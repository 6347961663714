import { createAction } from 'redux-create-action';
import { SHIPPING_ZONE_ACTIONS as ACTIONS } from '../constants';

export const setFieldUPCNumber = createAction(ACTIONS.SET_FIELD_UPC_NUMBER, 'fieldUPCNumber');
export const setZoneItems = createAction(ACTIONS.SET_ZONE_ITEMS, 'list');
export const setChangedItems = createAction(ACTIONS.SET_ZONE_CHANGED_ITEMS, 'list');

export const scanUPC = createAction(ACTIONS.SCAN_UPC, 'upc');
export const scanUPCSuccess = createAction(ACTIONS.SCAN_UPC_SUCCESS, 'payload', 'list');
export const scanUPCError = createAction(ACTIONS.SCAN_UPC_ERROR, 'errorMessage', 'error');

export const toggleSelectItem = createAction(ACTIONS.TOGGLE_SELECT_ITEM, 'upc', 'isSelected');
export const toggleSelectAllItems = createAction(ACTIONS.TOGGLE_SELECT_ALL_ITEMS, 'selectAll');
export const updateSelectedItems = createAction(ACTIONS.UPDATE_SELECTED_ITEMS, 'list');
export const cancelChangedItems = createAction(ACTIONS.CANCEL_CHANGED_ITEMS);

export const moveSelectedItems = createAction(ACTIONS.MOVE_SELECTED_ITEMS);
export const moveSelectedItemsSuccess = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS,
  'payload',
  'list',
);
export const moveSelectedItemsError = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_ERROR,
  'errorMessage',
  'error',
);

export const setSelectedItems = createAction(ACTIONS.SET_SELECTED_ITEMS, 'selectedItems');
