import _get from 'lodash/get';
import { reportsState } from '../selectors';

const reportState = state => _get(reportsState(state), 'salesReport');

// setup reducers
const setupState = state => _get(reportState(state), 'setup');
export const getIsExporting = state => _get(setupState(state), 'isExporting');
export const getBrandResults = state => _get(setupState(state), 'brandResults');
export const getVendorResults = state => _get(setupState(state), 'vendorResults');
export const getShowOptionsModal = state => _get(setupState(state), 'showOptionsModal');
export const getStartDate = state => _get(setupState(state), 'startDate');
export const getEndDate = state => _get(setupState(state), 'endDate');
export const getSelectedBrands = state => _get(setupState(state), 'selectedBrands');
export const getSelectedVendors = state => _get(setupState(state), 'selectedVendors');
export const getIsSearching = state => _get(setupState(state), 'isSearching');

// visualization reducers
const visualizationState = state => _get(reportState(state), 'visualization');
export const selectIsGenerating = state => _get(visualizationState(state), 'isGenerating');
export const selectIsFetchingSalesSummary = state =>
  _get(visualizationState(state), 'isFetchingSalesSummary');
export const selectIsFetchingSalesChart = state =>
  _get(visualizationState(state), 'isFetchingSalesChart');
export const selectSalesSummaryData = state => _get(visualizationState(state), 'salesSummaryData');
export const selectSalesChartData = state => _get(visualizationState(state), 'salesChartData');
export const selectChartSettings = state => _get(visualizationState(state), 'chartSettings');

// settings reducers
const settingsState = state => _get(reportState(state), 'settings');
export const getSettingsColumns = state => _get(settingsState(state), 'columns');
