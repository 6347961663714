import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { userManagementApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';
import { fetchPermissionsError, fetchPermissionsSuccess } from './actions';

function* fetchUsersWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.getUsers]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }
    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

export function* fetchRolesWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.getRoles]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export function* fetchPermissionsWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.getPermissions]);
    const result = response.data ? response.data : response;

    yield put(fetchPermissionsSuccess(result));

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(fetchPermissionsError(errorMessage, error));

    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setAddUserWatcher({ userData, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.addUser], userData);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setEditUserWatcher({ username, userData, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.editUser], username, userData);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setDeleteUserWatcher({ username, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.deleteUser], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export function* fetchCheckUsernameAvailabilityWatcher({
  username,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(userManagementApiSelector);
  try {
    const response = yield call([api, api.checkUsernameAvailability], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }
    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setResetUserPasswordWatcher({ username, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);

  try {
    const response = yield call([api, api.resetUserPassword], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setResendInviteWatcher({ username, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);

  try {
    const response = yield call([api, api.resendInvite], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setAddRoleWatcher({ params, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);

  try {
    const response = yield call([api, api.addRole], params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setEditRoleWatcher({ params, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);

  try {
    const response = yield call([api, api.editRole], params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* setDeleteRoleWatcher({ params, successActionCallback, errorActionCallback }) {
  const api = yield select(userManagementApiSelector);

  try {
    const response = yield call([api, api.deleteRole], params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export default function* userManagementSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_USERS, authRequired, fetchUsersWatcher),
    takeEvery(
      ACTIONS.FETCH_CHECK_USERNAME_AVAILABILITY,
      authRequired,
      fetchCheckUsernameAvailabilityWatcher,
    ),
    takeEvery(ACTIONS.FETCH_ROLES, authRequired, fetchRolesWatcher),
    takeEvery(ACTIONS.FETCH_PERMISSIONS, authRequired, fetchPermissionsWatcher),

    takeEvery(ACTIONS.SET_ADD_USER, authRequired, setAddUserWatcher),
    takeEvery(ACTIONS.SET_DELETE_USER, authRequired, setDeleteUserWatcher),
    takeEvery(ACTIONS.SET_EDIT_USER, authRequired, setEditUserWatcher),
    takeEvery(ACTIONS.SET_RESET_USER_PASSWORD, authRequired, setResetUserPasswordWatcher),
    takeEvery(ACTIONS.SET_RESEND_INVITE, authRequired, setResendInviteWatcher),
    takeEvery(ACTIONS.SET_ADD_ROLE, authRequired, setAddRoleWatcher),
    takeEvery(ACTIONS.SET_EDIT_ROLE, authRequired, setEditRoleWatcher),
    takeEvery(ACTIONS.SET_DELETE_ROLE, authRequired, setDeleteRoleWatcher),
  ]);
}
