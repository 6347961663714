import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ROUTES from 'constants/routes';
import { ReactComponent as AppError } from 'assets/svg/global/app-error.svg';
import Button, { buttonColors, buttonSizes } from 'components/Button';
import { LoaderWrap, ErrorWrap, ImageWrap } from './styles';

const LoadableError = ({ error, goBackToMain, title, description, ...restProps }) => {
  // eslint-disable-next-line no-console
  console.error('Loadable Error: ', error);
  const reloadWindow = () => window.location.reload();
  return (
    <LoaderWrap {...restProps}>
      <ErrorWrap>
        <ImageWrap>
          <AppError />
        </ImageWrap>
        <h1>{title}</h1>
        <p>{description}</p>
        <Button
          color={buttonColors.dark}
          size={buttonSizes.large}
          onClick={reloadWindow}
        >
          <span>Reload Page</span>
        </Button>
        <Button
          size={buttonSizes.large}
          color={buttonColors.secondary}
          onClick={() => goBackToMain()}
        >
          <span>Go Back To Main Page</span>
        </Button>
      </ErrorWrap>
    </LoaderWrap>
  );
};

LoadableError.propTypes = {
  error: PropTypes.shape(),
  goBackToMain: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

LoadableError.defaultProps = {
  error: null,
  title: 'Aw, Snap!',
  description: `
    Something went wrong while displaying this page. 
    To continue, press Reload or go to another page.
  `,
};

const mapDispatchToProps = {
  goBackToMain: () => push(ROUTES.BASE),
};

export default connect(null, mapDispatchToProps)(LoadableError);
