import { VIEW_COLUMNS } from './columns';
import { QUICK_FILTERS, VIEW_FILTERS } from './filters';
import { formatColumnSettingsStorage } from './helpers';

export const ORDER_LINES_COLUMNS_DEFAULT_ORDER = [
  VIEW_COLUMNS.ORDER_ID.id,
  VIEW_COLUMNS.SALES_ORDER_NUMBER.id,
  VIEW_COLUMNS.SKU.id,
  VIEW_COLUMNS.UPC.id,
  VIEW_COLUMNS.PURCHASE_ORDER_NUMBER.id,
  VIEW_COLUMNS.QTY_ORDERED.id,
  VIEW_COLUMNS.PRICE.id,
  VIEW_COLUMNS.SHIPMENT_LINES.id,
  VIEW_COLUMNS.ORDER_LINE_STATUS.id,
  VIEW_COLUMNS.LINE_TYPE.id,
  VIEW_COLUMNS.PURCHASE_DATE.id,
  VIEW_COLUMNS.PROMISE_DATE.id,
  VIEW_COLUMNS.EDI.id,
  VIEW_COLUMNS.BRAND.id,
  VIEW_COLUMNS.SUPPLIER.id,
  VIEW_COLUMNS.SALES_CHANNEL.id,
  VIEW_COLUMNS.COST.id,
  VIEW_COLUMNS.MARKUP.id,
  VIEW_COLUMNS.NOTES.id,
  VIEW_COLUMNS.ADDRESS.id,
  VIEW_COLUMNS.SHIPPING_PRIORITY.id,
  VIEW_COLUMNS.FULFILLMENT_WAREHOUSE_ABBR.id,
];

export const ORDER_LINES_COLUMNS = {
  [VIEW_COLUMNS.ORDER_ID.id]: {
    ...VIEW_COLUMNS.ORDER_ID,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SALES_ORDER_NUMBER.id]: {
    ...VIEW_COLUMNS.SALES_ORDER_NUMBER,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.SKU.id]: {
    ...VIEW_COLUMNS.SKU,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.UPC.id]: {
    ...VIEW_COLUMNS.UPC,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.PURCHASE_ORDER_NUMBER.id]: {
    ...VIEW_COLUMNS.PURCHASE_ORDER_NUMBER,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.QTY_ORDERED.id]: {
    ...VIEW_COLUMNS.QTY_ORDERED,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [VIEW_COLUMNS.PRICE.id]: {
    ...VIEW_COLUMNS.PRICE,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.SHIPMENT_LINES.id]: {
    ...VIEW_COLUMNS.SHIPMENT_LINES,
    enabled: true,
    sortable: false,
    filterableContextMenu: false,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.ORDER_LINE_STATUS.id]: {
    ...VIEW_COLUMNS.ORDER_LINE_STATUS,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '125px',
    },
  },
  [VIEW_COLUMNS.LINE_TYPE.id]: {
    ...VIEW_COLUMNS.LINE_TYPE,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [VIEW_COLUMNS.PURCHASE_DATE.id]: {
    ...VIEW_COLUMNS.PURCHASE_DATE,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.PROMISE_DATE.id]: {
    ...VIEW_COLUMNS.PROMISE_DATE,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.EDI.id]: {
    ...VIEW_COLUMNS.EDI,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [VIEW_COLUMNS.BRAND.id]: {
    ...VIEW_COLUMNS.BRAND,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SUPPLIER.id]: {
    ...VIEW_COLUMNS.SUPPLIER,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SALES_CHANNEL.id]: {
    ...VIEW_COLUMNS.SALES_CHANNEL,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.COST.id]: {
    ...VIEW_COLUMNS.COST,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [VIEW_COLUMNS.MARKUP.id]: {
    ...VIEW_COLUMNS.MARKUP,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '120px',
    },
  },
  [VIEW_COLUMNS.NOTES.id]: {
    ...VIEW_COLUMNS.NOTES,
    enabled: true,
    sortable: false,
    filterableContextMenu: false,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '150px',
    },
  },
  [VIEW_COLUMNS.ADDRESS.id]: {
    ...VIEW_COLUMNS.ADDRESS,
    enabled: true,
    sortable: false,
    filterableContextMenu: false,
    copyable: false,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SHIPPING_PRIORITY.id]: {
    ...VIEW_COLUMNS.SHIPPING_PRIORITY,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.FULFILLMENT_WAREHOUSE_ABBR.id]: {
    ...VIEW_COLUMNS.FULFILLMENT_WAREHOUSE_ABBR,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
};

export const ORDER_LINES_FILTERS = {
  ...VIEW_FILTERS,
};

export const ORDER_LINES_COLUMNS_STORAGE_SETTINGS = formatColumnSettingsStorage(
  ORDER_LINES_COLUMNS,
);

export const ORDER_LINES_QUICK_FILTERS = [
  ...QUICK_FILTERS,
];