import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  showCreateOrderModal: false,
  isConfirmingCreateOrder: false,
  salesOrderNumber: null,
};

const setCancelCreateOrder = state => ({
  ...state,
  salesOrderNumber: INITIAL_STATE.salesOrderNumber,
  guidOrderLine: INITIAL_STATE.guidOrderLine,
  isConfirmingCreateOrder: INITIAL_STATE.isConfirmingCreateOrder,
});

const setCreateOrderFromPrevious = (state, { salesOrderNumber }) => ({
  ...state,
  salesOrderNumber,
});

const setConfirmCreateOrder = state => ({
  ...state,
  isConfirmingCreateOrder: true,
});

const setConfirmCreateOrderError = state => ({
  ...state,
  ...setCancelCreateOrder(state),
});

const updateShowCreateModal = (state, { showCreateOrderModal }) => ({
  ...state,
  showCreateOrderModal,
});

export const handlers = {
  [ACTIONS.CREATE_ORDER_FROM_PREVIOUS]: setCreateOrderFromPrevious,
  [ACTIONS.CONFIRM_CREATE_ORDER]: setConfirmCreateOrder,
  [ACTIONS.CONFIRM_CREATE_ORDER_ERROR]: setConfirmCreateOrderError,
  [ACTIONS.CANCEL_CREATE_ORDER]: setCancelCreateOrder,
  [ACTIONS.SHOW_CREATE_ORDER_MODAL]: updateShowCreateModal,
};

export default createReducer(INITIAL_STATE, handlers);
