import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchProductInfo = createAction(
  ACTIONS.FETCH_PRODUCT_INFO,
  'upcOrSku',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchShipCities = createAction(
  ACTIONS.FETCH_SHIP_CITIES,
  'zipCode',
  'successActionCallback',
  'errorActionCallback',
);

export const fetchAdjustedInventory = createAction(
  ACTIONS.FETCH_ADJUSTED_INVENTORY,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchAdjustedInventoryCSV = createAction(
  ACTIONS.FETCH_ADJUSTED_INVENTORY_CSV,
  'successActionCallback',
  'errorActionCallback',
);

export const fetchAdjustedInventoryCost = createAction(
  ACTIONS.FETCH_ADJUSTED_INVENTORY_COST,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchAdjustedInventoryCostCSV = createAction(
  ACTIONS.FETCH_ADJUSTED_INVENTORY_COST_CSV,
  'successActionCallback',
  'errorActionCallback',
);

export const fetchInventoryLocations = createAction(
  ACTIONS.FETCH_INVENTORY_LOCATIONS,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchInventoryLocationsCSV = createAction(
  ACTIONS.FETCH_INVENTORY_LOCATIONS_CSV,
  'successActionCallback',
  'errorActionCallback',
);

export const fetchInventoryQtys = createAction(
  ACTIONS.FETCH_INVENTORY_QTYS,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchInventoryQtysCSV = createAction(
  ACTIONS.FETCH_INVENTORY_QTYS_CSV,
  'successActionCallback',
  'errorActionCallback',
);

export const fetchSalesReport = createAction(
  ACTIONS.FETCH_SALES_REPORT,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchSalesReportCSV = createAction(
  ACTIONS.FETCH_SALES_REPORT_CSV,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchSalesReportSearch = createAction(
  ACTIONS.FETCH_SALES_REPORT_SEARCH,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
