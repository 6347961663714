import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const scanPurchaseOrder = createAction(ACTIONS.SCAN_PURCHASE_ORDER, 'purchaseOrderNumber');
export const scanPurchaseOrderSuccess = createAction(
  ACTIONS.SCAN_PURCHASE_ORDER_SUCCESS,
  'payload',
);
export const scanPurchaseOrderError = createAction(
  ACTIONS.SCAN_PURCHASE_ORDER_ERROR,
  'errorMessage',
  'error',
);
export const setScannedItems = createAction(ACTIONS.SET_SCANNED_ITEMS, 'scannedItems');
export const setInitialScannedItemsSnapshot = createAction(
  ACTIONS.SET_INITIAL_SCANNED_ITEMS_SNAPSHOT,
  'initialScannedItemsSnapshot',
);
export const setPurchaseOrderNumber = createAction(
  ACTIONS.SET_PURCHASE_ORDER_NUMBER,
  'purchaseOrderNumber',
);
export const setUPCErrorList = createAction(ACTIONS.SET_UPC_ERROR_LIST, 'upcErrorList');
export const reloadPurchaseOrder = createAction(ACTIONS.RELOAD_PURCHASE_ORDER);
export const setShowManualConfirmModal = createAction(
  ACTIONS.SET_SHOW_MANUAL_CONFIRM_MODAL,
  'showManualConfirmModal',
);
export const setManualConfirmItem = createAction(
  ACTIONS.SET_MANUAL_CONFIRM_ITEM,
  'scannedItemRefId',
);
export const setSelectedSalesOrdersList = createAction(
  ACTIONS.SET_SELECTED_SALES_ORDERS_LIST,
  'selectedSalesOrdersList',
);
export const commitScannedItems = createAction(ACTIONS.COMMIT_SCANNED_ITEMS);
export const commitScannedItemsSuccess = createAction(
  ACTIONS.COMMIT_SCANNED_ITEMS_SUCCESS,
  'payload',
);
export const commitScannedItemsError = createAction(
  ACTIONS.COMMIT_SCANNED_ITEMS_ERROR,
  'errorMessage',
  'error',
);
export const showSalesOrdersListModal = createAction(
  ACTIONS.SHOW_SALES_ORDERS_LIST_MODAL,
  'showSalesOrdersListModal',
);
export const setShipmentVolumeUnit = createAction(
  ACTIONS.SET_SHIPMENT_VOLUME_UNIT,
  'shipmentVolumeUnit',
);
export const setShipmentTotalCost = createAction(
  ACTIONS.SET_SHIPMENT_TOTAL_COST,
  'shipmentTotalCost',
);
export const setShipmentTotalVolume = createAction(
  ACTIONS.SET_SHIPMENT_TOTAL_VOLUME,
  'shipmentTotalVolume',
);
export const setScannedItemsShipmentCost = createAction(
  ACTIONS.SET_SCANNED_ITEMS_SHIPMENT_COST,
  'scannedItems',
);
export const setInitialShipmentSnapshot = createAction(
  ACTIONS.SET_INITIAL_SHIPMENT_SNAPSHOT,
  'initialShipmentSnapshot',
);
