import _extend from 'lodash/extend';
import { FEATURE_FLAGS } from './constants';
import prod from './config.prod';

export default _extend({}, prod, {
  [FEATURE_FLAGS.APP_BRANDING]: true,
  [FEATURE_FLAGS.ACCOUNT_SETTINGS_BUSINESS]: true,
  [FEATURE_FLAGS.ACCOUNT_SETTINGS_BUSINESS_STRIPE]: false,
  [FEATURE_FLAGS.ACCOUNT_SETTINGS_INTEGRATIONS]: true,
  [FEATURE_FLAGS.INVENTORY_MOVE_MOBILE_VERSION]: false,
  [FEATURE_FLAGS.INVENTORY_PICKING_MOBILE_VERSION]: false,
  [FEATURE_FLAGS.ORDER_VIEW]: true,
  [FEATURE_FLAGS.PURCHASE_ORDER_MANAGEMENT_PURCHASE_ORDERS]: true,
  [FEATURE_FLAGS.PRODUCT_MANAGEMENT]: true,
  [FEATURE_FLAGS.PRODUCT_MANAGEMENT_BRANDS_SUPPLIERS]: true,
  [FEATURE_FLAGS.PRODUCT_MANAGEMENT_PRODUCTS]: true,
  [FEATURE_FLAGS.PRODUCT_MANAGEMENT_PRODUCT_CREATOR]: true,
  [FEATURE_FLAGS.REPORTS_INVENTORY_LOCATIONS_CHARTS]: true,
});
