import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS, { PURCHASE_ORDERS_DEFAULT_PARAMS } from './constants';

export default class PurchaseOrdersApi extends HttpRequest {
  importFile({ file }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fileData', file);

    return this.call(ENDPOINTS.IMPORT, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  validateFile({ file }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fileData', file);

    return this.call(ENDPOINTS.VALIDATE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getTemplate() {
    return this.call(ENDPOINTS.IMPORT_TEMPLATE);
  }

  getPurchaseOrders({ params = {} }) {
    const requestParams = {
      ...PURCHASE_ORDERS_DEFAULT_PARAMS,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.GET_PURCHASE_ORDERS}${queryString}`);
  }

  purchaseOrdersToCSV({ params = {} }) {
    const requestParams = {
      ...PURCHASE_ORDERS_DEFAULT_PARAMS,
      ...params,
    };
    const bodyFormData = new FormData();
    const { filter } = requestParams;
    bodyFormData.set(
      'payload',
      JSON.stringify({
        ...requestParams,
        filter: JSON.stringify(filter),
      }),
    );

    return this.call(ENDPOINTS.GET_PURCHASE_ORDERS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  removePurchaseOrder({ purchaseOrderNumber }) {
    const bodyFormData = new FormData();
    bodyFormData.set('po_number', purchaseOrderNumber);

    return this.call(ENDPOINTS.REMOVE_PURCHASE_ORDER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updatePurchaseOrder({ purchaseOrderData }) {
    const { po } = purchaseOrderData;
    const bodyFormData = new FormData();
    bodyFormData.set('po_number', po);
    bodyFormData.set('updateObject', JSON.stringify(purchaseOrderData));

    return this.call(ENDPOINTS.UPDATE_PURCHASE_ORDER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getPurchaseOrder(purchaseOrderNumber) {
    const query = {
      po: purchaseOrderNumber,
    };
    const queryString = queryStringFromObj(query);
    return this.call(`${ENDPOINTS.SEARCH}${queryString}`);
  }

  submitPurchaseOrderScannedItems(items, shipment) {
    const bodyFormData = new FormData();
    bodyFormData.set('po', JSON.stringify(items));
    bodyFormData.set('shipment', JSON.stringify(shipment));

    return this.call(ENDPOINTS.CHECKIN, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
