import styled, { css } from 'styled-components';
import _omit from 'lodash/omit';
import {
  GALLERY,
  ALABASTER,
  PORCELAIN,
  BLUE_VIOLET,
  BLUE_VIOLET_LIGHT_60,
  TUNDORA,
  TUNDORA_LIGHT_40,
  PUNCH_LIGHT_60,
  PUNCH,
  EUCALYPTUS_LIGHT_60,
  EUCALYPTUS,
  AZURE_LIGHT_60,
  AZURE,
  AMBER_LIGHT_60,
  AMBER,
  BLACK_LIGHT_60,
  BLACK,
  STEEL_BLUE_LIGHT_60,
  STEEL_BLUE,
} from 'constants/colors';
import { buttonColors, buttonSizes as switchSizes } from 'components/Button/styles';

const switchColors = _omit(buttonColors, ['ghost']);

// sizes
const sizeSmall = css`
  min-width: 2rem;
  min-height: 0.625rem;
  max-width: 2rem;
  max-height: 0.625rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  &::after {
    width: 1rem;
    height: 1rem;
  }
`;
const sizeMedium = css`
  min-width: 2.5rem;
  min-height: 0.875rem;
  max-width: 2.5rem;
  max-height: 0.875rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  &::after {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
const sizeLarge = css`
  min-width: 3rem;
  min-height: 1.1rem;
  max-width: 3rem;
  max-height: 1.1rem;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
  &::after {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const sizeTypes = {
  [switchSizes.small]: sizeSmall,
  [switchSizes.medium]: sizeMedium,
  [switchSizes.large]: sizeLarge,
};

// colors
export const defaultColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${STEEL_BLUE_LIGHT_60};
  `}
  &::after {
    background-color: ${STEEL_BLUE};
  }
`;

export const primaryColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${BLUE_VIOLET_LIGHT_60};
  `}
  &::after {
    background-color: ${BLUE_VIOLET};
  }
`;

export const secondaryColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${TUNDORA_LIGHT_40};
  `}
  &::after {
    background-color: ${TUNDORA};
  }
`;

export const dangerColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${PUNCH_LIGHT_60};
  `}
  &::after {
    background-color: ${PUNCH};
  }
`;

export const successColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${EUCALYPTUS_LIGHT_60};
  `}
  &::after {
    background-color: ${EUCALYPTUS};
  }
`;

export const infoColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${AZURE_LIGHT_60};
  `}
  &::after {
    background-color: ${AZURE};
  }
`;

export const warningColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${AMBER_LIGHT_60};
  `}
  &::after {
    background-color: ${AMBER};
  }
`;

export const darkColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${BLACK_LIGHT_60};
  `}
  &::after {
    background-color: ${BLACK};
  }
`;

export const lightColor = css`
  ${({ checked }) =>
    checked &&
    `
    background-color: ${GALLERY};
  `}
  &::after {
    background-color: ${ALABASTER};
    ${({ checked }) =>
      checked &&
      `
      background-color: ${PORCELAIN};
    `}
  }
`;

export const colorTypes = {
  [switchColors.default]: defaultColor,
  [switchColors.primary]: primaryColor,
  [switchColors.secondary]: secondaryColor,
  [switchColors.danger]: dangerColor,
  [switchColors.success]: successColor,
  [switchColors.info]: infoColor,
  [switchColors.warning]: warningColor,
  [switchColors.dark]: darkColor,
  [switchColors.light]: lightColor,
};

export const disabledStyle = css`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const transitionA = 'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';
const transitionB = 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

export const SwitchWrap = styled.div`
  position: relative;
  border-radius: 1rem;
  cursor: pointer;
  background-color: ${GALLERY};
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    background-color: ${ALABASTER};
    box-shadow: 0px 0.5px 2.8px 0px rgba(0,0,0,0.6);
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    margin: auto;
    transform: translateX(0%);
    transition: ${transitionA},${transitionB};
    ${({ checked }) =>
      checked &&
      `
      transform: translateX(100%);
    `}
  }
  ${defaultColor};
  ${sizeMedium};
  ${disabledStyle};
  ${({ size }) => size && sizeTypes[size]};
  ${({ color }) => color && colorTypes[color]};
`;

export const SwitchGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export { switchColors, switchSizes };
