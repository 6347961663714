import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import { setMoveItems, releaseZoneLock } from 'services/inventory/actions';
import {
  INVENTORY_MANAGEMENT_ERRORS,
  INVENTORY_MANAGEMENT_SUCCESS,
} from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  moveSelectedItemsError,
  moveSelectedItemsSuccess,
  toggleShowMoveConfirmation,
  reloadZone,
  setSelectedItems,
} from '../actions';
import { getChangedItems, getZoneName, selectSelectedItems } from '../selectors';

function* moveSelectedItemsWatcher({ targetZoneName }) {
  const sourceZoneName = yield select(getZoneName);
  const zoneItems = yield select(getChangedItems);
  const selectedItemIds = yield select(selectSelectedItems);
  const selectedItems = _reduce(
    selectedItemIds,
    (result, upc) => {
      const item = _find(zoneItems, { upc });
      if (item) {
        result.push(item);
      }
      return result;
    },
    [],
  );

  yield put(
    setMoveItems(
      targetZoneName,
      sourceZoneName,
      selectedItems,
      moveSelectedItemsSuccess,
      moveSelectedItemsError,
    ),
  );
}

function* moveSelectedItemsSuccessWatcher({ targetZoneName }) {
  yield put(releaseZoneLock(targetZoneName));
  yield put(setSelectedItems([]));
  yield put(toggleShowMoveConfirmation(false));
  yield put(reloadZone());
  yield call(successToasterSaga, { message: INVENTORY_MANAGEMENT_SUCCESS.MOVING_ITEMS });
}

function* moveSelectedItemsErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  const errorDisplay = errorMessage || INVENTORY_MANAGEMENT_ERRORS.MOVING_ITEMS;
  yield call(errorToasterSaga, { errorMessage: errorDisplay });
}

export default function* moveZoneItemsSagas() {
  yield all([
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS, moveSelectedItemsWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS, moveSelectedItemsSuccessWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_ERROR, moveSelectedItemsErrorWatcher),
  ]);
}
