import styled, { css } from 'styled-components';
import { alpha, darken } from '@material-ui/core/styles';
import { genericBorder } from 'assets/styles/utils';
import {
  BLUE_VIOLET,
  BLUE_VIOLET_DARK_20,
  WHITE,
  TUNDORA,
  BLACK,
  IRON,
  BOMBAY,
  PUNCH,
  PUNCH_DARK_20,
  EUCALYPTUS,
  EUCALYPTUS_DARK_20,
  ATHENS_GRAY,
  ATHENS_GRAY_DARK_7,
  AMBER,
  AMBER_DARK_20,
  AZURE,
  AZURE_DARK_20,
  WILD_SAND,
  CRUSTA,
  CRUSTA_DARK_20,
  TURQUOISE,
  TURQUOISE_DARK_20,
} from 'constants/colors';
import { ROBOTO_MEDIUM } from 'assets/styles/fonts';
import { SpinnerCenteredWrap } from 'components/Spinner';

const colorMixin = color => `
  background-color: ${color};
  border: ${genericBorder(color)};
`;

export const backgroundColors = {
  primary: {
    normal: BLUE_VIOLET,
    hover: BLUE_VIOLET_DARK_20,
  },
  secondary: {
    normal: CRUSTA,
    hover: CRUSTA_DARK_20,
  },
  danger: {
    normal: PUNCH,
    hover: PUNCH_DARK_20,
  },
  success: {
    normal: EUCALYPTUS,
    hover: EUCALYPTUS_DARK_20,
  },
  info: {
    normal: AZURE,
    hover: AZURE_DARK_20,
  },
  dark: {
    normal: BLACK,
    hover: BLACK,
  },
  light: {
    normal: ATHENS_GRAY,
    hover: ATHENS_GRAY_DARK_7,
  },
  ghost: {
    normal: alpha(WHITE, 0),
    hover: WILD_SAND,
  },
  warning: {
    normal: AMBER,
    hover: AMBER_DARK_20,
  },
  default: {
    normal: TURQUOISE,
    hover: TURQUOISE_DARK_20,
  },
};

// color
export const defaultColor = css`
  ${colorMixin(backgroundColors.default.normal)}
  color: ${WHITE};
  :hover {
    ${colorMixin(backgroundColors.default.hover)}
  }
`;
const primaryColor = css`
  ${colorMixin(backgroundColors.primary.normal)}
  :hover {
    ${colorMixin(backgroundColors.primary.hover)}
  }
`;
const secondaryColor = css`
  ${colorMixin(backgroundColors.secondary.normal)}
  :hover {
    ${colorMixin(backgroundColors.secondary.hover)}
  }
`;
const dangerColor = css`
  ${colorMixin(backgroundColors.danger.normal)}
  :hover {
    ${colorMixin(backgroundColors.danger.hover)}
  }
`;
const successColor = css`
  ${colorMixin(backgroundColors.success.normal)}
  :hover {
    ${colorMixin(backgroundColors.success.hover)}
  }
`;
const infoColor = css`
  ${colorMixin(backgroundColors.info.normal)}
  :hover {
    ${colorMixin(backgroundColors.info.hover)}
  }
`;
const darkColor = css`
  ${colorMixin(backgroundColors.dark.normal)}
  :hover {
    ${colorMixin(backgroundColors.dark.hover)}
  }
`;

const lightColor = css`
  ${colorMixin(backgroundColors.light.normal)}
  border: ${genericBorder()};
  color: ${BLACK};
  :hover {
    background-color: ${backgroundColors.light.hover};
    border: ${genericBorder()};
  }
`;

const ghostColor = css`
  ${colorMixin(backgroundColors.ghost.normal)}
  border: ${genericBorder('transparent')};
  color: ${TUNDORA};
  :hover {
    background-color: ${backgroundColors.ghost.hover};
    border: ${genericBorder(WILD_SAND)};
  }
`;

const warningColor = css`
  ${colorMixin(backgroundColors.warning.normal)}
  color: ${BLACK};
  :hover {
    ${colorMixin(backgroundColors.warning.hover)}
  }
`;

export const buttonColors = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  success: 'success',
  info: 'info',
  warning: 'warning',
  dark: 'dark',
  light: 'light',
  ghost: 'ghost',
};

export const colorTypes = {
  [buttonColors.default]: defaultColor,
  [buttonColors.primary]: primaryColor,
  [buttonColors.secondary]: secondaryColor,
  [buttonColors.danger]: dangerColor,
  [buttonColors.success]: successColor,
  [buttonColors.info]: infoColor,
  [buttonColors.warning]: warningColor,
  [buttonColors.dark]: darkColor,
  [buttonColors.light]: lightColor,
  [buttonColors.ghost]: ghostColor,
};

// sizes
const sizeSmall = css`
  height: 1.6875rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  > span {
    font-size: 0.75rem;
  }
  > * {
    &:first-child {
      margin-right:0.4rem;
    } 
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left:0.4rem;
    }
  }
`;
export const sizeMedium = css`
  height: 2.5rem;
  font-size: 0.875rem;
  padding: 0.2rem 0.8rem;
  > span {
    font-size: 0.875rem;
  }
  > * {
    &:first-child {
      margin-right:0.5rem;
    } 
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left:0.5rem;
    }
  }
`;
const sizeLarge = css`
  height: 3.5rem;
  font-size: 1.2rem;
  padding: 0.25rem 1.1rem;
  > span {
    font-size: 1.2rem;
  }
  > * {
    &:first-child {
      margin-right:0.7rem;
    } 
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left:0.7rem;
    }
  }
`;
export const buttonSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const sizeTypes = {
  [buttonSizes.small]: sizeSmall,
  [buttonSizes.medium]: sizeMedium,
  [buttonSizes.large]: sizeLarge,
};

export const spinnerSizes = {
  small: '1rem',
  medium: '1.5rem',
  large: '2rem',
};

export const disabledStyle = css`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.7;
    cursor: not-allowed;
  `}
  ${({ disabled, isLoading, color }) => {
    if (disabled && color === buttonColors.ghost) {
      return `
        ${colorMixin('transparent')}
        :hover {
          ${colorMixin('transparent')}
        }
      `;
    }

    if (disabled && !isLoading) {
      return `
      color: ${BOMBAY};
      ${colorMixin(IRON)}
      :hover {
        ${colorMixin(IRON)}
      }
    `;
    }
  }}
`;

export const ButtonStyled = styled.button`
  position: relative;
  border-radius: 4px;
  font-family: ${ROBOTO_MEDIUM};
  transition: background-color 0.1s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  white-space: nowrap;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  ${defaultColor}
  ${sizeMedium}
  ${({ color }) => color && colorTypes[color]}
  ${({ size }) => size && sizeTypes[size]}
  ${({ fullWidth }) =>
    fullWidth &&
    `
    max-width: 100%;
    width: 100%;
  `}
  ${({ width }) =>
    width &&
    `
    width: ${width};
  `}
  margin: ${({ margin }) => margin || '0'};
  ${disabledStyle}
  ${SpinnerCenteredWrap},
  .MuiCircularProgress-root {
    position: absolute;
    margin: auto;
    width: ${spinnerSizes.medium}!important;
    height: ${spinnerSizes.medium}!important;
    ${({ size }) =>
      size &&
      `
      width: ${spinnerSizes[size]}!important;
      height: ${spinnerSizes[size]}!important;
    `}
    > svg {
      color: #ccc;
      ${({ color }) =>
        color &&
        color !== 'transparent' &&
        backgroundColors[color] &&
        `
        color: ${darken(backgroundColors[color].normal, 0.4)}
      `}
    }
  }
`;

const btnContentAlign = {
  right: 'flex-end',
  left: 'flex-start',
  center: 'center',
};

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ contentAlign }) => btnContentAlign[contentAlign] || btnContentAlign.left};
  flex-grow: ${({ flexGrow }) => flexGrow || '1'};
  margin-right: 1rem;
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  min-width: ${({ minWidth }) => minWidth || '0'};
  box-sizing: border-box;
  &:last-child {
    margin-right: 0;
  }
  > * {
    margin: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
