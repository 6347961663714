import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS, {
  PRODUCTS_DEFAULT_PARAMS,
  BRANDS_SUPPLIERS_DEFAULT_PARAMS,
} from './constants';

export default class ProductManagementApi extends HttpRequest {
  getProducts({ params = {} }) {
    const requestParams = {
      ...PRODUCTS_DEFAULT_PARAMS,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.PRODUCTS}${queryString}`);
  }

  productsToCSV() {
    const requestParams = {
      ...PRODUCTS_DEFAULT_PARAMS,
    };
    const bodyFormData = new FormData();
    const { filter } = requestParams;
    bodyFormData.set('payload', JSON.stringify({
      ...requestParams,
      filter: JSON.stringify(filter),
    }));

    return this.call(ENDPOINTS.PRODUCTS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  importProductsFile({ file, uploadType }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fileData', file);
    bodyFormData.set('upload_type', uploadType);

    return this.call(ENDPOINTS.IMPORT_PRODUCTS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  validateProductsFile({ file, uploadType }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fileData', file);
    bodyFormData.set('upload_type', uploadType);

    return this.call(ENDPOINTS.VALIDATE_PRODUCTS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getEditProductsTemplate() {
    return this.call(ENDPOINTS.EDIT_PRODUCTS_TEMPLATE, { responseType: 'blob' });
  }

  getAddProductsTemplate() {
    return this.call(ENDPOINTS.ADD_PRODUCTS_TEMPLATE, { responseType: 'blob' });
  }

  getDeleteProductsTemplate() {
    return this.call(ENDPOINTS.DELETE_PRODUCTS_TEMPLATE, { responseType: 'blob' });
  }

  getUploadProductsLogs() {
    return this.call(ENDPOINTS.UPLOAD_PRODUCTS_LOGS);
  }

  getDownloadUploadedFile({ fileKey }) {
    return this.call(ENDPOINTS.DOWNLOAD_UPLOADED_FILE(fileKey));
  }

  getDownloadErrorFile({ fileKey }) {
    return this.call(ENDPOINTS.DOWNLOAD_ERROR_FILE(fileKey));
  }

  postCancelUploadRequest({ fileKey }) {
    const bodyFormData = new FormData();
    bodyFormData.set('file_key', fileKey);
    bodyFormData.set('status', 'cancel');

    return this.call(ENDPOINTS.UPLOAD_PRODUCTS_LOGS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getDownloadProductsLogs() {
    return this.call(ENDPOINTS.DOWNLOAD_PRODUCTS_LOGS);
  }

  postDownloadProducts({ params = {} }) {
    const requestParams = {
      ...PRODUCTS_DEFAULT_PARAMS,
      ...params,
    };
    const bodyFormData = new FormData();
    const { filter } = requestParams;
    bodyFormData.set('payload', JSON.stringify({
      ...requestParams,
      filter: JSON.stringify(filter),
    }));

    return this.call(ENDPOINTS.DOWNLOAD_PRODUCTS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getDownloadProductsFile({ fileKey }) {
    return this.call(ENDPOINTS.DOWNLOAD_PRODUCTS_FILE(fileKey));
  }

  postCancelDownloadRequest({ requestId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('request_id', requestId);
    bodyFormData.set('status', 'cancel');

    return this.call(ENDPOINTS.DOWNLOAD_PRODUCTS_LOGS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getBrandsSuppliers({ params = {} }) {
    const requestParams = {
      ...BRANDS_SUPPLIERS_DEFAULT_PARAMS,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.BRANDS_SUPPLIERS}${queryString}`);
  }

  getFilteredBrandsSuppliers({ params = {} }) {
    const requestParams = {
      ...BRANDS_SUPPLIERS_DEFAULT_PARAMS,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.FILTERED_BRANDS_SUPPLIERS}${queryString}`);
  }

  addBrandSupplier({ params = {} }) {
    const bodyFormData = new FormData();
    bodyFormData.set('brand_id', params.brand_id);
    bodyFormData.set('active', true);
    bodyFormData.set('supplier_id', params.supplier_id);

    return this.call(ENDPOINTS.BRANDS_SUPPLIERS_ADD, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteBrandSupplier({ brandSupplierId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('brand_supplier_id', brandSupplierId);

    return this.call(ENDPOINTS.BRANDS_SUPPLIERS_DELETE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updateBrandSupplier({ params = {} }) {
    const bodyFormData = new FormData();
    bodyFormData.set('brand_id', params.brand_id);
    bodyFormData.set('active', true);
    bodyFormData.set('supplier_id', params.supplier_id);
    bodyFormData.set('brand_supplier_id', params.brand_supplier_id);

    return this.call(ENDPOINTS.BRANDS_SUPPLIERS_UPDATE, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
