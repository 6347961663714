import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const listSuppliers = createAction(
  ACTIONS.LIST_SUPPLIERS,
  'successActionCallback',
  'errorActionCallback',
);
export const addSupplier = createAction(
  ACTIONS.ADD_SUPPLIER,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const updateSupplier = createAction(
  ACTIONS.UPDATE_SUPPLIER,
  'supplierId',
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const deleteSupplier = createAction(
  ACTIONS.DELETE_SUPPLIER,
  'supplierId',
  'successActionCallback',
  'errorActionCallback',
);
