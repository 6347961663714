import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const getInformation = createAction(
  ACTIONS.GET_INFORMATION,
  'successActionCallback',
  'errorActionCallback',
);
export const updateInformation = createAction(
  ACTIONS.UPDATE_INFORMATION,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
