import { all, takeEvery, put, call, select, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  fetchUserProfile,
  setChangePassword,
  setUpdateUserProfile,
} from 'services/account/actions';
import { getSessionUserName } from 'app/session/selectors';
import { signOff } from 'containers/Base/Login/actions';
import { ACTIONS } from './constants';
import {
  loadUserProfileSuccess,
  loadUserProfileError,
  changePasswordSuccess,
  changePasswordError,
  loadUserProfile,
  updateProfileSuccess,
  updateProfileError,
} from './actions';

function* loadUserProfileWatcher() {
  const sessionUserName = yield select(getSessionUserName);

  yield put(fetchUserProfile(sessionUserName, loadUserProfileSuccess, loadUserProfileError));
}

function* loadUserProfileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* changePasswordWatcher({ currentPassword, newPassword }) {
  yield put(
    setChangePassword(currentPassword, newPassword, changePasswordSuccess, changePasswordError),
  );
}

function* changePasswordSuccessWatcher() {
  yield call([toast, toast.success], 'Password successfully changed.');
  yield delay(1000);
  yield put(signOff());
}

function* changePasswordErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* updateProfileWatcher({ profileInfo }) {
  const sessionUserName = yield select(getSessionUserName);
  yield put(
    setUpdateUserProfile(sessionUserName, profileInfo, updateProfileSuccess, updateProfileError),
  );
}

function* updateProfileSuccessWatcher() {
  yield call([toast, toast.success], 'Profile successfully updated.');
  yield put(loadUserProfile());
}

function* updateProfileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* profileSagas() {
  yield all([
    takeEvery(ACTIONS.LOAD_USER_PROFILE, loadUserProfileWatcher),
    takeEvery(ACTIONS.LOAD_USER_PROFILE_ERROR, loadUserProfileErrorWatcher),
    takeEvery(ACTIONS.UPDATE_PROFILE, updateProfileWatcher),
    takeEvery(ACTIONS.UPDATE_PROFILE_SUCCESS, updateProfileSuccessWatcher),
    takeEvery(ACTIONS.UPDATE_PROFILE_ERROR, updateProfileErrorWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD, changePasswordWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD_SUCCESS, changePasswordSuccessWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD_ERROR, changePasswordErrorWatcher),
  ]);
}
