import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const downloadSample = createAction(ACTIONS.DOWNLOAD_SAMPLE);
export const downloadSampleSuccess = createAction(ACTIONS.DOWNLOAD_SAMPLE_SUCCESS, 'payload');
export const downloadSampleError = createAction(
  ACTIONS.DOWNLOAD_SAMPLE_ERROR,
  'errorMessage',
  'error',
);

export const uploadFile = createAction(ACTIONS.UPLOAD_FILE, 'file', 'options');
export const uploadFileSuccess = createAction(ACTIONS.UPLOAD_FILE_SUCCESS, 'payload');
export const uploadFileError = createAction(ACTIONS.UPLOAD_FILE_ERROR, 'errorMessage', 'error');

export const resetUPCLabelGeneratorState = createAction(ACTIONS.RESET_STATE);
