import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  fetchImportTemplate,
  setImportFile,
  setValidateFile,
} from 'services/purchaseOrders/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';
import {
  downloadTemplateSuccess,
  downloadTemplateError,
  validateUploadFileSuccess,
  validateUploadFileError,
  setUploadFileIsValid,
  setUploadFileValidationErrors,
  uploadFileSuccess,
  uploadFileError,
} from './actions';

export const PO_IMPORT_TEMPLATE_FILENAME = 'purchase_order_import_template.csv';

function* downloadTemplateWatcher() {
  yield put(fetchImportTemplate(downloadTemplateSuccess, downloadTemplateError));
}

function* downloadTemplateSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: PO_IMPORT_TEMPLATE_FILENAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: downloadTemplateError,
  });
}

function* downloadTemplateErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* validateUploadFileWatcher({ file }) {
  yield put(setUploadFileValidationErrors(null));
  yield put(setUploadFileIsValid(false));
  yield put(setValidateFile(file, validateUploadFileSuccess, validateUploadFileError));
}

function* validateUploadFileSuccessWatcher({ payload }) {
  const { success, errors } = payload;

  if (success) {
    yield put(setUploadFileIsValid(true));
    return;
  }

  yield put(setUploadFileIsValid(false));
  yield put(setUploadFileValidationErrors(errors));
}

function* validateUploadFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* uploadFileWatcher({ file }) {
  yield put(setImportFile(file, uploadFileSuccess, uploadFileError));
}

function* uploadFileSuccessWatcher() {
  yield put(setUploadFileIsValid(false));
  yield call([toast, toast.success], 'File successfully imported.');
}

function* uploadFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* purchaseOrderCreatorSagas() {
  yield all([
    takeEvery(ACTIONS.DOWNLOAD_TEMPLATE, downloadTemplateWatcher),
    takeEvery(ACTIONS.DOWNLOAD_TEMPLATE_SUCCESS, downloadTemplateSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_TEMPLATE_ERROR, downloadTemplateErrorWatcher),

    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE, validateUploadFileWatcher),
    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE_SUCCESS, validateUploadFileSuccessWatcher),
    takeEvery(ACTIONS.VALIDATE_UPLOAD_FILE_ERROR, validateUploadFileErrorWatcher),

    takeEvery(ACTIONS.UPLOAD_FILE, uploadFileWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_SUCCESS, uploadFileSuccessWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_ERROR, uploadFileErrorWatcher),
  ]);
}
