import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { orderManagementState } from 'features/OrderManagement/selectors';
import { SHIPPING_ADDRESS_FIELD } from './constants';

const addOrderState = state => _get(orderManagementState(state), 'addOrder');

// Order Type
const orderTypeState = state => _get(addOrderState(state), 'orderType');
export const getSalesChannel = state => _get(orderTypeState(state), 'sales_channel');

// Shipping Address
const shippingAddressState = state => _get(addOrderState(state), 'shippingAddress');
export const getShippingAddressField = fieldId => state =>
  _get(shippingAddressState(state), fieldId);
export const getIsSearchingZipCode = state =>
  _get(shippingAddressState(state), 'isSearchingZipCode');
export const getShippingInfo = state =>
  _pick(shippingAddressState(state), Object.values(SHIPPING_ADDRESS_FIELD));

// Add Items
const addItemsState = state => _get(addOrderState(state), 'addItems');
export const getIsScanningItem = state => _get(addItemsState(state), 'isScanningItem');
export const getOrderItems = state => _get(addItemsState(state), 'orderItems');

// Complete Order
const completeOrderState = state => _get(addOrderState(state), 'completeOrder');
export const getIsConfirmingOrder = state => _get(completeOrderState(state), 'isConfirmingOrder');
export const getOrderId = state => _get(completeOrderState(state), 'order_id');
export const getPurchaseDate = state => _get(completeOrderState(state), 'purchase_date');
export const getOrderStatus = state => _get(completeOrderState(state), 'order_status');
export const getOrderTotal = state => _get(completeOrderState(state), 'order_total');
export const getTotalQty = state => _get(completeOrderState(state), 'total_qty');
export const getTotalQtyShipped = state => _get(completeOrderState(state), 'total_qty_shipped');
export const getReferringOrder = state => _get(completeOrderState(state), 'referring_order');
export const getOrderNotes = state => _get(completeOrderState(state), 'order_notes');
export const getShippingItem = state => _get(completeOrderState(state), 'shippingItem');
export const getShowConfirmPaymentModal = state =>
  _get(completeOrderState(state), 'showConfirmPaymentModal');
export const getShowCancelOrderModal = state =>
  _get(completeOrderState(state), 'showCancelOrderModal');
export const getPaymentConfirmed = state => _get(completeOrderState(state), 'paymentConfirmed');
export const getOrderConfirmed = state => _get(completeOrderState(state), 'orderConfirmed');
