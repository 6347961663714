import { useEffect, useRef, useState, useCallback } from 'react';
import _forEach from 'lodash/forEach';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsBreakpoint = (breakpoint = 'sm', direction = 'down') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints[direction](breakpoint));
};

export const logPropsChanged = (prevProps, props) =>
  _forEach(prevProps, (prop, key) => {
    if (prop !== props[key]) {
      // eslint-disable-next-line no-console
      console.log(`Prop: [${key}]`, 'previous:', prop, 'current:', props[key]);
    }
  });

export const useToggle = (initialValue = false) => {
  const [toggleValue, setToggleValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setToggleValue(value => !value);
  }, []);

  return [toggleValue, toggle];
};
