import { all, takeEvery } from 'redux-saga/effects';
import { companyApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const getInformationWatcher = action => serviceSaga(companyApiSelector, 'getInformation', action);
const updateInformationWatcher = action =>
  serviceSaga(companyApiSelector, 'updateInformation', action);

export default function* companySagas() {
  yield all([
    takeEvery(ACTIONS.GET_INFORMATION, authRequired, getInformationWatcher),
    takeEvery(ACTIONS.UPDATE_INFORMATION, authRequired, updateInformationWatcher),
  ]);
}
