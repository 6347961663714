import _get from 'lodash/get';
import { toolsState } from '../selectors';

const pickTicketGeneratorState = state => _get(toolsState(state), 'pickTicketGenerator');
export const getSalesOrderNumbers = state =>
  _get(pickTicketGeneratorState(state), 'salesOrderNumbers');
export const getIsGenerating = state => _get(pickTicketGeneratorState(state), 'isGenerating');
export const getShowPickTicketsPopup = state =>
  _get(pickTicketGeneratorState(state), 'showPickTicketsPopup');
export const getGeneratedPickTickets = state =>
  _get(pickTicketGeneratorState(state), 'generatedPickTickets');
export const getShowPickTicketGeneratorModal = state =>
  _get(pickTicketGeneratorState(state), 'showPickTicketGeneratorModal');
