import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoadableSpinner from 'components/LoadableSpinner';
import LoadableSuspense from 'components/LoadableSuspense';
import LoadableError from 'components/LoadableError';
import ROUTES from 'constants/routes';
import PrivateRoute from './PrivateRoute';

const LoadableDashboard = LoadableSuspense({
  loader: () => import(/* webpackChunkName: "dashboard" */ 'containers/Dashboard'),
  loadingComponent: LoadableSpinner,
  errorComponent: LoadableError,
});

const LoadableBase = LoadableSuspense({
  loader: () => import(/* webpackChunkName: "base" */ 'containers/Base'),
  loadingComponent: LoadableSpinner,
  errorComponent: LoadableError,
});

const Router = () => (
  <Switch>
    <Redirect
      exact
      from={ROUTES.BASE}
      to={ROUTES.LOGIN.BASE}
    />
    <Route
      path={ROUTES.LOGIN.BASE}
      component={LoadableBase}
    />
    <PrivateRoute
      path={ROUTES.DASHBOARD}
      component={LoadableDashboard}
    />
    <Route path="*">
      <Redirect to={ROUTES.BASE} />
    </Route>
  </Switch>
);

export default memo(Router);
