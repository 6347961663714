import { all, takeEvery, put, call } from 'redux-saga/effects';
import { handleURLFilters } from 'features/sagaHelpers';
import { ACTIONS } from '../constants';
import { fetchBrands, fetchSuppliers, loadBrandSuppliers, setFilters } from '../actions';

function* initContainerWatcher() {
  yield call(handleURLFilters, setFilters);
  yield all([put(loadBrandSuppliers()), put(fetchBrands()), put(fetchSuppliers())]);
}

function* exitContainerWatcher() {
  //
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
  ]);
}
