import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  salesOrderItems: null,
  isScanningSalesOrder: false,
  isConfirmingShipment: false,
  scannedItems: null,
  salesOrderNumber: null,
  trackingNumber: null,
};

const setConfirmShipment = (state, { trackingNumber }) => ({
  ...state,
  trackingNumber,
  isConfirmingShipment: true,
});

const setConfirmShipmentSuccess = state => ({
  ...state,
  isConfirmingShipment: false,
  trackingNumber: INITIAL_STATE.trackingNumber,
});

const setConfirmShipmentError = state => ({
  ...state,
  isConfirmingShipment: false,
  trackingNumber: INITIAL_STATE.trackingNumber,
});

const setIsScanningSalesOrder = (state, isScanningSalesOrder) => ({
  ...state,
  isScanningSalesOrder,
});

const setScanSalesOrderSuccess = (state, { payload }) => ({
  ...state,
  isScanningSalesOrder: INITIAL_STATE.isScanningSalesOrder,
  salesOrderItems: payload.order_lines,
});

const updateScannedItems = (state, { scannedItems }) => ({
  ...state,
  scannedItems,
});

const updateSalesOrderNumber = (state, { salesOrderNumber }) => ({
  ...state,
  salesOrderNumber,
});

const setToggleShipments = state => ({
  ...state,
});

export const handlers = {
  [ACTIONS.SCAN_SALES_ORDER]: state => setIsScanningSalesOrder(state, true),
  [ACTIONS.SCAN_SALES_ORDER_SUCCESS]: setScanSalesOrderSuccess,
  [ACTIONS.SET_SCANNED_ITEMS]: updateScannedItems,
  [ACTIONS.SET_SALES_ORDER_NUMBER]: updateSalesOrderNumber,
  [ACTIONS.CONFIRM_SHIPMENT]: setConfirmShipment,
  [ACTIONS.CONFIRM_SHIPMENT_SUCCESS]: setConfirmShipmentSuccess,
  [ACTIONS.CONFIRM_SHIPMENT_ERROR]: setConfirmShipmentError,
  [ACTIONS.TOGGLE_SHIPMENTS]: setToggleShipments,
};

export default createReducer(INITIAL_STATE, handlers);
