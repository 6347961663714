import { all, takeEvery, put, call } from 'redux-saga/effects';
import { fetchProductsCSV } from 'services/productManagement/actions';
import { errorToasterSaga, fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import { exportToFileSuccess, exportToFileError } from '../actions';

const EXPORT_FILE_NAME = 'products.csv';

function* exportToFileWatcher() {
  yield put(fetchProductsCSV(exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, errorToasterSaga),
  ]);
}
