import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _isPlainObject from 'lodash/isPlainObject';

export const queryStringObjFromString = str => {
  if (!str) {
    return {};
  }
  return str
    .substr(0)
    .split('&')
    .map(pair => pair.split('='))
    .reduce(
      (obj, pair) => ({
        ...obj,
        [pair[0]]: decodeURIComponent(pair[1]),
      }),
      {},
    );
};

export const removeQueryString = str => str.split('?')[0];

export const queryStringFromObj = obj => {
  if (!_isPlainObject(obj)) {
    return '';
  }
  const chain = _map(obj, (value, key) => {
    const flatValue = _isPlainObject(value)
      ? encodeURIComponent(JSON.stringify(value))
      : encodeURIComponent(value);
    return `${key}=${flatValue}`;
  });
  return !_isEmpty(chain) ? `?${chain.join('&')}` : '';
};
