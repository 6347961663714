import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initInventoryViewContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitInventoryViewContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const setFieldZoneName = createAction(ACTIONS.SET_FIELD_ZONE_NAME, 'fieldZoneName');
export const setFieldUPCNumber = createAction(ACTIONS.SET_FIELD_UPC_NUMBER, 'fieldUPCNumber');

export const setChangedItems = createAction(ACTIONS.SET_CHANGED_ITEMS, 'list');
export const saveChangedItems = createAction(ACTIONS.SAVE_CHANGED_ITEMS, 'changedItems');
export const saveChangedItemsSuccess = createAction(ACTIONS.SAVE_CHANGED_ITEMS_SUCCESS);
export const saveChangedItemsError = createAction(
  ACTIONS.SAVE_CHANGED_ITEMS_ERROR,
  'errorMessage',
  'error',
);

export const scanZoneName = createAction(ACTIONS.SCAN_ZONE_NAME, 'zoneName');
export const scanZoneNameSuccess = createAction(ACTIONS.SCAN_ZONE_NAME_SUCCESS, 'payload');
export const scanZoneNameError = createAction(
  ACTIONS.SCAN_ZONE_NAME_ERROR,
  'errorMessage',
  'error',
);

export const scanUPC = createAction(ACTIONS.SCAN_UPC, 'upc');
export const scanUPCSuccess = createAction(ACTIONS.SCAN_UPC_SUCCESS, 'payload');
export const scanUPCError = createAction(ACTIONS.SCAN_UPC_ERROR, 'errorMessage', 'error');

export const searchItems = createAction(ACTIONS.SEARCH_ITEMS, 'searchTerm');
export const searchItemsSuccess = createAction(ACTIONS.SEARCH_ITEMS_SUCCESS, 'payload');
export const searchItemsError = createAction(ACTIONS.SEARCH_ITEMS_ERROR, 'errorMessage', 'error');
export const searchItemsReset = createAction(ACTIONS.SEARCH_ITEMS_RESET);
export const searchItemsLoadZone = createAction(ACTIONS.SEARCH_ITEMS_LOAD_ZONE, 'zoneName');
export const searchItemsLoadZoneSuccess = createAction(
  ACTIONS.SEARCH_ITEMS_LOAD_ZONE_SUCCESS,
  'payload',
);
export const searchItemsLoadZoneError = createAction(
  ACTIONS.SEARCH_ITEMS_LOAD_ZONE_ERROR,
  'errorMessage',
  'error',
);

export const toggleZoneLock = createAction(ACTIONS.TOGGLE_ZONE_LOCK);
export const lockZone = createAction(ACTIONS.LOCK_ZONE);
export const lockZoneSuccess = createAction(ACTIONS.LOCK_ZONE_SUCCESS, 'payload');
export const lockZoneError = createAction(ACTIONS.LOCK_ZONE_ERROR, 'errorMessage', 'error');
export const unlockZone = createAction(ACTIONS.UNLOCK_ZONE);
export const unlockZoneSuccess = createAction(ACTIONS.UNLOCK_ZONE_SUCCESS, 'payload');
export const unlockZoneError = createAction(ACTIONS.UNLOCK_ZONE_ERROR, 'errorMessage', 'error');
export const reloadZoneLock = createAction(ACTIONS.RELOAD_ZONE_LOCK);
export const reloadZoneLockSuccess = createAction(ACTIONS.RELOAD_ZONE_LOCK_SUCCESS, 'payload');
export const reloadZoneLockError = createAction(
  ACTIONS.RELOAD_ZONE_LOCK_ERROR,
  'errorMessage',
  'error',
);

export const renameZone = createAction(ACTIONS.RENAME_ZONE, 'zoneName');
export const renameZoneSuccess = createAction(ACTIONS.RENAME_ZONE_SUCCESS, 'payload', 'zoneName');
export const renameZoneError = createAction(ACTIONS.RENAME_ZONE_ERROR, 'errorMessage', 'error');

export const setSelectedItems = createAction(ACTIONS.SET_SELECTED_ITEMS, 'selectedItems');

export const deleteSelectedItems = createAction(ACTIONS.DELETE_SELECTED_ITEMS);
export const deleteSelectedItemsSuccess = createAction(
  ACTIONS.DELETE_SELECTED_ITEMS_SUCCESS,
  'payload',
);
export const deleteSelectedItemsError = createAction(
  ACTIONS.DELETE_SELECTED_ITEMS_ERROR,
  'errorMessage',
  'error',
);

export const moveSelectedItems = createAction(ACTIONS.MOVE_SELECTED_ITEMS, 'targetZoneName');
export const moveSelectedItemsSuccess = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS,
  'payload',
  'targetZoneName',
);
export const moveSelectedItemsError = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_ERROR,
  'errorMessage',
  'error',
);

export const toggleShowDeleteConfirmation = createAction(
  ACTIONS.TOGGLE_SHOW_DELETE_CONFIRMATION,
  'show',
);
export const toggleShowMoveConfirmation = createAction(
  ACTIONS.TOGGLE_SHOW_MOVE_CONFIRMATION,
  'show',
);
export const toggleShowSearchModal = createAction(ACTIONS.TOGGLE_SHOW_SEARCH_MODAL, 'show');

export const reloadZone = createAction(ACTIONS.RELOAD_ZONE);

export const resetInventoryViewState = createAction(ACTIONS.RESET_STATE);
