import { put, all, takeEvery, takeLeading, call, select } from 'redux-saga/effects';
import _compact from 'lodash/compact';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import { inventoryApiSelector } from 'app/api/selectors';
import { getErrorMessage, isError } from 'utils/error';
import { getSessionUserName } from 'app/session/selectors';
import { splitZoneName } from 'utils/zoneName';
import { firebaseWrite } from 'services/firebase/actions';
import { FIREBASE_PATHS } from 'services/firebase/constants';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS, INVENTORY_ERRORS, MOVE_ITEMS_REF } from './constants';
import { buildInventoryItem } from './helpers';
import {
  releaseZoneLock,
  setZoneUnlock,
  fetchShippingZoneNameSuccess,
  fetchShippingZoneNameError,
} from './actions';

function* updateZonesLastChange(zones = []) {
  const timestamp = Date.now();

  const updates = _map(zones, zone => {
    const action = put(
      firebaseWrite(FIREBASE_PATHS.ZONE_LAST_CHANGE({ zoneName: zone }), timestamp),
    );
    return action;
  });

  yield all(updates);
}

function* fetchHasLockWatcher({ zoneName, successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.hasLock], zoneName);
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }

    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchZoneWatcher({ zoneName, successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  try {
    const lockData = yield call(fetchHasLockWatcher, { zoneName });
    if (isError(lockData)) {
      throw new Error(INVENTORY_ERRORS.FETCHING_LOCK);
    }

    const response = yield call([api, api.getZone], zoneName);
    if (!_isObject(response)) {
      throw new Error(INVENTORY_ERRORS.INVALID_API_RESPONSE_RECEIVED('get_zone'));
    }

    const data = {
      ...response.data,
      lock: lockData,
    };

    if (successActionCallback) {
      yield put(successActionCallback(data));
    }
    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setZoneLockWatcher({ zoneName, successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  const sessionUsername = yield select(getSessionUserName);
  try {
    const response = yield call([api, api.acquireLock], zoneName, sessionUsername);
    const { lock_acquired: lockAcquired, message } = response;

    if (!lockAcquired) {
      const errorMessage = message || INVENTORY_ERRORS.ACQUIRING_LOCK;
      throw new Error(errorMessage);
    }

    if (successActionCallback) {
      yield put(successActionCallback(response));
    }

    // update firebase path
    yield put(firebaseWrite(FIREBASE_PATHS.ZONE_LOCK({ zoneName }), sessionUsername));

    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setZoneUnlockWatcher({ zoneName, nonce, successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.releaseLock], zoneName, nonce);
    const { lock_released: lockReleased, message } = response;

    if (!lockReleased) {
      const releaseError = message || INVENTORY_ERRORS.RELEASING_LOCK;
      throw new Error(releaseError);
    }

    if (successActionCallback) {
      yield put(successActionCallback(response));
    }

    // update firebase path
    yield put(firebaseWrite(FIREBASE_PATHS.ZONE_LOCK({ zoneName }), null));

    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setReplaceQtyWatcher({
  zoneName,
  collection,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.replaceQty], _compact(collection));
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }

    yield call(updateZonesLastChange, [zoneName]);
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setMoveItemsWatcher({
  targetZoneName,
  sourceZoneName,
  list,
  successActionCallback,
  errorActionCallback,
  ref,
}) {
  const api = yield select(inventoryApiSelector);

  try {
    const sessionUsername = yield select(getSessionUserName);
    const zoneLock = yield call(fetchHasLockWatcher, { zoneName: targetZoneName });

    if (isError(zoneLock)) {
      throw new Error(INVENTORY_ERRORS.FETCHING_LOCK);
    }

    if (zoneLock.locked && zoneLock.owner !== sessionUsername) {
      throw new Error(INVENTORY_ERRORS.ZONE_LOCKED_BY_ANOTHER_USER(targetZoneName, zoneLock.owner));
    }

    // Acquire Lock if zone is unlocked
    if (!zoneLock.locked) {
      yield call(setZoneLockWatcher, { zoneName: targetZoneName });
    }

    const [row, lvl, col] = yield call(splitZoneName, sourceZoneName);
    const collection = yield all(
      list.map(item => {
        const inventoryItem = call(buildInventoryItem, {
          ...item,
          col,
          lvl,
          row,
        });
        return inventoryItem;
      }),
    );

    const response = yield call([api, api.moveItems], _compact(collection), targetZoneName);

    if (ref === MOVE_ITEMS_REF.RENAMING_ZONE) {
      // Release the Lock from source zone
      yield put(releaseZoneLock(sourceZoneName));
    }

    if (successActionCallback) {
      yield put(successActionCallback(response, targetZoneName));
    }

    yield call(updateZonesLastChange, [targetZoneName, sourceZoneName]);

    return {
      ...response,
      targetZoneName,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* releaseZoneLockWatcher({ zoneName }) {
  const { nonce } = yield call(fetchHasLockWatcher, { zoneName });
  yield put(setZoneUnlock(zoneName, nonce));
}

function* fetchSearchItemWatcher({ searchTerm, successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  try {
    const { data } = yield call([api, api.searchItem], searchTerm);
    const response = {
      searchTerm,
      ...data,
    };
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setMoveReplaceDeltaQtyWatcher({
  sourceZoneName,
  targetZoneName,
  sourceList,
  targetList,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call(
      [api, api.moveReplaceDeltaQty],
      sourceList,
      targetList,
      targetZoneName,
    );
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    yield call(updateZonesLastChange, [sourceZoneName, targetZoneName]);
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

export function* fetchShippingZoneNameWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(inventoryApiSelector);
  try {
    const { shipping, user } = yield call([api, api.getHoldingShippingZone]);
    const shippingZoneName = shipping.join('').toUpperCase();
    const personalZoneName = user.join('');

    if (successActionCallback) {
      yield put(successActionCallback(shippingZoneName));
    }
    yield put(fetchShippingZoneNameSuccess(shippingZoneName, personalZoneName));
    return shippingZoneName;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    yield put(fetchShippingZoneNameError(errorMessage, error));
    return error;
  }
}

function* setMoveToShippingWatcher({
  pickingZoneName,
  shippingZoneName,
  list,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.moveToShipping], list, shippingZoneName);
    if (successActionCallback) {
      yield put(successActionCallback(response, list));
    }

    yield call(updateZonesLastChange, [pickingZoneName, shippingZoneName]);
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setMoveFromShippingWatcher({
  pickingZoneName,
  shippingZoneName,
  list,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.moveFromShipping], list, shippingZoneName);
    if (successActionCallback) {
      yield put(successActionCallback(response, list));
    }

    yield call(updateZonesLastChange, [pickingZoneName, shippingZoneName]);
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchDecrementQtyWatcher({
  location,
  upc,
  qty,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const response = yield call([api, api.decrementQty], location, upc, qty);
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }

    yield call(updateZonesLastChange, [location]);
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchGetZoneAvailabilityWatcher({
  sku,
  upc,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(inventoryApiSelector);
  try {
    const { data } = yield call([api, api.getZoneAvailability], sku, upc);
    if (successActionCallback) {
      yield put(successActionCallback(data, { sku, upc }));
    }

    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

export default function* inventoryServiceSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_ZONE, authRequired, fetchZoneWatcher),
    takeLeading(ACTIONS.FETCH_HAS_LOCK, authRequired, fetchHasLockWatcher),
    takeLeading(ACTIONS.FETCH_SEARCH_ITEM, authRequired, fetchSearchItemWatcher),
    takeLeading(ACTIONS.FETCH_SHIPPING_ZONE_NAME, authRequired, fetchShippingZoneNameWatcher),
    takeLeading(ACTIONS.SET_ZONE_LOCK, authRequired, setZoneLockWatcher),
    takeLeading(ACTIONS.SET_ZONE_UNLOCK, authRequired, setZoneUnlockWatcher),
    takeLeading(ACTIONS.SET_REPLACE_QTY, authRequired, setReplaceQtyWatcher),
    takeLeading(ACTIONS.SET_MOVE_ITEMS, authRequired, setMoveItemsWatcher),
    takeLeading(ACTIONS.SET_MOVE_REPLACE_DELTA_QTY, authRequired, setMoveReplaceDeltaQtyWatcher),
    takeLeading(ACTIONS.SET_MOVE_TO_SHIPPING, authRequired, setMoveToShippingWatcher),
    takeLeading(ACTIONS.SET_MOVE_FROM_SHIPPING, authRequired, setMoveFromShippingWatcher),
    takeLeading(ACTIONS.RELEASE_ZONE_LOCK, authRequired, releaseZoneLockWatcher),
    takeEvery(ACTIONS.FETCH_DECREMENT_QTY, authRequired, fetchDecrementQtyWatcher),
    takeEvery(ACTIONS.FETCH_GET_ZONE_AVAILABILITY, authRequired, fetchGetZoneAvailabilityWatcher),
  ]);
}
