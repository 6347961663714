const ENDPOINTS = {
  PRODUCT_INFO: '/product_info',
  SHIP_CITIES: '/ship_cities',
  ADJUSTED_INVENTORY: '/adjusted_inventory',
  ADJUSTED_INVENTORY_COST: '/adjusted_inventory_cost_report',
  INVENTORY_LOCATIONS: '/inventory_locations',
  INVENTORY_QTYS: '/inventory_qtys',
  SALES_REPORT: '/sales_report',
  SALES_REPORT_SEARCH: '/sales_report_search',
};

export default ENDPOINTS;
