import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setRemovePurchaseOrder, setUpdatePurchaseOrder } from 'services/purchaseOrders/actions';
import { ACTIONS } from '../constants';
import {
  removePurchaseOrderError,
  removePurchaseOrderSuccess,
  refreshFeed,
  updatePurchaseOrderSuccess,
  updatePurchaseOrderError,
} from '../actions';
import { getSelectedPurchaseOrder } from '../selectors';

function* removePurchaseOrderWatcher() {
  const { po } = yield select(getSelectedPurchaseOrder);
  yield put(setRemovePurchaseOrder(po, removePurchaseOrderSuccess, removePurchaseOrderError));
}

function* removePurchaseOrderSuccessWatcher({ payload }) {
  const { result } = payload;
  if (result === 'success') {
    yield call([toast, toast.success], 'Purchase Order successfully deleted.');
    yield put(refreshFeed());
  }
}

function* removePurchaseOrderErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* updatePurchaseOrderWatcher({ purchaseOrderData }) {
  yield put(
    setUpdatePurchaseOrder(purchaseOrderData, updatePurchaseOrderSuccess, updatePurchaseOrderError),
  );
}

function* updatePurchaseOrderSuccessWatcher({ payload }) {
  const { result } = payload;
  if (result === 'success') {
    yield call([toast, toast.success], 'Purchase Order successfully updated.');
    yield put(refreshFeed());
  }
}

function* updatePurchaseOrderErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.REMOVE_PURCHASE_ORDER, removePurchaseOrderWatcher),
    takeEvery(ACTIONS.REMOVE_PURCHASE_ORDER_SUCCESS, removePurchaseOrderSuccessWatcher),
    takeEvery(ACTIONS.REMOVE_PURCHASE_ORDER_ERROR, removePurchaseOrderErrorWatcher),

    takeEvery(ACTIONS.UPDATE_PURCHASE_ORDER, updatePurchaseOrderWatcher),
    takeEvery(ACTIONS.UPDATE_PURCHASE_ORDER_SUCCESS, updatePurchaseOrderSuccessWatcher),
    takeEvery(ACTIONS.UPDATE_PURCHASE_ORDER_ERROR, updatePurchaseOrderErrorWatcher),
  ]);
}
