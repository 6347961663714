import _get from 'lodash/get';
import { servicesState } from '../selectors';

const inventoryState = state => _get(servicesState(state), 'inventory');
export const getIsFetchingShippingZoneName = state =>
  _get(inventoryState(state), 'isFetchingShippingZoneName');
export const getShippingZoneName = state => _get(inventoryState(state), 'shippingZoneName');
export const getPersonalZoneName = state => _get(inventoryState(state), 'personalZoneName');
export const getFetchShippingZoneNameError = state =>
  _get(inventoryState(state), 'fetchShippingZoneError');
