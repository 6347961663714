import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchZone = createAction(
  ACTIONS.FETCH_ZONE,
  'zoneName',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchHasLock = createAction(
  ACTIONS.FETCH_HAS_LOCK,
  'zoneName',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchSearchItem = createAction(
  ACTIONS.FETCH_SEARCH_ITEM,
  'searchTerm',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchShippingZoneName = createAction(
  ACTIONS.FETCH_SHIPPING_ZONE_NAME,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchShippingZoneNameSuccess = createAction(
  ACTIONS.FETCH_SHIPPING_ZONE_NAME_SUCCESS,
  'shippingZoneName',
  'personalZoneName',
);
export const fetchShippingZoneNameError = createAction(
  ACTIONS.FETCH_SHIPPING_ZONE_NAME_ERROR,
  'errorMessage',
  'error',
);
export const setZoneLock = createAction(
  ACTIONS.SET_ZONE_LOCK,
  'zoneName',
  'successActionCallback',
  'errorActionCallback',
);
export const setZoneUnlock = createAction(
  ACTIONS.SET_ZONE_UNLOCK,
  'zoneName',
  'nonce',
  'successActionCallback',
  'errorActionCallback',
);
export const setReplaceQty = createAction(
  ACTIONS.SET_REPLACE_QTY,
  'zoneName',
  'collection',
  'successActionCallback',
  'errorActionCallback',
);
export const setMoveItems = createAction(
  ACTIONS.SET_MOVE_ITEMS,
  'targetZoneName',
  'sourceZoneName',
  'list',
  'successActionCallback',
  'errorActionCallback',
);
export const setMoveReplaceDeltaQty = createAction(
  ACTIONS.SET_MOVE_REPLACE_DELTA_QTY,
  'sourceZoneName',
  'targetZoneName',
  'sourceList',
  'targetList',
  'successActionCallback',
  'errorActionCallback',
);
export const setMoveToShipping = createAction(
  ACTIONS.SET_MOVE_TO_SHIPPING,
  'pickingZoneName',
  'shippingZoneName',
  'list',
  'successActionCallback',
  'errorActionCallback',
);
export const setMoveFromShipping = createAction(
  ACTIONS.SET_MOVE_FROM_SHIPPING,
  'pickingZoneName',
  'shippingZoneName',
  'list',
  'successActionCallback',
  'errorActionCallback',
);
export const releaseZoneLock = createAction(
  ACTIONS.RELEASE_ZONE_LOCK,
  'zoneName',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchDecrementQty = createAction(
  ACTIONS.FETCH_DECREMENT_QTY,
  'location',
  'upc',
  'qty',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchGetZoneAvailability = createAction(
  ACTIONS.FETCH_GET_ZONE_AVAILABILITY,
  'sku',
  'upc',
  'successActionCallback',
  'errorActionCallback',
);
