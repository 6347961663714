import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ROUTES from 'constants/routes';
import { getRouterQuery } from 'app/router/selectors';
import { setVerifyPasswordReset, setChangeTemporaryPassword } from 'services/auth/actions';
import {
  changePasswordError,
  changePasswordSuccess,
  resetState, setTokenCode,
} from './actions';
import { ACTIONS } from './constants';
import { getChangeRequired, getOldPassword, getToken, getCode } from './selectors';

function* initContainerWatcher() {
  const urlQueryObj = yield select(getRouterQuery);
  const changeRequired = _get(urlQueryObj, 'change_required') === 'true';
  const token = _get(urlQueryObj, 'token');
  const code = _get(urlQueryObj, 'code');
  const oldPassword = yield select(getOldPassword);

  if (changeRequired && !_isEmpty(oldPassword)) {
    return;
  }

  if (changeRequired && _isEmpty(oldPassword)) {
    yield put(push(ROUTES.LOGIN.BASE));
    return;
  }

  if (_isEmpty(token) || _isEmpty(code)) {
    yield put(push(ROUTES.LOGIN.RESET_PASSWORD));
    return;
  }

  if (!_isEmpty(token) && !_isEmpty(code)) {
    const decodedToken = decodeURIComponent(token);
    yield put(setTokenCode(decodedToken, code));
    yield put(push(ROUTES.LOGIN.NEW_PASSWORD()));
    return;
  }

  // go to login
  yield put(push(ROUTES.LOGIN.BASE));
}

function* exitContainerWatcher() {
  yield put(resetState());
}

function* temporaryPasswordChange(newPassword) {
  const oldPassword = yield select(getOldPassword);
  const data = {
    oldPassword,
    newPassword,
  };
  yield put(setChangeTemporaryPassword(data, changePasswordSuccess, changePasswordError));
}

function* forgotPasswordChange(newPassword) {
  const token = yield select(getToken);
  const code = yield select(getCode);
  const data = {
    token,
    code,
    newPassword,
  };

  yield put(setVerifyPasswordReset(data, changePasswordSuccess, changePasswordError));
}

function* changePasswordWatcher({ newPassword }) {
  const changeRequired = yield select(getChangeRequired);
  if (changeRequired) {
    yield call(temporaryPasswordChange, newPassword);
  } else {
    yield call(forgotPasswordChange, newPassword);
  }
}

function* changePasswordSuccessWatcher() {
  yield call([toast, toast.success], 'Password changed. Sign in with your new password.');
  yield put(push(ROUTES.LOGIN.BASE));
}

function* changePasswordErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* newPasswordSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD, changePasswordWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD_SUCCESS, changePasswordSuccessWatcher),
    takeEvery(ACTIONS.CHANGE_PASSWORD_ERROR, changePasswordErrorWatcher),
  ]);
}
