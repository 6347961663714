import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const initContainerSuccess = createAction(ACTIONS.INIT_CONTAINER_SUCCESS);
export const initContainerError = createAction(ACTIONS.INIT_CONTAINER_ERROR);
export const toggleDrawer = createAction(ACTIONS.TOGGLE_DRAWER);
export const toggleHelpMenu = createAction(ACTIONS.TOGGLE_HELP_MENU);
export const setFeatureName = createAction(ACTIONS.SET_FEATURE_NAME, 'featureName');
export const setDocumentTitle = createAction(ACTIONS.SET_DOCUMENT_TITLE, 'documentTitle');
export const toggleSidebar = createAction(ACTIONS.TOGGLE_SIDEBAR, 'open');
export const setIsMobileDevice = createAction(ACTIONS.SET_IS_MOBILE_DEVICE, 'isMobileDevice');
