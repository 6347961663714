import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import { EUCALYPTUS, POMEGRANATE, TEXT_SUCCESS } from 'constants/colors';

export const TextFieldWrap = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  ${({ success }) =>
    success &&
    `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${EUCALYPTUS} !important;
    }
    .MuiFormLabel-root, .MuiFormHelperText-root {
      color: ${TEXT_SUCCESS} !important;
    }
  `}
`;

export const TextFieldError = styled.p`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: ${POMEGRANATE};
  font-size: 0.75rem;
`;
