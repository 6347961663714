import { all, takeEvery } from 'redux-saga/effects';
import { dataVisualizationApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const getSalesSummaryWatcher = action =>
  serviceSaga(dataVisualizationApiSelector, 'getSalesSummary', action);
const getSalesChartWatcher = action =>
  serviceSaga(dataVisualizationApiSelector, 'getSalesChart', action);
const getInventoryLocationsChartWatcher = action =>
  serviceSaga(dataVisualizationApiSelector, 'getInventoryLocationsChart', action);
const getInventoryLocationsSummaryWatcher = action =>
  serviceSaga(dataVisualizationApiSelector, 'getInventoryLocationsSummary', action);

export default function* dataVisualizationSagas() {
  yield all([
    takeEvery(ACTIONS.GET_SALES_SUMMARY, authRequired, getSalesSummaryWatcher),
    takeEvery(ACTIONS.GET_SALES_CHART, authRequired, getSalesChartWatcher),
    takeEvery(
      ACTIONS.GET_INVENTORY_LOCATIONS_CHART,
      authRequired,
      getInventoryLocationsChartWatcher,
    ),
    takeEvery(
      ACTIONS.GET_INVENTORY_LOCATIONS_SUMMARY,
      authRequired,
      getInventoryLocationsSummaryWatcher,
    ),
  ]);
}
