import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isInitiatingContainer: false,

  isLoadingChannels: false,
  isLoadingIntegrations: false,

  isSubmittingChannelData: false,
  isConnectingIntegration: false,

  isDisconnectingId: '', // lookupId

  salesChannels: null,
  channelTypes: null,
  integrationServices: null,

  showCreateChannelModal: false,
  showEditChannelModal: false,
  showDeleteChannelModal: false,

  showAddIntegrationModal: false,

  channelTypeSelected: null,
  channelIdSelected: null,

  createdChannelData: null,

  authorizationError: null,
};

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsInitContainer = (state, isInitiatingContainer) => ({
  ...state,
  isInitiatingContainer,
});

const setIsLoadingChannels = (state, isLoadingChannels) => ({
  ...state,
  isLoadingChannels,
});

const setIsLoadingIntegrations = (state, isLoadingIntegrations) => ({
  ...state,
  isLoadingIntegrations,
});

const updateChannels = (state, { payload }) => ({
  ...state,
  salesChannels: payload.sales_channels,
  isLoadingChannels: INITIAL_STATE.isLoadingChannels,
});

const updateIntegrations = (state, { payload }) => ({
  ...state,
  channelTypes: payload.channel_types,
  isLoadingIntegrations: INITIAL_STATE.isLoadingIntegrations,
});

const updateShowCreateChannelModal = (state, { showCreateChannelModal }) => ({
  ...state,
  showCreateChannelModal,
});

const updateShowAddIntegrationModal = (state, { showAddIntegrationModal }) => ({
  ...state,
  showAddIntegrationModal,
});

const updateShowEditChannelModal = (state, { showEditChannelModal }) => ({
  ...state,
  showEditChannelModal,
});

const updateShowDeleteChannelModal = (state, { showDeleteChannelModal }) => ({
  ...state,
  showDeleteChannelModal,
});

const updateChannelTypeSelected = (state, { channelTypeSelected }) => ({
  ...state,
  channelTypeSelected,
});

const updateChannelIdSelected = (state, { channelIdSelected }) => ({
  ...state,
  channelIdSelected,
});

const setIsSubmittingChannelData = (state, isSubmittingChannelData) => ({
  ...state,
  isSubmittingChannelData,
});

const updateIntegrationServices = (state, { payload }) => ({
  ...state,
  integrationServices: payload.integration_services,
});

const updateCreateSalesChannel = (state, { channelData }) => ({
  ...state,
  createdChannelData: channelData,
  isSubmittingChannelData: true,
});

const updateCreatedChannelData = (state, { createdChannelData }) => ({
  ...state,
  createdChannelData,
});

const resetCreatingChannel = state => ({
  ...state,
  createdChannelData: null,
  isSubmittingChannelData: false,
});

const setIsConnectingIntegration = (state, isConnectingIntegration) => ({
  ...state,
  isConnectingIntegration,
});

const setIsDisconnectingId = (state, lookupId) => ({
  ...state,
  isDisconnectingId: lookupId,
});

const updateAuthorizationError = (state, { authorizationError }) => ({
  ...state,
  authorizationError,
});

export const handlers = {
  [ACTIONS.INIT_CONTAINER]: state => setIsInitContainer(state, true),
  [ACTIONS.INIT_CONTAINER_SUCCESS]: state => setIsInitContainer(state, false),
  [ACTIONS.INIT_CONTAINER_ERROR]: state => setIsInitContainer(state, false),

  [ACTIONS.LOAD_SALES_CHANNELS]: state => setIsLoadingChannels(state, true),
  [ACTIONS.LOAD_SALES_CHANNELS_SUCCESS]: updateChannels,
  [ACTIONS.LOAD_SALES_CHANNELS_ERROR]: state => setIsLoadingChannels(state, false),

  [ACTIONS.LOAD_CHANNEL_TYPES]: state => setIsLoadingIntegrations(state, true),
  [ACTIONS.LOAD_CHANNEL_TYPES_SUCCESS]: updateIntegrations,
  [ACTIONS.LOAD_CHANNEL_TYPES_ERROR]: state => setIsLoadingIntegrations(state, false),

  [ACTIONS.LOAD_INTEGRATION_SERVICES_SUCCESS]: updateIntegrationServices,

  [ACTIONS.SET_SHOW_CREATE_CHANNEL_MODAL]: updateShowCreateChannelModal,
  [ACTIONS.SET_SHOW_EDIT_CHANNEL_MODAL]: updateShowEditChannelModal,
  [ACTIONS.SET_SHOW_DELETE_CHANNEL_MODAL]: updateShowDeleteChannelModal,

  [ACTIONS.SET_SHOW_ADD_INTEGRATION_MODAL]: updateShowAddIntegrationModal,

  [ACTIONS.SET_CHANNEL_TYPE_SELECTED]: updateChannelTypeSelected,
  [ACTIONS.SET_CHANNEL_ID_SELECTED]: updateChannelIdSelected,

  [ACTIONS.SET_CREATED_CHANNEL_DATA]: updateCreatedChannelData,

  [ACTIONS.CREATE_SALES_CHANNEL]: updateCreateSalesChannel,
  [ACTIONS.CREATE_SALES_CHANNEL_SUCCESS]: state => setIsSubmittingChannelData(state, false),
  [ACTIONS.CREATE_SALES_CHANNEL_ERROR]: resetCreatingChannel,

  [ACTIONS.UPDATE_SALES_CHANNEL]: state => setIsSubmittingChannelData(state, true),
  [ACTIONS.UPDATE_SALES_CHANNEL_SUCCESS]: state => setIsSubmittingChannelData(state, false),
  [ACTIONS.UPDATE_SALES_CHANNEL_ERROR]: state => setIsSubmittingChannelData(state, false),

  [ACTIONS.REMOVE_SALES_CHANNEL]: state => setIsSubmittingChannelData(state, true),
  [ACTIONS.REMOVE_SALES_CHANNEL_SUCCESS]: state => setIsSubmittingChannelData(state, false),
  [ACTIONS.REMOVE_SALES_CHANNEL_ERROR]: state => setIsSubmittingChannelData(state, false),

  [ACTIONS.CREATE_INTEGRATION]: state => setIsConnectingIntegration(state, true),
  [ACTIONS.CREATE_INTEGRATION_SUCCESS]: state => setIsConnectingIntegration(state, false),
  [ACTIONS.CREATE_INTEGRATION_ERROR]: state => setIsConnectingIntegration(state, false),

  [ACTIONS.REMOVE_INTEGRATION]: (state, { lookupId }) => setIsDisconnectingId(state, lookupId),
  [ACTIONS.REMOVE_INTEGRATION_SUCCESS]: state => setIsDisconnectingId(state, ''),
  [ACTIONS.REMOVE_INTEGRATION_ERROR]: state => setIsDisconnectingId(state, ''),

  [ACTIONS.SET_AUTHORIZATION_ERROR]: updateAuthorizationError,

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
