import { put, all, takeEvery, call, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { toast } from 'react-toastify';
import { DEV_MODE, LOCAL_MODE } from 'app/config';
import { firebaseApiSelector } from 'app/api/selectors';
import { parseJwt } from 'utils/jwt';
import { getErrorMessage } from 'utils/error';
import FirebaseConfig from './firebase.config';
import { ACTIONS } from './constants';
import { firebaseSetSession } from './actions';
import { getFirebaseUserCompany } from './selectors';

function* firebaseReference(path) {
  const firebaseDatabaseRef = yield call([firebase, firebase.database]);
  return firebaseDatabaseRef.ref().child(path);
}

function* firebasePath(path) {
  const userCompany = yield select(getFirebaseUserCompany);
  return `${userCompany}/${path}`;
}

function* createChannelAndSubscribe({
  path,
  dataReceivedActionCallback,
  takeDataOnSubscription = false,
  subscriptionActionCallback,
}) {
  try {
    const channelPath = yield call(firebasePath, path);
    const channelRef = yield call(firebaseReference, channelPath);
    const channel = eventChannel(emit => {
      const handler = firebaseData => {
        const data = firebaseData.val();
        if (DEV_MODE) {
          // eslint-disable-next-line no-console
          console.log('channel:', channelPath, 'emitted:', data);
        }
        emit(data || {});
      };

      channelRef.on('value', handler);

      return () => {
        channelRef.off('value', handler);
      };
    });

    if (subscriptionActionCallback) {
      yield put(subscriptionActionCallback());
    }

    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log('Firebase subscribed:', channelPath);
    }

    let isSubscribed = false;

    while (true) {
      const data = yield take(channel);
      if (takeDataOnSubscription) {
        yield put(dataReceivedActionCallback(data, path));
      } else if (isSubscribed) {
        yield put(dataReceivedActionCallback(data, path));
      } else {
        isSubscribed = true;
      }
    }
  } catch (error) {
    const errorMessage = `[FIREBASE SUBSCRIPTION] ${getErrorMessage(error)}`;
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(errorMessage);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

function* cancelSubscription({ path }) {
  const channelPath = yield call(firebasePath, path);
  const channelRef = yield call(firebaseReference, channelPath);
  channelRef.off();
  if (DEV_MODE) {
    // eslint-disable-next-line no-console
    console.log('Firebase unsubscribed:', channelPath);
  }
}

function* writeToPath({ path, value }) {
  const channelPath = yield call(firebasePath, path);
  const channelRef = yield call(firebaseReference, channelPath);
  channelRef.set(value, error => {
    if (error) {
      const errorMessage = `[FIREBASE WRITE] ${getErrorMessage(error)}`;
      toast.error(errorMessage);
      if (DEV_MODE) {
        // eslint-disable-next-line no-console
        console.log(errorMessage);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });
}

function* readFromPath({ path, dataActionCallback }) {
  try {
    const channelPath = yield call(firebasePath, path);
    const channelRef = yield call(firebaseReference, channelPath);
    const snapshot = yield call([channelRef, channelRef.once], 'value');
    const data = snapshot.val();
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log('channel:', channelPath, 'data:', data);
    }
    if (dataActionCallback) {
      yield put(dataActionCallback(data, path));
    }
    return data;
  } catch (error) {
    const errorMessage = `[FIREBASE READ] ${getErrorMessage(error)}`;
    if (DEV_MODE) {
      // eslint-disable-next-line no-console
      console.log(errorMessage);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

function* signOffFirebase() {
  const auth = yield call([firebase, firebase.auth]);
  yield call([auth, auth.signOut]);
}

function* signInFirebase() {
  const api = yield select(firebaseApiSelector);
  try {
    yield call(signOffFirebase);

    const token = yield call([api, api.getReadWriteToken]);
    const auth = yield call([firebase, firebase.auth]);
    const decodedToken = yield call(parseJwt, token);
    const { claims } = decodedToken;
    yield call([auth, auth.signInWithCustomToken], token);
    yield put(firebaseSetSession(claims));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield call([toast, toast.error], `FIREBASE: ${errorMessage}`);
    return error;
  }
}

export default function* firebaseSagas() {
  if (!LOCAL_MODE) {
    firebase.initializeApp(FirebaseConfig);

    yield all([
      takeEvery(ACTIONS.FIREBASE_SIGN_IN, signInFirebase),
      takeEvery(ACTIONS.FIREBASE_SIGN_OFF, signOffFirebase),
      takeEvery(ACTIONS.FIREBASE_SUBSCRIBE, createChannelAndSubscribe),
      takeEvery(ACTIONS.FIREBASE_UNSUBSCRIBE, cancelSubscription),
      takeEvery(ACTIONS.FIREBASE_WRITE, writeToPath),
      takeEvery(ACTIONS.FIREBASE_READ, readFromPath),
    ]);
  }
}
