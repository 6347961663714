import { all, takeEvery, put, take, race, call, select } from 'redux-saga/effects';
import { APP_VERSION } from 'app/config';
import { selectAppVersion } from 'app/globalState/selectors';
import { setAppVersion } from 'app/globalState/actions';
import { formatUserLocalStorageKey } from 'app/persistedState';
import { isMobileDeviceBrowser } from 'utils/browser';
import { filterLocalStorageItems, removeFromLocalStorage } from 'utils/localStorage';
import { authRequired } from 'services/auth/sagas';
import { firebaseSignIn } from 'services/firebase/actions';
import { loadUserProfile } from 'features/AccountSettings/Profile/actions';
import { ACTIONS as PROFILE_ACTIONS } from 'features/AccountSettings/Profile/constants';
import { ACTIONS } from './constants';
import { setIsMobileDevice, initContainerSuccess, initContainerError } from './actions';

function* appVersionCheck() {
  // This function will check if the app version changed
  // if so, then will clean the local storage items of the user
  // in order to get fresh new objects
  // this is to avoid errors when objects where changed
  const localAppVersion = yield select(selectAppVersion);
  if (APP_VERSION !== localAppVersion) {
    // all users localStorage keys should be filtered
    const regExp = new RegExp(formatUserLocalStorageKey(''));
    const localStorageItems = filterLocalStorageItems(regExp);
    localStorageItems.forEach(({ key }) => {
      removeFromLocalStorage(key);
    });
  }
}

function* initContainerWatcher() {
  yield call(appVersionCheck);

  yield all([
    put(setIsMobileDevice(isMobileDeviceBrowser(navigator))),
    put(setAppVersion()),
    put(firebaseSignIn()),
    put(loadUserProfile()),
  ]);

  const { success, error } = yield race({
    success: take(PROFILE_ACTIONS.LOAD_USER_PROFILE_SUCCESS),
    error: take(PROFILE_ACTIONS.LOAD_USER_PROFILE_ERROR),
  });

  if (success) {
    yield put(initContainerSuccess());
  } else if (error) {
    yield put(initContainerError());
  }
}

export default function* dashboardSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, authRequired, initContainerWatcher),
  ]);
}
