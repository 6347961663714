export const ACTIONS = {
  LOAD_COMPANY_DATA: 'BUSINESS__LOAD_COMPANY_DATA',
  LOAD_COMPANY_DATA_SUCCESS: 'BUSINESS__LOAD_COMPANY_DATA_SUCCESS',
  LOAD_COMPANY_DATA_ERROR: 'BUSINESS__LOAD_COMPANY_DATA_ERROR',

  UPDATE_BUSINESS_INFO: 'BUSINESS__UPDATE_BUSINESS_INFO',
  UPDATE_BUSINESS_INFO_SUCCESS: 'BUSINESS__UPDATE_BUSINESS_INFO_SUCCESS',
  UPDATE_BUSINESS_INFO_ERROR: 'BUSINESS__UPDATE_BUSINESS_INFO_ERROR',

  UPDATE_PICK_TICKET_INFO: 'BUSINESS__UPDATE_PICK_TICKET_INFO',
  UPDATE_PICK_TICKET_INFO_SUCCESS: 'BUSINESS__UPDATE_PICK_TICKET_INFO_SUCCESS',
  UPDATE_PICK_TICKET_INFO_ERROR: 'BUSINESS__UPDATE_PICK_TICKET_INFO_ERROR',

  UPDATE_BUSINESS_LOGO: 'BUSINESS__UPDATE_BUSINESS_LOGO',
  UPDATE_BUSINESS_LOGO_SUCCESS: 'BUSINESS__UPDATE_BUSINESS_LOGO',
  UPDATE_BUSINESS_LOGO_ERROR: 'BUSINESS__UPDATE_BUSINESS_LOGO',

  SET_AUTHORIZATION_ERROR: 'BUSINESS__SET_AUTHORIZATION_ERROR',

  RESET_STATE: 'BUSINESS__RESET_STATE',
};

export const requiredMessage = 'This field is required.';
