import _get from 'lodash/get';
import { purchaseOrderManagementState } from '../selectors';

export const purchaseOrdersState = state =>
  _get(purchaseOrderManagementState(state), 'purchaseOrders');

// feeedReducers
const feedState = state => _get(purchaseOrdersState(state), 'feed');
export const getFeedData = state => _get(feedState(state), 'data');
export const getPurchaseOrders = state => _get(getFeedData(state), 'purchase_orders');
export const getFeedDataTotalRecords = state => _get(getFeedData(state), 'totalRecords');
export const getFeedDataPage = state => _get(getFeedData(state), 'page');
export const getFeedDataItemsPerPage = state => _get(getFeedData(state), 'itemsPerPage');
export const getLastPage = state => _get(feedState(state), 'lastPage');
export const getFilters = state => _get(feedState(state), 'filters');

export const getShowOptionsModal = state => _get(feedState(state), 'showOptionsModal');

export const getIsExporting = state => _get(feedState(state), 'isExporting');
export const getIsRefreshing = state => _get(feedState(state), 'isRefreshing');
export const getIsFetchingFeed = state => _get(feedState(state), 'isFetchingFeed');

// management
const managementState = state => _get(purchaseOrdersState(state), 'management');
export const getSelectedPurchaseOrder = state =>
  _get(managementState(state), 'selectedPurchaseOrder');
export const getShowDeleteModal = state => _get(managementState(state), 'showDeleteModal');
export const getShowEditModal = state => _get(managementState(state), 'showEditModal');

export const getIsRemoving = state => _get(managementState(state), 'isRemoving');
export const getIsUpdating = state => _get(managementState(state), 'isUpdating');

// settings
const settingsState = state => _get(purchaseOrdersState(state), 'settings');
export const getSettingsColumnsOrder = state => _get(settingsState(state), 'columnsOrder');
export const getSettingsColumns = state => _get(settingsState(state), 'columns');
export const getSettingsDays = state => _get(settingsState(state), 'days');
export const getSettingsItemsPerPage = state => _get(settingsState(state), 'itemsPerPage');
export const getSettingsSorting = state => _get(settingsState(state), 'sorting');
