import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const firebaseSignIn = createAction(ACTIONS.FIREBASE_SIGN_IN);
export const firebaseSignOff = createAction(ACTIONS.FIREBASE_SIGN_OFF);
export const firebaseSubscribe = createAction(
  ACTIONS.FIREBASE_SUBSCRIBE,
  'path',
  'dataReceivedActionCallback',
  'takeDataOnSubscription',
  'subscriptionActionCallback',
);
export const firebaseUnsubscribe = createAction(ACTIONS.FIREBASE_UNSUBSCRIBE, 'path');
export const firebaseWrite = createAction(ACTIONS.FIREBASE_WRITE, 'path', 'value');
export const firebaseRead = createAction(ACTIONS.FIREBASE_READ, 'path', 'dataActionCallback');
export const firebaseSetSession = createAction(ACTIONS.FIREBASE_SET_SESSION, 'session');
