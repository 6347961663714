import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadPurchaseOrders = createAction(ACTIONS.LOAD_PURCHASE_ORDERS);
export const loadPurchaseOrdersSuccess = createAction(
  ACTIONS.LOAD_PURCHASE_ORDERS_SUCCESS,
  'payload',
);
export const loadPurchaseOrdersError = createAction(
  ACTIONS.LOAD_PURCHASE_ORDERS_ERROR,
  'errorMessage',
  'error',
);
export const loadFeed = createAction(ACTIONS.LOAD_FEED);

export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const refreshFeed = createAction(ACTIONS.REFRESH_FEED);
export const setFilterOperand = createAction(ACTIONS.SET_FILTER_OPERAND, 'operand');
export const unsetFilterOperand = createAction(
  ACTIONS.UNSET_FILTER_OPERAND,
  'groupIndex',
  'filterIndex',
);
export const clearFilters = createAction(ACTIONS.CLEAR_FILTERS);
export const setFilters = createAction(ACTIONS.SET_FILTERS, 'filters');
export const arrangeFilters = createAction(ACTIONS.ARRANGE_FILTERS, 'destination', 'source');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(
  ACTIONS.EXPORT_TO_FILE_ERROR,
  'errorMessage',
  'error',
);

export const downloadLine = createAction(ACTIONS.DOWNLOAD_LINE, 'item');
export const downloadLineSuccess = createAction(ACTIONS.DOWNLOAD_LINE_SUCCESS, 'payload');
export const downloadLineError = createAction(ACTIONS.DOWNLOAD_LINE_ERROR, 'errorMessage', 'error');

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnsOrder = createAction(ACTIONS.SET_COLUMNS_ORDER, 'columnsOrder');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');
export const setItemsPerPage = createAction(ACTIONS.SET_ITEMS_PER_PAGE, 'itemsPerPage');
export const sortFeed = createAction(ACTIONS.SORT_FEED, 'columnId', 'direction');
export const goToPage = createAction(ACTIONS.GO_TO_PAGE, 'lastPage');

export const editPurchaseOrder = createAction(ACTIONS.EDIT_PURCHASE_ORDER, 'selectedPurchaseOrder');
export const cancelEditPurchaseOrder = createAction(ACTIONS.CANCEL_EDIT_PURCHASE_ORDER);

export const deletePurchaseOrder = createAction(
  ACTIONS.DELETE_PURCHASE_ORDER,
  'selectedPurchaseOrder',
);
export const cancelDeletePurchaseOrder = createAction(ACTIONS.CANCEL_DELETE_PURCHASE_ORDER);

export const removePurchaseOrder = createAction(ACTIONS.REMOVE_PURCHASE_ORDER);
export const removePurchaseOrderSuccess = createAction(
  ACTIONS.REMOVE_PURCHASE_ORDER_SUCCESS,
  'payload',
);
export const removePurchaseOrderError = createAction(
  ACTIONS.REMOVE_PURCHASE_ORDER_ERROR,
  'errorMessage',
  'error',
);

export const updatePurchaseOrder = createAction(
  ACTIONS.UPDATE_PURCHASE_ORDER,
  'purchaseOrderValues',
);
export const updatePurchaseOrderSuccess = createAction(
  ACTIONS.UPDATE_PURCHASE_ORDER_SUCCESS,
  'payload',
);
export const updatePurchaseOrderError = createAction(
  ACTIONS.UPDATE_PURCHASE_ORDER_ERROR,
  'errorMessage',
  'error',
);
