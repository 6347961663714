import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getInformation, updateInformation } from 'services/company/actions';
import { errorToasterSaga, handleAuthorizationErrorSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';
import {
  loadCompanyDataSuccess,
  loadCompanyDataError,
  updateBusinessInfoSuccess,
  updateBusinessInfoError,
  updatePickTicketInfoSuccess,
  updatePickTicketInfoError,
  setAuthorizationError,
} from './actions';

function* loadCompanyDataWatcher() {
  yield put(getInformation(loadCompanyDataSuccess, loadCompanyDataError));
}

function* loadCompanyDataErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* updateBusinessInfoWatcher({ formValues }) {
  yield put(updateInformation(formValues, updateBusinessInfoSuccess, updateBusinessInfoError));
}

function* updatePickTicketInfoWatcher({ formValues }) {
  yield put(updateInformation(formValues, updatePickTicketInfoSuccess, updatePickTicketInfoError));
}

function* updateBusinessLogoWatcher({ fileData }) {
  yield put(
    updateInformation({ fileData }, updatePickTicketInfoSuccess, updatePickTicketInfoError),
  );
}

export default function* businessSagas() {
  yield all([
    takeEvery(
      [
        ACTIONS.LOAD_COMPANY_DATA,
        ACTIONS.UPDATE_BUSINESS_INFO_SUCCESS,
        ACTIONS.UPDATE_PICK_TICKET_INFO_SUCCESS,
      ],
      loadCompanyDataWatcher,
    ),
  ]);
  yield all([takeEvery(ACTIONS.LOAD_COMPANY_DATA_ERROR, loadCompanyDataErrorWatcher)]);
  yield all([takeEvery(ACTIONS.UPDATE_BUSINESS_INFO, updateBusinessInfoWatcher)]);
  yield all([takeEvery(ACTIONS.UPDATE_BUSINESS_INFO_ERROR, errorToasterSaga)]);
  yield all([takeEvery(ACTIONS.UPDATE_PICK_TICKET_INFO, updatePickTicketInfoWatcher)]);
  yield all([takeEvery(ACTIONS.UPDATE_PICK_TICKET_INFO_ERROR, errorToasterSaga)]);
  yield all([takeEvery(ACTIONS.UPDATE_BUSINESS_LOGO, updateBusinessLogoWatcher)]);
  yield all([takeEvery(ACTIONS.UPDATE_BUSINESS_LOGO_ERROR, errorToasterSaga)]);
}
