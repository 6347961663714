import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import ROUTES from 'constants/routes';
import { setSession, clearSession } from 'app/session/actions';
import { getSessionUserName } from 'app/session/selectors';
import { fetchAuthTest, setLogin, setLogout } from 'services/auth/actions';
import { AUTH_ERRORS, AUTH_FLAGS } from 'services/auth/constants';
import { firebaseSignOff } from 'services/firebase/actions';
import { setChangeRequired, setOldPassword } from '../NewPassword/actions';
import { ACTIONS, LOGIN_ERRORS } from './constants';
import {
  signInSuccess,
  signInError,
  signOffSuccess,
  signOffError,
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  resetState,
} from './actions';

function* exitContainerWatcher() {
  yield put(resetState());
}

function* signInWatcher({ data }) {
  const { username, password } = data;
  yield put(setLogin(username, password, loginSuccess, loginError));
}

function* loginSuccessWatcher({ payload }) {
  const {
    username, success, error, type, tempPass, message,
  } = payload;

  if (success) {
    yield put(fetchAuthTest(username, 'login', signInSuccess, signInError));
    return;
  }

  if (error) {
    if (type === AUTH_FLAGS.PASSWORD_CHANGE_REQUIRED) {
      yield put(setOldPassword(tempPass));
      yield put(setChangeRequired(true));
      yield put(push(ROUTES.LOGIN.NEW_PASSWORD({ change_required: true })));
      return;
    }
    yield put(signInError(message));
  }
}

function* signInSuccessWatcher({ payload }) {
  yield put(setSession(payload));
  yield put(push(ROUTES.DASHBOARD));
}

function* signInErrorWatcher({ errorMessage }) {
  if (
    errorMessage !== AUTH_ERRORS.NOT_AUTHORIZED &&
    errorMessage !== LOGIN_ERRORS.INVALID_USERNAME_PASSWORD
  ) {
    yield call([toast, toast.error], LOGIN_ERRORS.SIGN_IN);
  }
}

function* signOffWatcher() {
  yield put(setLogout(logoutSuccess, logoutError));
}

function* logoutSuccessWatcher() {
  const sessionUserName = yield select(getSessionUserName);
  yield put(fetchAuthTest(sessionUserName, 'logout', signOffError, signOffSuccess));
}

function* signOffSuccessWatcher() {
  yield put(clearSession());
  yield put(firebaseSignOff());
}

function* signOffErrorWatcher() {
  yield call([toast, toast.error], LOGIN_ERRORS.SIGN_OFF);
}

export default function* loginSagas() {
  yield all([
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    takeEvery(ACTIONS.SIGN_IN, signInWatcher),
    takeEvery(ACTIONS.SIGN_IN_SUCCESS, signInSuccessWatcher),
    takeEvery(ACTIONS.SIGN_IN_ERROR, signInErrorWatcher),
    takeEvery(ACTIONS.SIGN_OFF, signOffWatcher),
    takeEvery(ACTIONS.SIGN_OFF_SUCCESS, signOffSuccessWatcher),
    takeEvery(ACTIONS.SIGN_OFF_ERROR, signOffErrorWatcher),
    takeEvery(ACTIONS.LOGIN_SUCCESS, loginSuccessWatcher),
    takeEvery(ACTIONS.LOGOUT_SUCCESS, logoutSuccessWatcher),
    takeEvery(ACTIONS.LOGOUT_ERROR, signOffErrorWatcher),
  ]);
}
