import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { fetchOrderFeedQuery } from 'services/orders/actions';
import { fetchMergeShipment } from 'services/shipments/actions';
import { ACTIONS } from './constants';
import {
  scanSalesOrderSuccess,
  scanSalesOrderError,
  setScannedItems,
  setSalesOrderNumber,
  confirmShipmentSuccess,
  confirmShipmentError,
  scanSalesOrder,
} from './actions';
import { getLinesConfirmedGuidOrderLine } from './helpers';
import { getScannedItems, getSalesOrderNumber, getTrackingNumber } from './selectors';

function* scanSalesOrderWatcher({ salesOrderNumber }) {
  const params = {
    sales_order_number: salesOrderNumber,
  };

  yield put(fetchOrderFeedQuery(params, scanSalesOrderSuccess, scanSalesOrderError));
  yield put(setSalesOrderNumber(salesOrderNumber));
}

function* scanSalesOrderErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* scanSalesOrderSuccessWatcher({ payload }) {
  const { order_lines: salesOrderItems } = payload;

  const scannedItems = _reduce(
    salesOrderItems,
    (result, item) => {
      const newUPC = item.upc;
      const updatedResult = {
        ...result,
        [newUPC]: {
          count: 0,
          total: item.qty_ordered,
          guid_order_line: item.guid_order_line,
          upc: item.upc,
          sku: item.sku,
          left: item.left_to_ship,
        },
      };
      return updatedResult;
    },
    {},
  );

  yield put(setScannedItems(scannedItems));
}

function* confirmShipmentWatcher() {
  const trackingNumber = yield select(getTrackingNumber);
  const scannedItems = yield select(getScannedItems);
  const confirmedGuidList = getLinesConfirmedGuidOrderLine(scannedItems);

  if (_isEmpty(trackingNumber)) {
    return;
  }

  yield put(
    fetchMergeShipment(
      trackingNumber,
      confirmedGuidList,
      confirmShipmentSuccess,
      confirmShipmentError,
    ),
  );
}

function* confirmShipmentSuccessWatcher() {
  yield call([toast, toast.success], 'Shipment successfully confirmed');
  const salesOrderNumber = yield select(getSalesOrderNumber);
  yield put(scanSalesOrder(salesOrderNumber));
}

function* confirmShipmentErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* shippingSagas() {
  yield all([
    takeEvery(ACTIONS.SCAN_SALES_ORDER, scanSalesOrderWatcher),
    takeEvery(ACTIONS.SCAN_SALES_ORDER_ERROR, scanSalesOrderErrorWatcher),
    takeEvery(ACTIONS.SCAN_SALES_ORDER_SUCCESS, scanSalesOrderSuccessWatcher),
    takeEvery(ACTIONS.CONFIRM_SHIPMENT, confirmShipmentWatcher),
    takeEvery(ACTIONS.CONFIRM_SHIPMENT_SUCCESS, confirmShipmentSuccessWatcher),
    takeEvery(ACTIONS.CONFIRM_SHIPMENT_ERROR, confirmShipmentErrorWatcher),
  ]);
}
