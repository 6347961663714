import _get from 'lodash/get';
import { orderManagementState } from 'features/OrderManagement/selectors';

const shippingState = state => _get(orderManagementState(state), 'shipping');
export const getSalesOrderItems = state => _get(shippingState(state), 'salesOrderItems');
export const getIsScanningSalesOrder = state => _get(shippingState(state), 'isScanningSalesOrder');
export const getIsConfirmingShipment = state => _get(shippingState(state), 'isConfirmingShipment');
export const getScannedItems = state => _get(shippingState(state), 'scannedItems');
export const getSalesOrderNumber = state => _get(shippingState(state), 'salesOrderNumber');
export const getTrackingNumber = state => _get(shippingState(state), 'trackingNumber');
