import ROUTES from 'constants/routes';
import { RiSwapBoxLine, RiTableAltLine } from 'react-icons/ri';
import { GiCardboardBox } from 'react-icons/gi';

export const ACTIONS = {
  INIT_CONTAINER: 'INVENTORY_MANAGEMENT__INIT_CONTAINER',
  EXIT_CONTAINER: 'INVENTORY_MANAGEMENT__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'INVENTORY_MANAGEMENT__RESET_FEATURE_REDUCERS',
};

export const INVENTORY_MANAGEMENT_ERRORS = {
  CHANGING_ITEMS: 'Something went wrong updating items quantities. Please try again.',
  DELETING_ITEMS: 'Something went wrong deleting the items. Please try again.',
  SCANNING_UPC: 'Something went wrong scanning UPC. Please try again.',
  SCANNING_ZONE_NAME: 'Something went wrong scanning Zone Name. Please try again.',
  LOCKING_ZONE: 'Something went wrong locking the zone. Please try again later.',
  UNLOCKING_ZONE: 'Something went wrong unlocking the zone. Please try again later.',
  RENAMING_ZONE: 'Something went wrong renaming the zone. Please try again later.',
  MOVING_ITEMS: 'Something went wrong moving the items. Please try again later.',
  SEARCHING_ITEM: 'Something went wrong searching for items. Please try again later.',
  LOADING_ZONE_SEARCH_ITEM:
    'Something went wrong loading the zone for the selected item. Please try again later.',
  INVALID_ZONE_NAME: 'Invalid Zone Name Format',
  ZONE_ALREADY_SCANNED: 'Zone already scanned',
  UPC_NOT_FOUND_IN_ZONE: zoneName => `UPC not found in ${zoneName || 'this Zone'}`,
  NO_SAME_ZONE_MOVE_ITEMS: "You can't move items to the same Zone",
  LOADING_SHIPPING_ZONE: 'Something went wrong loading the shipping zone. Please try again later.',
};

export const INVENTORY_MANAGEMENT_SUCCESS = {
  CHANGING_ITEMS: 'Zone items updated!',
  DELETING_ITEMS: 'Zone items deleted!',
  RENAMING_ZONE: 'Zone renamed!',
  MOVING_ITEMS: 'Zone items moved!',
  MOVING_ITEMS_TO_SHIPPING_ZONE: 'Items moved to shipping zone',
  MOVING_ITEMS_FROM_SHIPPING_ZONE: 'Items moved back to zone',
};

export const INVENTORY_MANAGEMENT_SECTIONS = {
  INVENTORY_VIEW: {
    id: 'inventory_view',
    title: 'Inventory View',
    route: ROUTES.INVENTORY.VIEW,
    icon: RiTableAltLine,
  },
  INVENTORY_MOVE: {
    id: 'inventory_move',
    title: 'Inventory Move',
    route: ROUTES.INVENTORY.MOVE,
    icon: RiSwapBoxLine,
  },
  PICKING: {
    id: 'picking',
    title: 'Picking',
    route: ROUTES.INVENTORY.PICKING,
    icon: GiCardboardBox,
  },
};
