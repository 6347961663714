import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setMoveItems } from 'services/inventory/actions';
import { MOVE_ITEMS_REF } from 'services/inventory/constants';
import {
  INVENTORY_MANAGEMENT_ERRORS,
  INVENTORY_MANAGEMENT_SUCCESS,
} from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import { scanZoneName, renameZoneSuccess, renameZoneError, reloadZone } from '../actions';
import { getZoneItems, getZoneName } from '../selectors';

function* renameZoneWatcher({ zoneName }) {
  const zoneItems = yield select(getZoneItems);
  const currentZoneName = yield select(getZoneName);

  yield put(
    setMoveItems(
      zoneName,
      currentZoneName,
      zoneItems,
      renameZoneSuccess,
      renameZoneError,
      MOVE_ITEMS_REF.RENAMING_ZONE,
    ),
  );
}

function* renameZoneSuccessWatcher({ zoneName }) {
  yield call([toast, toast.success], INVENTORY_MANAGEMENT_SUCCESS.RENAMING_ZONE);
  yield put(scanZoneName(zoneName));
}

function* renameZoneErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.RENAMING_ZONE);
}

export default function* renameZoneSagas() {
  yield all([
    takeEvery(ACTIONS.RENAME_ZONE, renameZoneWatcher),
    takeEvery(ACTIONS.RENAME_ZONE_SUCCESS, renameZoneSuccessWatcher),
    takeEvery(ACTIONS.RENAME_ZONE_ERROR, renameZoneErrorWatcher),
  ]);
}
