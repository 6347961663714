import { all, takeEvery, put, call, takeLatest, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import {
  fetchRoles,
  fetchUsers,
  setAddUser,
  setDeleteUser,
  setEditUser,
  setResendInvite,
  fetchCheckUsernameAvailability,
} from 'services/userManagement/actions';
import { handleAuthorizationErrorSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';
import {
  addUserError,
  addUserSuccess,
  deleteUserError,
  deleteUserSuccess,
  editUserError,
  editUserSuccess,
  initUsersManagementContainerError,
  initUsersManagementContainerSuccess,
  loadAccountUsers,
  loadAccountUsersError,
  loadAccountUsersSuccess,
  resendInviteEmailError,
  resendInviteEmailSuccess,
  setAuthorizationError,
  setDeleteSelectedUser,
  setShowAddUserModal,
  setShowDeleteUserModal,
  setShowEditUserModal,
  setRoles,
  checkUsernameAvailabilitySuccess,
  checkUsernameAvailabilityError,
  setAvailabilityError,
} from './actions';

function* initContainerWatcher() {
  yield put(fetchRoles(initUsersManagementContainerSuccess, initUsersManagementContainerError));
}

function* initContainerSuccessWatcher({ payload }) {
  yield put(setRoles(payload.roles));
  yield put(loadAccountUsers());
}

function* initContainerErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* loadAccountUsersWatcher() {
  yield put(fetchUsers(loadAccountUsersSuccess, loadAccountUsersError));
}

function* loadAccountUsersErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* checkUsernameAvailabilityWatcher({ username }) {
  yield delay(1000);
  yield put(
    fetchCheckUsernameAvailability(
      username,
      checkUsernameAvailabilitySuccess,
      checkUsernameAvailabilityError,
    ),
  );
}

function* checkUsernameAvailabilityErrorWatcher({ errorMessage }) {
  const errors = _get(JSON.parse(errorMessage), 'errors.username');
  const msg = errors.join('\n');
  yield put(setAvailabilityError(msg));
}

function* addUserWatcher({ userData }) {
  yield put(setAddUser(userData, addUserSuccess, addUserError));
}

function* addUserSuccessWatcher() {
  yield call([toast, toast.success], 'User successfully added.');
  yield put(setShowAddUserModal(false));
  yield put(loadAccountUsers());
}

function* addUserErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* deleteUserWatcher({ username }) {
  yield put(setDeleteUser(username, deleteUserSuccess, deleteUserError));
}

function* deleteUserSuccessWatcher() {
  yield call([toast, toast.success], 'User successfully deleted.');
  yield put(setShowDeleteUserModal(false));
  yield put(loadAccountUsers());
  yield put(setDeleteSelectedUser(null));
}

function* deleteUserErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* editUserWatcher({ userData }) {
  const { username } = userData;
  const userDataObj = _omit(userData, ['username']);
  yield put(setEditUser(username, userDataObj, editUserSuccess, editUserError));
}

function* editUserSuccessWatcher() {
  yield call([toast, toast.success], 'User successfully updated.');
  yield put(setShowEditUserModal(false));
  yield put(loadAccountUsers());
}

function* editUserErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* resendInviteEmailWatcher({ username }) {
  yield put(setResendInvite(username, resendInviteEmailSuccess, resendInviteEmailError));
}

function* resendInviteEmailSuccessWatcher() {
  yield call([toast, toast.success], 'Invite email resent to the user.');
}

function* resendInviteEmailErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* usersManagementSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_SUCCESS, initContainerSuccessWatcher),
    takeEvery(ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_ERROR, initContainerErrorWatcher),
    takeEvery(ACTIONS.LOAD_ACCOUNT_USERS, loadAccountUsersWatcher),
    takeEvery(ACTIONS.LOAD_ACCOUNT_USERS_ERROR, loadAccountUsersErrorWatcher),
    takeLatest(ACTIONS.CHECK_USERNAME_AVAILABILITY, checkUsernameAvailabilityWatcher),
    takeEvery(ACTIONS.CHECK_USERNAME_AVAILABILITY_ERROR, checkUsernameAvailabilityErrorWatcher),
    takeEvery(ACTIONS.ADD_USER, addUserWatcher),
    takeEvery(ACTIONS.ADD_USER_SUCCESS, addUserSuccessWatcher),
    takeEvery(ACTIONS.ADD_USER_ERROR, addUserErrorWatcher),
    takeEvery(ACTIONS.DELETE_USER, deleteUserWatcher),
    takeEvery(ACTIONS.DELETE_USER_SUCCESS, deleteUserSuccessWatcher),
    takeEvery(ACTIONS.DELETE_USER_ERROR, deleteUserErrorWatcher),
    takeEvery(ACTIONS.EDIT_USER, editUserWatcher),
    takeEvery(ACTIONS.EDIT_USER_SUCCESS, editUserSuccessWatcher),
    takeEvery(ACTIONS.EDIT_USER_ERROR, editUserErrorWatcher),
    takeEvery(ACTIONS.RESEND_INVITE_EMAIL, resendInviteEmailWatcher),
    takeEvery(ACTIONS.RESEND_INVITE_EMAIL_SUCCESS, resendInviteEmailSuccessWatcher),
    takeEvery(ACTIONS.RESEND_INVITE_EMAIL_ERROR, resendInviteEmailErrorWatcher),
  ]);
}
