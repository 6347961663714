import { all, takeEvery } from 'redux-saga/effects';
import { purchaseOrdersApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const setImportFileWatcher = action => serviceSaga(purchaseOrdersApiSelector, 'importFile', action);
const setValidateFileWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'validateFile', action);
const fetchImportTemplateWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'getTemplate', action);
const fetchPurchaseOrdersWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'getPurchaseOrders', action);
const fetchPurchaseOrdersCSVWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'purchaseOrdersToCSV', action);
const setRemovePurchaseOrderWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'removePurchaseOrder', action);
const setUpdatePurchaseOrderWatcher = action =>
  serviceSaga(purchaseOrdersApiSelector, 'updatePurchaseOrder', action);

export default function* purchaseOrderManagementSagas() {
  yield all([
    takeEvery(ACTIONS.SET_IMPORT_FILE, authRequired, setImportFileWatcher),
    takeEvery(ACTIONS.SET_VALIDATE_FILE, authRequired, setValidateFileWatcher),
    takeEvery(ACTIONS.FETCH_IMPORT_TEMPLATE, authRequired, fetchImportTemplateWatcher),
    takeEvery(ACTIONS.FETCH_PURCHASE_ORDERS, authRequired, fetchPurchaseOrdersWatcher),
    takeEvery(ACTIONS.FETCH_PURCHASE_ORDERS_CSV, authRequired, fetchPurchaseOrdersCSVWatcher),
    takeEvery(ACTIONS.SET_REMOVE_PURCHASE_ORDER, authRequired, setRemovePurchaseOrderWatcher),
    takeEvery(ACTIONS.SET_UPDATE_PURCHASE_ORDER, authRequired, setUpdatePurchaseOrderWatcher),
  ]);
}
