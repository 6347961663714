import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadProducts = createAction(ACTIONS.LOAD_PRODUCTS);
export const loadProductsSuccess = createAction(ACTIONS.LOAD_PRODUCTS_SUCCESS, 'payload');
export const loadProductsError = createAction(ACTIONS.LOAD_PRODUCTS_ERROR, 'errorMessage', 'error');
export const loadFeed = createAction(ACTIONS.LOAD_FEED);

export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const setShowDownloadModal = createAction(
  ACTIONS.SET_SHOW_DOWNLOAD_MODAL,
  'showDownloadModal',
);
export const refreshFeed = createAction(ACTIONS.REFRESH_FEED);
export const setFilterOperand = createAction(ACTIONS.SET_FILTER_OPERAND, 'operand');
export const unsetFilterOperand = createAction(
  ACTIONS.UNSET_FILTER_OPERAND,
  'groupIndex',
  'filterIndex',
);
export const clearFilters = createAction(ACTIONS.CLEAR_FILTERS);
export const setFilters = createAction(ACTIONS.SET_FILTERS, 'filters');
export const arrangeFilters = createAction(ACTIONS.ARRANGE_FILTERS, 'destination', 'source');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(
  ACTIONS.EXPORT_TO_FILE_ERROR,
  'errorMessage',
  'error',
);

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnsOrder = createAction(ACTIONS.SET_COLUMNS_ORDER, 'columnsOrder');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');
export const setItemsPerPage = createAction(ACTIONS.SET_ITEMS_PER_PAGE, 'itemsPerPage');
export const sortFeed = createAction(ACTIONS.SORT_FEED, 'columnId', 'direction');
export const goToPage = createAction(ACTIONS.GO_TO_PAGE, 'lastPage');

export const addProduct = createAction(ACTIONS.ADD_PRODUCT);
export const cancelAddProduct = createAction(ACTIONS.CANCEL_ADD_PRODUCT);
export const editProduct = createAction(ACTIONS.EDIT_PRODUCT, 'selectedProduct');
export const cancelEditProduct = createAction(ACTIONS.CANCEL_EDIT_PRODUCT);
export const deleteProduct = createAction(ACTIONS.DELETE_PRODUCT, 'selectedProduct');
export const cancelDeleteProduct = createAction(ACTIONS.CANCEL_DELETE_PRODUCT);

export const removeProduct = createAction(ACTIONS.REMOVE_PRODUCT);
export const removeProductSuccess = createAction(ACTIONS.REMOVE_PRODUCT_SUCCESS, 'payload');
export const removeProductError = createAction(
  ACTIONS.REMOVE_PRODUCT_ERROR,
  'errorMessage',
  'error',
);

export const createProduct = createAction(ACTIONS.CREATE_PRODUCT, 'productValues');
export const createProductSuccess = createAction(ACTIONS.CREATE_PRODUCT_SUCCESS, 'payload');
export const createProductError = createAction(
  ACTIONS.CREATE_PRODUCT_ERROR,
  'errorMessage',
  'error',
);

export const updateProduct = createAction(ACTIONS.UPDATE_PRODUCT, 'productValues');
export const updateProductSuccess = createAction(ACTIONS.UPDATE_PRODUCT_SUCCESS, 'payload');
export const updateProductError = createAction(
  ACTIONS.UPDATE_PRODUCT_ERROR,
  'errorMessage',
  'error',
);

export const fetchDownloadLogs = createAction(ACTIONS.FETCH_DOWNLOAD_LOGS);
export const fetchDownloadLogsSuccess = createAction(
  ACTIONS.FETCH_DOWNLOAD_LOGS_SUCCESS,
  'payload',
);
export const fetchDownloadLogsError = createAction(
  ACTIONS.FETCH_DOWNLOAD_LOGS_ERROR,
  'errorMessage',
  'error',
);

export const requestDownloadProducts = createAction(ACTIONS.REQUEST_DOWNLOAD_PRODUCTS);
export const requestDownloadProductsSuccess = createAction(
  ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_SUCCESS,
  'payload',
);
export const requestDownloadProductsError = createAction(
  ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_ERROR,
  'errorMessage',
  'error',
);

export const downloadProductsFile = createAction(ACTIONS.DOWNLOAD_PRODUCTS_FILE, 'fileKey');
export const downloadProductsFileSuccess = createAction(
  ACTIONS.DOWNLOAD_PRODUCTS_FILE_SUCCESS,
  'payload',
);
export const downloadProductsFileError = createAction(
  ACTIONS.DOWNLOAD_PRODUCTS_FILE_ERROR,
  'errorMessage',
  'error',
);

export const cancelDownloadRequest = createAction(ACTIONS.CANCEL_DOWNLOAD_REQUEST, 'requestId');
export const cancelDownloadRequestSuccess = createAction(
  ACTIONS.CANCEL_DOWNLOAD_REQUEST_SUCCESS,
  'payload',
);
export const cancelDownloadRequestError = createAction(
  ACTIONS.CANCEL_DOWNLOAD_REQUEST_ERROR,
  'errorMessage',
  'error',
);
