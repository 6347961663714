import { createReducer } from 'redux-create-reducer';
import _isEmpty from 'lodash/isEmpty';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  company: {},
  initialLoading: false,
  authorizationError: null,
};

const updateCompanyData = (state, { payload }) => ({
  ...state,
  company: payload,
  initialLoading: false,
});

const setInitialLoading = state => ({
  ...state,
  initialLoading: !!_isEmpty(state.company),
});

const updateAuthorizationError = (state, { authorizationError }) => ({
  ...state,
  authorizationError,
  initialLoading: false,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.LOAD_COMPANY_DATA]: setInitialLoading,
  [ACTIONS.LOAD_COMPANY_DATA_SUCCESS]: updateCompanyData,
  [ACTIONS.SET_AUTHORIZATION_ERROR]: updateAuthorizationError,
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
