import _isNull from 'lodash/isNull';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import { DEV_MODE } from 'app/config';
import { getSessionState } from 'app/session/selectors';
import {
  getFromLocalStorage,
  logEstimateStorageUsagePercent,
  saveToLocalStorage,
} from 'utils/localStorage';
import { APP_LOCAL_STORAGE_KEY, UNIQ_LOCAL_STORAGE_KEYS } from './constants';

const persistedState = getFromLocalStorage(APP_LOCAL_STORAGE_KEY);

export const updatePersistedState = state =>
  saveToLocalStorage(
    {
      session: getSessionState(state),
    },
    APP_LOCAL_STORAGE_KEY,
  );

export const formatUserLocalStorageKey = (username = null, uniqKey = '') => {
  if (_isNull(username)) {
    return undefined;
  }
  return _compact([APP_LOCAL_STORAGE_KEY, 'usr', username, uniqKey]).join(':');
};

export const getLocalStorageSessionUsername = () =>
  _get(getFromLocalStorage(APP_LOCAL_STORAGE_KEY), 'session.username');

if (DEV_MODE) {
  logEstimateStorageUsagePercent(navigator);
}

export default persistedState;

export { APP_LOCAL_STORAGE_KEY, UNIQ_LOCAL_STORAGE_KEYS };
