import { css } from 'styled-components';
import { MINE_SHAFT, HAVELOCK_BLUE } from 'constants/colors';
import {
  MONTSERRAT,
  MONTSERRAT_BOLD,
} from './fonts';

const typography = css`
  body {
    font-family: ${MONTSERRAT};
    color: ${MINE_SHAFT};
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0;
    font-weight: normal;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p {
    @-moz-document url-prefix() {
      font-weight: lighter;
    }
  }

  h1 {
    font-family: ${MONTSERRAT_BOLD};
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  strong {
    font-family: ${MONTSERRAT};
    font-weight: 600;
  }

  h2, h3, h4, h5, h6 {
    font-family: ${MONTSERRAT};
    font-weight: 600;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: -0.01em;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
  }

  h4, h5, h6, span, button, label {
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0.02rem;
  }

  h4 {
    font-size: 0.9375rem;
  }

  h5 {
    font-size: 0.8125rem;
  }

  h6 {
    font-size: 0.6875rem;
  }

  p {
    letter-spacing: 0.02rem;
  }

  span {
    line-height: 0.9rem;
  }

  button {
    text-align: center;
    font-family: ${MONTSERRAT};
  }

  a {
    color: ${HAVELOCK_BLUE};
    font-family: ${MONTSERRAT};
  }

  input {
    font-size: 1rem;
    line-height: 1.25rem;
    background: inherit;
    border: none;
    &[type=date] {
      font-family: system-ui;
    }
    &:focus {
      outline: none;
    }
  }

  textarea {
    font-size: 1rem;
  }
`;

export default typography;
