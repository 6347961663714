import { all, put, takeLatest, delay } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import { fetchSalesReportSearch } from 'services/feeds/actions';
import { ACTIONS } from '../constants';
import { searchBrandSuccess, searchVendorSuccess } from '../actions';

function* searchAutocompleteWatcher({ fieldId, value }) {
  if (_isEmpty(value)) {
    return;
  }
  yield delay(500);
  if (fieldId === 'brand') {
    yield put(fetchSalesReportSearch({ brand: value }, searchBrandSuccess));
    return;
  }
  if (fieldId === 'vendor') {
    yield put(fetchSalesReportSearch({ vendor: value }, searchVendorSuccess));
  }
}

export default function* searchSagas() {
  yield all([takeLatest(ACTIONS.SEARCH_AUTOCOMPLETE, searchAutocompleteWatcher)]);
}
