export const formatScannedItemRefId = item => `${item.upc}-${item.sales_order_number}`;

export const formatScannedItem = item => ({
  count: Number(item.qty_prev_scanned),
  total: Number(item.quantity_purchased),
  qty_prev_scanned: Number(item.qty_prev_scanned),
  order_line_id: item.order_line_id,
  upc: item.upc,
  sku: item.sku,
  order_id: item.order_id,
  sales_order_number: item.sales_order_number,
  purchase_order_number: item.purchase_number,
  item_shipment_volume: item.item_shipment_volume || 0,
  item_shipment_cost: item.item_shipment_cost || 0,
});

export const shipmentHasChanges = state => {
  const { initialShipmentSnapshot, shipmentVolumeUnit, shipmentTotalCost } = state;
  return (
    initialShipmentSnapshot.shipmentVolumeUnit !== shipmentVolumeUnit ||
    initialShipmentSnapshot.shipmentTotalCost !== shipmentTotalCost
  );
};
