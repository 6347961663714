import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchUserProfile = createAction(
  ACTIONS.FETCH_USER,
  'username',
  'successActionCallback',
  'errorActionCallback',
);
export const setChangePassword = createAction(
  ACTIONS.SET_CHANGE_PASSWORD,
  'currentPassword',
  'newPassword',
  'successActionCallback',
  'errorActionCallback',
);
export const setUpdateUserProfile = createAction(
  ACTIONS.SET_UPDATE_USER_PROFILE,
  'username',
  'profileInfo',
  'successActionCallback',
  'errorActionCallback',
);
