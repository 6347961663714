import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { setFeatureName } from 'containers/Dashboard/actions';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';
import purchaseOrdersSagas from './PurchaseOrders/sagas';
import purchaseOrderCreatorSagas from './PurchaseOrderCreator/sagas';
import receivingSagas from './Receiving/sagas';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.PURCHASE_ORDER_MANAGEMENT.title));
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* purchaseOrderManagementSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(purchaseOrdersSagas, ...args),
    fork(purchaseOrderCreatorSagas, ...args),
    fork(receivingSagas, ...args),
  ]);
}
