import { MdStore, MdAssignmentReturn, MdPeople, MdHome, MdSyncProblem } from 'react-icons/md';

export const ACTIONS = {
  RESET_FEATURE_REDUCERS: 'ADD_ORDER__RESET_FEATURE_REDUCERS',

  // order type
  SET_SALES_CHANNEL: 'ADD_ORDER__SET_SALES_CHANNEL',

  // shipping address
  SET_SHIPPING_ADDRESS_FIELD_VALUE: 'ADD_ORDER__SET_SHIPPING_ADDRESS_FIELD_VALUE',
  SEARCH_ZIP_CODE: 'ADD_ORDER__SEARCH_ZIP_CODE',
  SEARCH_ZIP_CODE_SUCCESS: 'ADD_ORDER__SEARCH_ZIP_CODE_SUCCESS',
  SET_SHIPPING_INFO: 'ADD_ORDER__SET_SHIPPING_INFO',

  // add items
  SCAN_ITEM: 'ADD_ORDER__SCAN_ITEM',
  SCAN_ITEM_SUCCESS: 'ADD_ORDER__SCAN_ITEM_SUCCESS',
  SCAN_ITEM_ERROR: 'ADD_ORDER__SCAN_ITEM_ERROR',
  SET_ORDER_ITEMS: 'ADD_ORDER__SET_ORDER_ITEMS',
  SET_ITEM: 'ADD_ORDER__SET_ITEM',
  REMOVE_ITEM: 'ADD_ORDER__REMOVE_ITEM',
  SET_ITEM_QTY: 'ADD_ORDER__SET_ITEM_QTY',
  SET_ITEM_DISCOUNT: 'ADD_ORDER__SET_ITEM_DISCOUNT',
  SET_ITEM_SUBTOTAL: 'ADD_ORDER__SET_ITEM_SUBTOTAL',
  SET_ITEM_TAX_PERCENTAGE: 'ADD_ORDER__SET_ITEM_TAX_PERCENTAGE',
  SET_ALL_ITEMS_TAX_PERCENTAGE: 'ADD_ORDER__SET_ALL_ITEMS_TAX_PERCENTAGE',

  // complete order
  SET_ORDER_ID: 'ADD_ORDER__SET_ORDER_ID',
  SET_PURCHASE_DATE: 'ADD_ORDER__SET_PURCHASE_DATE',
  SET_REFERRING_ORDER: 'ADD_ORDER__SET_REFERRING_ORDER',
  SET_ORDER_NOTES: 'ADD_ORDER__SET_ORDER_NOTES',
  CONFIRM_ORDER: 'ADD_ORDER__CONFIRM_ORDER',
  CONFIRM_ORDER_SUCCESS: 'ADD_ORDER__CONFIRM_ORDER_SUCCESS',
  CONFIRM_ORDER_ERROR: 'ADD_ORDER__CONFIRM_ORDER_ERROR',
  SET_SHIPPING_ITEM: 'ADD_ORDER__SET_SHIPPING_ITEM',
  SET_SHIPPING_ITEM_SUBTOTAL: 'ADD_ORDER__SET_SHIPPING_ITEM_SUBTOTAL',
  REMOVE_SHIPPING_ITEM: 'ADD_ORDER__REMOVE_SHIPPING_ITEM',
  SET_SHOW_CONFIRM_PAYMENT_MODAL: 'ADD_ORDER__SET_SHOW_CONFIRM_PAYMENT_MODAL',
  SET_SHOW_CANCEL_ORDER_MODAL: 'ADD_ORDER__SET_SHOW_CANCEL_ORDER_MODAL',
  CONFIRM_PAYMENT: 'ADD_ORDER__CONFIRM_PAYMENT',
  CONFIRM_PAYMENT_SUCCESS: 'ADD_ORDER__CONFIRM_PAYMENT_SUCCESS',
  GENERATE_ORDER_ID: 'ADD_ORDER__GENERATE_ORDER_ID',
  CANCEL_ORDER: 'ADD_ORDER__CANCEL_ORDER',
  RESTART_ORDER: 'ADD_ORDER__RESTART_ORDER',
};

export const STEPS = [
  {
    id: 'order_type',
    label: 'Order Type',
  },
  {
    id: 'ship_address',
    label: 'Shipping Address',
  },
  {
    id: 'add_items',
    label: 'Add Items',
  },
  {
    id: 'complete_order',
    label: 'Complete Order',
  },
];

export const ORDER_TYPES = [
  {
    id: 'in-store',
    label: 'In Store',
    icon: MdStore,
    value: 'store',
    code: 'ST',
  },
  {
    id: 'return-replacement',
    label: 'Return Replacement',
    icon: MdAssignmentReturn,
    value: 'return',
    code: 'RT',
  },
  {
    id: 'sold-out-reorder',
    label: 'Sold Out / Reorder',
    icon: MdSyncProblem,
    value: 'reorder',
    code: 'SR',
  },
  {
    id: 'custom-employee',
    label: 'Custom / Employee',
    icon: MdPeople,
    value: 'custom',
    code: 'CO',
  },
  {
    id: 'in-house',
    label: 'In House',
    icon: MdHome,
    value: 'house',
    code: 'HO',
  },
];

export const PAYMENT_TYPES = {
  CASH: 'cash',
  CREDIT_CARD_EPAYMENT: 'credit-card-epayment',
  CHECK: 'check',
  ON_ACCOUNT: 'on-account',
  INTERNAL: 'internal',
};

export const PAYMENT_TYPES_OPTIONS = [
  {
    id: PAYMENT_TYPES.CASH,
    label: 'Cash',
    value: PAYMENT_TYPES.CASH,
    getLogText: () => 'Order payment type: Cash',
  },
  {
    id: PAYMENT_TYPES.CREDIT_CARD_EPAYMENT,
    label: 'Credit Card | Epayment',
    value: PAYMENT_TYPES.CREDIT_CARD_EPAYMENT,
    getLogText: transactionIds =>
      `Order payment type: Credit Card | Epayment. ${
        transactionIds.length > 1 ? 'Transactions' : 'Transaction'
      }: [${transactionIds.join(', ')}].`,
  },
  {
    id: PAYMENT_TYPES.CHECK,
    label: 'Check',
    value: PAYMENT_TYPES.CHECK,
    getLogText: () => 'Order payment type: Check.',
  },
  {
    id: PAYMENT_TYPES.ON_ACCOUNT,
    label: 'On Account',
    value: PAYMENT_TYPES.ON_ACCOUNT,
    getLogText: () => 'Order payment type: On Account.',
  },
  {
    id: PAYMENT_TYPES.INTERNAL,
    label: 'Internal',
    value: PAYMENT_TYPES.INTERNAL,
    getLogText: () => 'Order payment type: Internal.',
  },
];

export const SHIPPING_ADDRESS_FIELD = {
  NAME: 'ship_name',
  ADDRESS_1: 'ship_address_1',
  ADDRESS_2: 'ship_address_2',
  ADDRESS_3: 'ship_address_3',
  PHONE: 'ship_phone',
  COUNTRY: 'ship_country',
  CITY: 'ship_city',
  STATE: 'ship_state',
  ZIP_CODE: 'ship_postal_code',
};

export const ADD_ORDER_ERRORS = {
  ITEM_NOT_FOUND: 'Could not add item to the order. Item not found.',
};
