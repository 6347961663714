import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { accountApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

export function* fetchUserWatcher({ username, successActionCallback, errorActionCallback }) {
  const api = yield select(accountApiSelector);
  try {
    const response = yield call([api, api.getUser], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }
    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setChangePasswordWatcher({
  currentPassword,
  newPassword,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(accountApiSelector);
  try {
    const response = yield call([api, api.changePassword], currentPassword, newPassword);

    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* setUpdateUserProfileWatcher({
  username,
  profileInfo,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(accountApiSelector);
  try {
    const response = yield call([api, api.updateUserProfileInfo], username, profileInfo);

    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

export default function* accountSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_USER, authRequired, fetchUserWatcher),
    takeEvery(ACTIONS.SET_CHANGE_PASSWORD, authRequired, setChangePasswordWatcher),
    takeEvery(ACTIONS.SET_UPDATE_USER_PROFILE, authRequired, setUpdateUserProfileWatcher),
  ]);
}
