import _get from 'lodash/get';
import { productManagementState } from '../selectors';

export const productsState = state => _get(productManagementState(state), 'products');

// feeedReducers
const feedState = state => _get(productsState(state), 'feed');
export const getFeedData = state => _get(feedState(state), 'data');
export const getProducts = state => _get(getFeedData(state), 'products');
export const getFeedDataTotalRecords = state => _get(getFeedData(state), 'totalRecords');
export const getFeedDataPage = state => _get(getFeedData(state), 'page');
export const getFeedDataItemsPerPage = state => _get(getFeedData(state), 'itemsPerPage');
export const getLastPage = state => _get(feedState(state), 'lastPage');
export const getFilters = state => _get(feedState(state), 'filters');

export const getShowOptionsModal = state => _get(feedState(state), 'showOptionsModal');
export const selectShowDownloadModal = state => _get(feedState(state), 'showDownloadModal');

export const getIsRefreshing = state => _get(feedState(state), 'isRefreshing');
export const getIsFetchingFeed = state => _get(feedState(state), 'isFetchingFeed');

// settings
const settingsState = state => _get(productsState(state), 'settings');
export const getSettingsColumnsOrder = state => _get(settingsState(state), 'columnsOrder');
export const getSettingsColumns = state => _get(settingsState(state), 'columns');
export const getSettingsItemsPerPage = state => _get(settingsState(state), 'itemsPerPage');
export const getSettingsSorting = state => _get(settingsState(state), 'sorting');

// download
const downloadState = state => _get(productsState(state), 'download');
export const selectDownloadLogs = state => _get(downloadState(state), 'logs');
export const selectIsFetchingLogs = state => _get(downloadState(state), 'isFetchingLogs');
export const selectIsRequestingDownload = state =>
  _get(downloadState(state), 'isRequestingDownload');
export const selectIsDownloading = state => _get(downloadState(state), 'isDownloading');
export const selectIsCancelling = state => _get(downloadState(state), 'isCancelling');
