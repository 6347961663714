import _forEach from 'lodash/forEach';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class CompanyApi extends HttpRequest {
  getInformation() {
    return this.call(ENDPOINTS.INFORMATION);
  }

  updateInformation({ params = {} }) {
    const bodyFormData = new FormData();

    _forEach(params, (value, key) => {
      bodyFormData.set(key, value);
    });

    return this.call(ENDPOINTS.INFORMATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
