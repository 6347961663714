import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const loadUserProfile = createAction(ACTIONS.LOAD_USER_PROFILE);
export const loadUserProfileSuccess = createAction(ACTIONS.LOAD_USER_PROFILE_SUCCESS, 'payload');
export const loadUserProfileError = createAction(
  ACTIONS.LOAD_USER_PROFILE_ERROR,
  'errorMessage',
  'error',
);

export const updateProfile = createAction(ACTIONS.UPDATE_PROFILE, 'profileInfo');
export const updateProfileSuccess = createAction(ACTIONS.UPDATE_PROFILE_SUCCESS, 'payload');
export const updateProfileError = createAction(
  ACTIONS.UPDATE_PROFILE_ERROR,
  'errorMessage',
  'error',
);

export const changePassword = createAction(
  ACTIONS.CHANGE_PASSWORD,
  'currentPassword',
  'newPassword',
);
export const changePasswordSuccess = createAction(ACTIONS.CHANGE_PASSWORD_SUCCESS, 'payload');
export const changePasswordError = createAction(
  ACTIONS.CHANGE_PASSWORD_ERROR,
  'errorMessage',
  'error',
);

export const resetProfileState = createAction(ACTIONS.RESET_STATE);
