import { all, takeEvery, put, call } from 'redux-saga/effects';
import { handleURLFilters } from 'features/sagaHelpers';
import { ACTIONS } from '../constants';
import {
  loadShippingRules,
  loadShipSetup,
  setFilters,
  fetchBrandsSuppliers,
} from '../actions';

function* initContainerWatcher() {
  yield call(handleURLFilters, setFilters);
  yield put(loadShipSetup());
  yield put(loadShippingRules());
  yield put(fetchBrandsSuppliers());
}

function* exitContainerWatcher() {
  //
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
  ]);
}