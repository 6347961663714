import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _flatMap from 'lodash/flatMap';
import _compact from 'lodash/compact';
import {
  fetchPermissions,
  fetchRoles,
  setAddRole,
  setDeleteRole,
  setEditRole,
} from 'services/userManagement/actions';
import { handleAuthorizationErrorSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';
import {
  addRoleError,
  addRoleSuccess,
  deleteRoleError,
  deleteRoleSuccess,
  editRoleError,
  editRoleSuccess,
  initContainerError,
  initContainerSuccess,
  loadRoles,
  loadRolesError,
  loadRolesSuccess,
  setAuthorizationError,
  setDeleteSelectedRole,
  setShowAddRoleModal,
  setShowDeleteRoleModal,
  setShowEditRoleModal,
} from './actions';

function* initContainerWatcher() {
  yield put(fetchPermissions(initContainerSuccess, initContainerError));
}

function* initContainerSuccessWatcher() {
  yield put(loadRoles());
}

function* initContainerErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* loadRolesWatcher() {
  yield put(fetchRoles(loadRolesSuccess, loadRolesError));
}

function* loadRolesErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* addRoleWatcher({ params }) {
  const { roleName, roleDescription, rolePermissions } = params;

  const permissions = _compact(_flatMap(rolePermissions));

  const newParams = {
    role_name: roleName,
    description: roleDescription,
    permissions,
  };
  yield put(setAddRole(newParams, addRoleSuccess, addRoleError));
}

function* addRoleSuccessWatcher() {
  yield call([toast, toast.success], 'Role successfully added.');
  yield put(setShowAddRoleModal(false));
  yield put(loadRoles());
}

function* addRoleErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* deleteRoleWatcher({ params }) {
  const { roleName } = params;
  const newParams = {
    role_name: roleName,
  };
  yield put(setDeleteRole(newParams, deleteRoleSuccess, deleteRoleError));
}

function* deleteRoleSuccessWatcher() {
  yield call([toast, toast.success], 'Role successfully deleted.');
  yield put(setShowDeleteRoleModal(false));
  yield put(loadRoles());
  yield put(setDeleteSelectedRole(null));
}

function* deleteRoleErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* editRoleWatcher({ params }) {
  const { roleName, roleDescription, rolePermissions } = params;

  const permissions = _compact(_flatMap(rolePermissions));

  const newParams = {
    role_name: roleName,
    description: roleDescription,
    permissions,
  };
  yield put(setEditRole(newParams, editRoleSuccess, editRoleError));
}

function* editRoleSuccessWatcher() {
  yield call([toast, toast.success], 'Role successfully updated.');
  yield put(setShowEditRoleModal(false));
  yield put(loadRoles());
}

function* editRoleErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* rolesManagementSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.INIT_CONTAINER_SUCCESS, initContainerSuccessWatcher),
    takeEvery(ACTIONS.INIT_CONTAINER_ERROR, initContainerErrorWatcher),

    takeEvery(ACTIONS.LOAD_ROLES, loadRolesWatcher),
    takeEvery(ACTIONS.LOAD_ROLES_ERROR, loadRolesErrorWatcher),

    takeEvery(ACTIONS.ADD_ROLE, addRoleWatcher),
    takeEvery(ACTIONS.ADD_ROLE_SUCCESS, addRoleSuccessWatcher),
    takeEvery(ACTIONS.ADD_ROLE_ERROR, addRoleErrorWatcher),

    takeEvery(ACTIONS.DELETE_ROLE, deleteRoleWatcher),
    takeEvery(ACTIONS.DELETE_ROLE_SUCCESS, deleteRoleSuccessWatcher),
    takeEvery(ACTIONS.DELETE_ROLE_ERROR, deleteRoleErrorWatcher),

    takeEvery(ACTIONS.EDIT_ROLE, editRoleWatcher),
    takeEvery(ACTIONS.EDIT_ROLE_SUCCESS, editRoleSuccessWatcher),
    takeEvery(ACTIONS.EDIT_ROLE_ERROR, editRoleErrorWatcher),
  ]);
}
