import _isEmpty from 'lodash/isEmpty';
import _isRegExp from 'lodash/isRegExp';

const ONLY_INTEGER = /^[0-9\b]+$/;
const ONLY_LETTERS = /^[A-Za-z]+$/;
const ONLY_LETTERS_SPACE = /^[a-zA-Z\s]*$/;
const ONLY_INTEGER_OR_DECIMALS = /^(?:0|[1-9][0-9]*)(?:\.([0-9]{1,2})?)?$/;
const ONLY_NUMBER_UP_TO_HUNDRED = /^(([1-9]?\d)(?:\.(\d{1,2})?)?|100(\.00?)?)$/;
const ONLY_USERNAME_FORMAT = /^[a-z0-9_-]+$/;
const ONLY_AWS_GROUP_NAME_FORMAT = /^[a-zA-Z0-9_.-]+$/;
const ONLY_EMAIL_ADDRESS = /^[\S]+$/;
const ONLY_PLUS_SIGN_NUMBERS = /^([+])?[\d]*$/;

const VALUE_TYPES_REGEXP = {
  integer: ONLY_INTEGER,
  letters: ONLY_LETTERS,
  letters_space: ONLY_LETTERS_SPACE,
  decimals: ONLY_INTEGER_OR_DECIMALS,
  percentage: ONLY_NUMBER_UP_TO_HUNDRED,
  username: ONLY_USERNAME_FORMAT,
  aws_group_name: ONLY_AWS_GROUP_NAME_FORMAT,
  email: ONLY_EMAIL_ADDRESS,
  plus_sign_numbers: ONLY_PLUS_SIGN_NUMBERS,
};

export const validateValueType = (valueType, value) => {
  if (_isEmpty(value)) {
    return true;
  }

  const regexp = VALUE_TYPES_REGEXP[valueType] || valueType;

  if (!_isRegExp(regexp)) {
    return true;
  }

  return regexp.test(value);
};
