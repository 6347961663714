import { all, call } from 'redux-saga/effects';
import {
  sagas,
  scanZoneNameWorker,
  scanZoneNameSuccessWorker,
  scanZoneNameErrorWorker,
  toggleZoneLockWorker,
  lockZoneWorker,
  lockZoneErrorWorker,
  unlockZoneWorker,
  unlockZoneErrorWorker,
  reloadZoneLockWorker,
  reloadZoneLockErrorWorker,
  toggleSelectItemWorker,
  toggleSelectAllItemsWorker,
  scanUPCWorker,
  scanUPCSuccessWorker,
  scanUPCErrorWorker,
  moveSelectedItemsWorker,
  moveSelectedItemsSuccessWorker,
  moveSelectedItemsErrorWorker,
  reloadZoneWorker,
} from './sagaWorkers';
import {
  getZoneLocked,
  getZoneName,
  getZoneLockNonce,
  getChangedItems,
  getOtherZoneName,
  findZoneItemByUPC,
  getZoneCoords,
  getOtherZoneCoords,
  selectSelectedItems,
} from '../selectors/zoneBSelectors';
import {
  scanZoneNameSuccess,
  scanZoneNameError,
  setChangedItems,
  unlockZone,
  lockZone,
  lockZoneSuccess,
  lockZoneError,
  unlockZoneSuccess,
  unlockZoneError,
  reloadZoneLockSuccess,
  reloadZoneLockError,
  updateSelectedItems,
  scanUPCSuccess,
  scanUPCError,
  scanZoneName,
  reloadZone,
  moveSelectedItemsSuccess,
  moveSelectedItemsError,
  reloadZoneLock,
  setSelectedItems,
} from '../actions/zoneB';
import { reloadZone as reloadOtherZone } from '../actions/zoneA';
import { ZONE_B_ACTIONS as ACTIONS } from '../constants';

function* scanZoneNameWatcher({ zoneName }) {
  yield call(scanZoneNameWorker, {
    zoneName, getZoneName, scanZoneNameSuccess, scanZoneNameError,
  });
}

function* scanZoneNameSuccessWatcher({ payload }) {
  yield call(scanZoneNameSuccessWorker, {
    payload, reloadZoneLock, reloadZone, setChangedItems,
  });
}

function* scanZoneNameErrorWatcher() {
  yield call(scanZoneNameErrorWorker);
}

function* toggleZoneLockWatcher() {
  yield call(toggleZoneLockWorker, { getZoneLocked, unlockZone, lockZone });
}

function* lockZoneWatcher() {
  yield call(lockZoneWorker, { getZoneName, lockZoneSuccess, lockZoneError });
}

function* lockZoneErrorWatcher({ errorMessage }) {
  yield call(lockZoneErrorWorker, { errorMessage, reloadZone });
}

function* unlockZoneWatcher() {
  yield call(unlockZoneWorker, {
    getZoneName, getZoneLockNonce, unlockZoneSuccess, unlockZoneError,
  });
}

function* unlockZoneErrorWatcher({ errorMessage }) {
  yield call(unlockZoneErrorWorker, { reloadZone, errorMessage });
}

function* reloadZoneLockWatcher() {
  yield call(reloadZoneLockWorker, { getZoneName, reloadZoneLockSuccess, reloadZoneLockError });
}

function* reloadZoneLockErrorWatcher({ errorMessage }) {
  yield call(reloadZoneLockErrorWorker, { errorMessage });
}

function* toggleSelectItemWatcher({ upc, isSelected }) {
  yield call(toggleSelectItemWorker, {
    upc, isSelected, getChangedItems, updateSelectedItems,
  });
}

function* toggleSelectAllItemsWatcher({ selectAll }) {
  yield call(toggleSelectAllItemsWorker, { selectAll, getChangedItems, updateSelectedItems });
}

function* scanUPCWatcher({ upc }) {
  yield call(scanUPCWorker, {
    upc,
    findZoneItemByUPC,
    getZoneCoords,
    getZoneName,
    getOtherZoneCoords,
    getOtherZoneName,
    scanUPCSuccess,
    scanUPCError,
  });
}

function* scanUPCSuccessWatcher() {
  yield call(scanUPCSuccessWorker, { reloadZone, reloadOtherZone });
}

function* scanUPCErrorWatcher({ errorMessage }) {
  yield call(scanUPCErrorWorker, { errorMessage, reloadZone, reloadOtherZone });
}

function* moveSelectedItemsWatcher() {
  yield call(moveSelectedItemsWorker, {
    getOtherZoneName,
    getZoneName,
    selectSelectedItems,
    getChangedItems,
    moveSelectedItemsSuccess,
    moveSelectedItemsError,
  });
}

function* moveSelectedItemsSuccessWatcher() {
  yield call(moveSelectedItemsSuccessWorker, { reloadZone, reloadOtherZone, setSelectedItems });
}

function* moveSelectedItemsErrorWatcher({ errorMessage }) {
  yield call(moveSelectedItemsErrorWorker, { errorMessage, reloadZone, reloadOtherZone });
}

function* reloadZoneWatcher() {
  yield call(reloadZoneWorker, { getZoneName, scanZoneName });
}

export default function* zoneASagas() {
  yield all(sagas(ACTIONS, {
    scanZoneNameWatcher,
    scanZoneNameSuccessWatcher,
    scanZoneNameErrorWatcher,
    toggleZoneLockWatcher,
    lockZoneWatcher,
    lockZoneErrorWatcher,
    unlockZoneWatcher,
    unlockZoneErrorWatcher,
    reloadZoneLockWatcher,
    reloadZoneLockErrorWatcher,
    toggleSelectItemWatcher,
    toggleSelectAllItemsWatcher,
    scanUPCWatcher,
    scanUPCSuccessWatcher,
    scanUPCErrorWatcher,
    moveSelectedItemsWatcher,
    moveSelectedItemsSuccessWatcher,
    moveSelectedItemsErrorWatcher,
    reloadZoneWatcher,
  }));
}