const ENDPOINTS = {
  GET_SALES_CHANNELS: '/sales_channels',
  ADD_SALES_CHANNEL: '/add_sales_channel',
  EDIT_SALES_CHANNEL: '/edit_sales_channel',
  DELETE_SALES_CHANNEL: '/delete_sales_channel',
  GET_CHANNEL_TYPES: '/channel_types',
  ADD_INTEGRATION: '/add_integration',
  DELETE_INTEGRATION: '/delete_integration',
  AMAZON_CONNECTION: '/initiate_amazon_connection',
  INTEGRATION_SERVICES: '/integration_services',
};

export default ENDPOINTS;
