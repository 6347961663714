import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const initContainerSuccess = createAction(ACTIONS.INIT_CONTAINER_SUCCESS);
export const initContainerError = createAction(
  ACTIONS.INIT_CONTAINER_ERROR,
  'errorMessage',
  'error',
);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadSalesChannels = createAction(ACTIONS.LOAD_SALES_CHANNELS);
export const loadSalesChannelsSuccess = createAction(
  ACTIONS.LOAD_SALES_CHANNELS_SUCCESS,
  'payload',
);
export const loadSalesChannelsError = createAction(
  ACTIONS.LOAD_SALES_CHANNELS_ERROR,
  'errorMessage',
  'error',
);

export const loadChannelTypes = createAction(ACTIONS.LOAD_CHANNEL_TYPES);
export const loadChannelTypesSuccess = createAction(ACTIONS.LOAD_CHANNEL_TYPES_SUCCESS, 'payload');
export const loadChannelTypesError = createAction(
  ACTIONS.LOAD_CHANNEL_TYPES_ERROR,
  'errorMessage',
  'error',
);

export const setShowCreateChannelModal = createAction(
  ACTIONS.SET_SHOW_CREATE_CHANNEL_MODAL,
  'showCreateChannelModal',
);
export const setShowEditChannelModal = createAction(
  ACTIONS.SET_SHOW_EDIT_CHANNEL_MODAL,
  'showEditChannelModal',
);
export const setShowDeleteChannelModal = createAction(
  ACTIONS.SET_SHOW_DELETE_CHANNEL_MODAL,
  'showDeleteChannelModal',
);

export const setShowAddIntegrationModal = createAction(
  ACTIONS.SET_SHOW_ADD_INTEGRATION_MODAL,
  'showAddIntegrationModal',
);

export const setChannelTypeSelected = createAction(
  ACTIONS.SET_CHANNEL_TYPE_SELECTED,
  'channelTypeSelected',
);
export const setChannelIdSelected = createAction(
  ACTIONS.SET_CHANNEL_ID_SELECTED,
  'channelIdSelected',
);

export const setCreatedChannelData = createAction(
  ACTIONS.SET_CREATED_CHANNEL_DATA,
  'createdChannelData',
);

export const createSalesChannel = createAction(ACTIONS.CREATE_SALES_CHANNEL, 'channelData');
export const createSalesChannelSuccess = createAction(
  ACTIONS.CREATE_SALES_CHANNEL_SUCCESS,
  'payload',
);
export const createSalesChannelError = createAction(
  ACTIONS.CREATE_SALES_CHANNEL_ERROR,
  'errorMessage',
  'error',
);

export const updateSalesChannel = createAction(ACTIONS.UPDATE_SALES_CHANNEL, 'channelData');
export const updateSalesChannelSuccess = createAction(
  ACTIONS.UPDATE_SALES_CHANNEL_SUCCESS,
  'payload',
);
export const updateSalesChannelError = createAction(
  ACTIONS.UPDATE_SALES_CHANNEL_ERROR,
  'errorMessage',
  'error',
);

export const removeSalesChannel = createAction(ACTIONS.REMOVE_SALES_CHANNEL, 'channelId');
export const removeSalesChannelSuccess = createAction(
  ACTIONS.REMOVE_SALES_CHANNEL_SUCCESS,
  'payload',
);
export const removeSalesChannelError = createAction(
  ACTIONS.REMOVE_SALES_CHANNEL_ERROR,
  'errorMessage',
  'error',
);

export const removeIntegration = createAction(ACTIONS.REMOVE_INTEGRATION, 'lookupId');
export const removeIntegrationSuccess = createAction(ACTIONS.REMOVE_INTEGRATION_SUCCESS, 'payload');
export const removeIntegrationError = createAction(
  ACTIONS.REMOVE_INTEGRATION_ERROR,
  'errorMessage',
  'error',
);

export const createIntegration = createAction(ACTIONS.CREATE_INTEGRATION, 'channelData');
export const createIntegrationSuccess = createAction(ACTIONS.CREATE_INTEGRATION_SUCCESS, 'payload');
export const createIntegrationError = createAction(
  ACTIONS.CREATE_INTEGRATION_ERROR,
  'errorMessage',
  'error',
);

export const installIntegration = createAction(ACTIONS.INSTALL_INTEGRATION, 'payload');

export const loadIntegrationServices = createAction(ACTIONS.LOAD_INTEGRATION_SERVICES);
export const loadIntegrationServicesSuccess = createAction(
  ACTIONS.LOAD_INTEGRATION_SERVICES_SUCCESS,
  'payload',
);
export const loadIntegrationServicesError = createAction(
  ACTIONS.LOAD_INTEGRATION_SERVICES_ERROR,
  'errorMessage',
  'error',
);

export const setAuthorizationError = createAction(
  ACTIONS.SET_AUTHORIZATION_ERROR,
  'authorizationError',
);

export const resetState = createAction(ACTIONS.RESET_STATE);
