import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { setFeatureName } from 'containers/Dashboard/actions';
import addOrderSagas from './AddOrder/sagas';
import shippingSagas from './Shipping/sagas';
import orderExporterSagas from './OrderExporter/sagas';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.ORDER_MANAGEMENT.title));
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* orderManagementSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(addOrderSagas, ...args),
    fork(shippingSagas, ...args),
    fork(orderExporterSagas, ...args),
  ]);
}
