export const ACTIONS = {
  INIT_CONTAINER: 'INTEGRATIONS__INIT_CONTAINER',
  INIT_CONTAINER_SUCCESS: 'INTEGRATIONS__INIT_CONTAINER_SUCCESS',
  INIT_CONTAINER_ERROR: 'INTEGRATIONS__INIT_CONTAINER_ERROR',
  EXIT_CONTAINER: 'INTEGRATIONS__EXIT_CONTAINER',

  LOAD_SALES_CHANNELS: 'INTEGRATIONS__LOAD_SALES_CHANNELS',
  LOAD_SALES_CHANNELS_SUCCESS: 'INTEGRATIONS__LOAD_SALES_CHANNELS_SUCCESS',
  LOAD_SALES_CHANNELS_ERROR: 'INTEGRATIONS__LOAD_SALES_CHANNELS_ERROR',

  LOAD_CHANNEL_TYPES: 'INTEGRATIONS__CHANNEL_TYPES',
  LOAD_CHANNEL_TYPES_SUCCESS: 'INTEGRATIONS__LOAD_CHANNEL_TYPES_SUCCESS',
  LOAD_CHANNEL_TYPES_ERROR: 'INTEGRATIONS__LOAD_CHANNEL_TYPES_ERROR',

  LOAD_INTEGRATION_SERVICES: 'INTEGRATIONS__LOAD_INTEGRATION_SERVICES',
  LOAD_INTEGRATION_SERVICES_SUCCESS: 'INTEGRATIONS__LOAD_INTEGRATION_SERVICES_SUCCESS',
  LOAD_INTEGRATION_SERVICES_ERROR: 'INTEGRATIONS__LOAD_INTEGRATION_SERVICES_ERROR',

  SET_SHOW_CREATE_CHANNEL_MODAL: 'INTEGRATIONS__SET_SHOW_CREATE_CHANNEL_MODAL',
  SET_SHOW_EDIT_CHANNEL_MODAL: 'INTEGRATIONS__SET_SHOW_EDIT_CHANNEL_MODAL',
  SET_SHOW_DELETE_CHANNEL_MODAL: 'INTEGRATIONS__SET_SHOW_DELETE_CHANNEL_MODAL',

  SET_SHOW_ADD_INTEGRATION_MODAL: 'INTEGRATIONS__SET_SHOW_ADD_INTEGRATION_MODAL',

  SET_CHANNEL_TYPE_SELECTED: 'INTEGRATIONS__SET_CHANNEL_TYPE_SELECTED',
  SET_CHANNEL_ID_SELECTED: 'INTEGRATIONS__SET_CHANNEL_ID_SELECTED',

  SET_CREATED_CHANNEL_DATA: 'INTEGRATIONS__SET_CREATED_CHANNEL_DATA',

  CREATE_SALES_CHANNEL: 'INTEGRATIONS__CREATE_SALES_CHANNEL',
  CREATE_SALES_CHANNEL_SUCCESS: 'INTEGRATIONS__CREATE_SALES_CHANNEL_SUCCESS',
  CREATE_SALES_CHANNEL_ERROR: 'INTEGRATIONS__CREATE_SALES_CHANNEL_ERROR',

  UPDATE_SALES_CHANNEL: 'INTEGRATIONS__UPDATE_SALES_CHANNEL',
  UPDATE_SALES_CHANNEL_SUCCESS: 'INTEGRATIONS__UPDATE_SALES_CHANNEL_SUCCESS',
  UPDATE_SALES_CHANNEL_ERROR: 'INTEGRATIONS__UPDATE_SALES_CHANNEL_ERROR',

  REMOVE_SALES_CHANNEL: 'INTEGRATIONS__REMOVE_SALES_CHANNEL',
  REMOVE_SALES_CHANNEL_SUCCESS: 'INTEGRATIONS__REMOVE_SALES_CHANNEL_SUCCESS',
  REMOVE_SALES_CHANNEL_ERROR: 'INTEGRATIONS__REMOVE_SALES_CHANNEL_ERROR',

  INSTALL_INTEGRATION: 'INTEGRATIONS__INSTALL_INTEGRATION',

  REMOVE_INTEGRATION: 'INTEGRATIONS__REMOVE_INTEGRATION',
  REMOVE_INTEGRATION_SUCCESS: 'INTEGRATIONS__REMOVE_INTEGRATION_SUCCESS',
  REMOVE_INTEGRATION_ERROR: 'INTEGRATIONS__REMOVE_INTEGRATION_ERROR',

  CREATE_INTEGRATION: 'INTEGRATIONS__CREATE_INTEGRATION',
  CREATE_INTEGRATION_SUCCESS: 'INTEGRATIONS__CREATE_INTEGRATION_SUCCESS',
  CREATE_INTEGRATION_ERROR: 'INTEGRATIONS__CREATE_INTEGRATION_ERROR',

  SET_AUTHORIZATION_ERROR: 'INTEGRATIONS__SET_AUTHORIZATION_ERROR',

  RESET_STATE: 'INTEGRATIONS__RESET_STATE',
};
