import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchPurchaseOrder = createAction(
  ACTIONS.FETCH_PURCHASE_ORDER,
  'purchaseOrderNumber',
  'successActionCallback',
  'errorActionCallback',
);
export const setPurchaseOrderScannedItems = createAction(
  ACTIONS.SET_PURCHASE_ORDER_SCANNED_ITEMS,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
