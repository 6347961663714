import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const loadCompanyData = createAction(ACTIONS.LOAD_COMPANY_DATA);
export const loadCompanyDataSuccess = createAction(ACTIONS.LOAD_COMPANY_DATA_SUCCESS, 'payload');
export const loadCompanyDataError = createAction(
  ACTIONS.LOAD_COMPANY_DATA_ERROR,
  'errorMessage',
  'error',
);
export const updateBusinessInfo = createAction(ACTIONS.UPDATE_BUSINESS_INFO, 'formValues');
export const updateBusinessInfoSuccess = createAction(
  ACTIONS.UPDATE_BUSINESS_INFO_SUCCESS,
  'payload',
);
export const updateBusinessInfoError = createAction(
  ACTIONS.UPDATE_BUSINESS_INFO_ERROR,
  'errorMessage',
  'error',
);
export const updatePickTicketInfo = createAction(ACTIONS.UPDATE_PICK_TICKET_INFO, 'formValues');
export const updatePickTicketInfoSuccess = createAction(
  ACTIONS.UPDATE_PICK_TICKET_INFO_SUCCESS,
  'payload',
);
export const updatePickTicketInfoError = createAction(
  ACTIONS.UPDATE_PICK_TICKET_INFO_ERROR,
  'errorMessage',
  'error',
);
export const updateBusinessLogo = createAction(ACTIONS.UPDATE_BUSINESS_LOGO, 'fileData');
export const updateBusinessLogoSuccess = createAction(
  ACTIONS.UPDATE_BUSINESS_LOGO_SUCCESS,
  'payload',
);
export const updateBusinessLogoError = createAction(
  ACTIONS.UPDATE_BUSINESS_LOGO_ERROR,
  'errorMessage',
  'error',
);
export const setAuthorizationError = createAction(
  ACTIONS.SET_AUTHORIZATION_ERROR,
  'authorizationError',
);
