import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchShipRulesCSV } from 'services/shipments/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import {
  exportToFileSuccess,
  exportToFileError,
} from '../actions';

const EXPORT_FILE_NAME = 'filtered_vendor_rules.csv';

function* exportToFileWatcher() {
  yield put(fetchShipRulesCSV(exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* exportToFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, exportToFileErrorWatcher),
  ]);
}