export const ACTIONS = {
  FETCH_ORDER_FEED: 'SERVICE_ORDERS__FETCH_ORDER_FEED',
  FETCH_ORDER_FEED_SUCCESS: 'SERVICE_ORDERS__FETCH_ORDER_FEED_SUCCESS',
  FETCH_ORDER_FEED_ERROR: 'SERVICE_ORDERS__FETCH_ORDER_FEED_ERROR',
  FETCH_ORDER_FEED_QUERY: 'SERVICE_ORDERS__FETCH_ORDER_FEED_QUERY',
  FETCH_ORDER_FEED_CSV: 'SERVICE_ORDERS__FETCH_EXPORT_ORDER_FEED',
  SET_UPDATE_FLAG: 'SERVICE_ORDERS__SET_UPDATE_FLAG',
  SET_CANCEL_ORDER_LINES: 'SERVICE_ORDERS__SET_CANCEL_ORDER_LINES',

  FETCH_SWEEP_ORDER_LINE: 'SERVICE_ORDERS__FETCH_SWEEP_ORDER_LINE',
  FETCH_SEARCH_ORDERS: 'SERVICE_ORDERS__FETCH_SEARCH_ORDERS',
  SET_ADD_ORDER: 'SERVICE_ORDERS__SET_ADD_ORDER',
  FETCH_GENERATE_PICK_TICKET: 'SERVICE_ORDERS__FETCH_GENERATE_PICK_TICKET',
  FETCH_ORDER_EXPORTER_PAST_REPORTS: 'SERVICE_ORDERS__FETCH_ORDER_EXPORTER_PAST_REPORTS',
  SET_ORDER_EXPORTER_CSV_DOWNLOAD: 'SERVICE_ORDERS__SET_ORDER_EXPORTER_CSV_DOWNLOAD',
  GET_FILTERED_ORDER_LINES: 'SERVICE_ORDERS__GET_FILTERED_ORDER_LINES',
  GET_FILTERED_ORDERS: 'SERVICE_ORDERS__GET_FILTERED_ORDERS',
  GET_ORDER_DETAILS: 'SERVICE_ORDERS__GET_ORDER_DETAILS',
  POST_ORDER_NOTE: 'SERVICE_ORDERS__POST_ORDER_NOTE',
  GET_ORDER_LINE_NOTES: 'SERVICE_ORDER__GET_ORDER_LINE_NOTES',
  POST_ADD_ORDER_LINE_NOTES: 'SERVICE_ORDER__POST_ADD_ORDER_LINE_NOTES',
};

export const ORDER_EXPORTER_ORDER_TYPES = {
  edi: {
    id: 'edi',
    label: 'EDI',
  },
  warehouse: {
    id: 'warehouse',
    label: 'Warehouse',
  },
  b2b_email: {
    id: 'b2b_email',
    label: 'B2B/Email',
  },
};
