import _get from 'lodash/get';
import { purchaseOrderManagementState } from 'features/PurchaseOrderManagement/selectors';
import { createSelector } from 'reselect';
import _reduce from 'lodash/reduce';
import _isEqual from 'lodash/isEqual';

const receivingState = state => _get(purchaseOrderManagementState(state), 'receiving');
export const getPurchaseOrderItems = state => _get(receivingState(state), 'purchaseOrderItems');
export const getIsScanningPurchaseOrder = state =>
  _get(receivingState(state), 'isScanningPurchaseOrder');
export const getIsCommittingItems = state => _get(receivingState(state), 'isCommittingItems');
export const getIsReloadingPurchaseOrder = state =>
  _get(receivingState(state), 'isReloadingPurchaseOrder');
export const getScannedItems = state => _get(receivingState(state), 'scannedItems');
export const getInitialScannedItemsSnapshot = state =>
  _get(receivingState(state), 'initialScannedItemsSnapshot');
export const getCommitItems = createSelector(
  getScannedItems,
  getInitialScannedItemsSnapshot,
  (scanned, initialSnapshot) =>
    _reduce(
      scanned,
      (result, item, key) => {
        const newResult = result;
        const initialItem = initialSnapshot[key];
        if (!_isEqual(item, initialItem)) {
          newResult[key] = {
            ...item,
            scanned_in: item.count - initialItem.count,
          };
        }
        return newResult;
      },
      {},
    ),
);
export const getPurchaseOrderNumber = state => _get(receivingState(state), 'purchaseOrderNumber');
export const getUPCErrorList = state => _get(receivingState(state), 'upcErrorList');
export const getShowManualConfirmModal = state =>
  _get(receivingState(state), 'showManualConfirmModal');
export const getShowSalesOrdersListModal = state =>
  _get(receivingState(state), 'showSalesOrdersListModal');
export const getManualConfirmItem = state => _get(receivingState(state), 'manualConfirmItem');
export const getHasChanges = state => _get(receivingState(state), 'hasChanges');
export const getSelectedSalesOrdersList = state =>
  _get(receivingState(state), 'selectedSalesOrdersList');
export const getShipmentVolumeUnit = state => _get(receivingState(state), 'shipmentVolumeUnit');
export const getShipmentTotalCost = state => _get(receivingState(state), 'shipmentTotalCost');
export const getShipmentTotalVolume = state => _get(receivingState(state), 'shipmentTotalVolume');
