import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchChannelTypes,
  fetchSalesChannels,
  addSalesChannel,
  editSalesChannel,
  deleteSalesChannel,
  deleteIntegration,
  addIntegration,
  fetchIntegrationServices,
} from 'services/integrations/actions';
import {
  errorToasterSaga,
  handleAuthorizationErrorSaga,
  successToasterSaga,
} from 'services/sagaWorkers';
import {
  loadSalesChannels,
  loadSalesChannelsSuccess,
  loadSalesChannelsError,
  loadChannelTypes,
  loadChannelTypesSuccess,
  loadChannelTypesError,
  createSalesChannelSuccess,
  createSalesChannelError,
  updateSalesChannelSuccess,
  updateSalesChannelError,
  removeSalesChannelSuccess,
  removeSalesChannelError,
  removeIntegrationSuccess,
  removeIntegrationError,
  createIntegrationSuccess,
  createIntegrationError,
  loadIntegrationServices,
  loadIntegrationServicesSuccess,
  loadIntegrationServicesError,
  setChannelTypeSelected,
  setChannelIdSelected,
  setShowEditChannelModal,
  setShowDeleteChannelModal,
  setShowAddIntegrationModal,
  setAuthorizationError,
  initContainerSuccess,
  initContainerError,
} from './actions';
import { ACTIONS } from './constants';

function* initContainerWatcher() {
  yield put(loadIntegrationServices());
}

function* initContainerSuccessWatcher() {
  yield put(loadSalesChannels());
  yield put(loadChannelTypes());
}

function* initContainerErrorWatcher({ errorMessage, error }) {
  yield call(handleAuthorizationErrorSaga, { errorMessage, error, action: setAuthorizationError });
}

function* loadSalesChannelsWatcher() {
  yield put(fetchSalesChannels(loadSalesChannelsSuccess, loadSalesChannelsError));
}

function* loadChannelTypesWatcher() {
  yield put(fetchChannelTypes(loadChannelTypesSuccess, loadChannelTypesError));
}

function* loadIntegrationServicesWatcher() {
  yield put(fetchIntegrationServices(loadIntegrationServicesSuccess, loadIntegrationServicesError));
}

function* loadIntegrationServicesSuccessWatcher() {
  yield put(initContainerSuccess());
}

function* loadIntegrationServicesErrorWatcher({ errorMessage, error }) {
  yield put(initContainerError(errorMessage, error));
}

function* setShowCreateChannelModalWatcher({ showCreateChannelModal }) {
  if (!showCreateChannelModal) {
    yield put(setChannelTypeSelected(null));
  }
}

function* setShowEditChannelModalWatcher({ showEditChannelModal }) {
  if (!showEditChannelModal) {
    yield put(setChannelIdSelected(null));
  }
}

function* setShowDeleteChannelModalWatcher({ showDeleteChannelModal }) {
  if (!showDeleteChannelModal) {
    yield put(setChannelIdSelected(null));
  }
}

function* createSalesChannelWatcher({ channelData }) {
  yield put(addSalesChannel(channelData, createSalesChannelSuccess, createSalesChannelError));
}

function* createSalesChannelSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Sales Channel successfully created.' });
  yield put(loadSalesChannels());
}

function* updateSalesChannelWatcher({ channelData }) {
  const { salesChannelId, name, ...rest } = channelData;
  const channelDataObj = {
    ...rest,
    description: name,
  };
  yield put(
    editSalesChannel(
      salesChannelId,
      channelDataObj,
      updateSalesChannelSuccess,
      updateSalesChannelError,
    ),
  );
}

function* updateSalesChannelSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Sales Channel successfully edited.' });
  yield put(setShowEditChannelModal(false));
  yield put(loadSalesChannels());
}

function* removeSalesChannelWatcher({ channelId }) {
  yield put(deleteSalesChannel(channelId, removeSalesChannelSuccess, removeSalesChannelError));
}

function* removeSalesChannelSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Sales Channel successfully deleted.' });
  yield put(loadSalesChannels());
  yield put(setShowDeleteChannelModal(false));
}

function* removeIntegrationWatcher({ lookupId }) {
  yield put(deleteIntegration(lookupId, removeIntegrationSuccess, removeIntegrationError));
}

function* removeIntegrationSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Integration successfully disconnected.' });
  yield put(loadSalesChannels());
}

function* createIntegrationWatcher({ channelData }) {
  yield put(addIntegration(channelData, createIntegrationSuccess, createIntegrationError));
}

function* createIntegrationSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Channel keyPoint successfully added.' });
  yield put(loadSalesChannels());
  yield put(setShowAddIntegrationModal(false));
}

export default function* integrationsSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.INIT_CONTAINER_SUCCESS, initContainerSuccessWatcher),
    takeEvery(ACTIONS.INIT_CONTAINER_ERROR, initContainerErrorWatcher),

    takeEvery(ACTIONS.LOAD_SALES_CHANNELS, loadSalesChannelsWatcher),
    takeEvery(ACTIONS.LOAD_SALES_CHANNELS_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.LOAD_CHANNEL_TYPES, loadChannelTypesWatcher),
    takeEvery(ACTIONS.LOAD_CHANNEL_TYPES_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.LOAD_INTEGRATION_SERVICES, loadIntegrationServicesWatcher),
    takeEvery(ACTIONS.LOAD_INTEGRATION_SERVICES_SUCCESS, loadIntegrationServicesSuccessWatcher),
    takeEvery(ACTIONS.LOAD_INTEGRATION_SERVICES_ERROR, loadIntegrationServicesErrorWatcher),

    takeEvery(ACTIONS.SET_SHOW_CREATE_CHANNEL_MODAL, setShowCreateChannelModalWatcher),
    takeEvery(ACTIONS.SET_SHOW_EDIT_CHANNEL_MODAL, setShowEditChannelModalWatcher),
    takeEvery(ACTIONS.SET_SHOW_DELETE_CHANNEL_MODAL, setShowDeleteChannelModalWatcher),

    takeEvery(ACTIONS.CREATE_SALES_CHANNEL, createSalesChannelWatcher),
    takeEvery(ACTIONS.CREATE_SALES_CHANNEL_SUCCESS, createSalesChannelSuccessWatcher),
    takeEvery(ACTIONS.CREATE_SALES_CHANNEL_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.UPDATE_SALES_CHANNEL, updateSalesChannelWatcher),
    takeEvery(ACTIONS.UPDATE_SALES_CHANNEL_SUCCESS, updateSalesChannelSuccessWatcher),
    takeEvery(ACTIONS.UPDATE_SALES_CHANNEL_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.REMOVE_SALES_CHANNEL, removeSalesChannelWatcher),
    takeEvery(ACTIONS.REMOVE_SALES_CHANNEL_SUCCESS, removeSalesChannelSuccessWatcher),
    takeEvery(ACTIONS.REMOVE_SALES_CHANNEL_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.REMOVE_INTEGRATION, removeIntegrationWatcher),
    takeEvery(ACTIONS.REMOVE_INTEGRATION_SUCCESS, removeIntegrationSuccessWatcher),
    takeEvery(ACTIONS.REMOVE_INTEGRATION_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.CREATE_INTEGRATION, createIntegrationWatcher),
    takeEvery(ACTIONS.CREATE_INTEGRATION_SUCCESS, createIntegrationSuccessWatcher),
    takeEvery(ACTIONS.CREATE_INTEGRATION_ERROR, errorToasterSaga),
  ]);
}
