import { all, fork } from 'redux-saga/effects';
import orderTypeSagas from './orderTypeSagas';
import shippingAddressSagas from './shippingAddressSagas';
import addItemsSagas from './addItemsSagas';
import completeOrderSagas from './completeOrderSagas';

export default function* addOrderSagas(...args) {
  yield all([
    fork(orderTypeSagas, ...args),
    fork(shippingAddressSagas, ...args),
    fork(addItemsSagas, ...args),
    fork(completeOrderSagas, ...args),
  ]);
}
