import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const initContainerSuccess = createAction(ACTIONS.INIT_CONTAINER_SUCCESS);
export const initContainerError = createAction(
  ACTIONS.INIT_CONTAINER_ERROR,
  'errorMessage',
  'error',
);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const setAuthorizationError = createAction(
  ACTIONS.SET_AUTHORIZATION_ERROR,
  'authorizationError',
);

export const loadRoles = createAction(ACTIONS.LOAD_ROLES);
export const loadRolesSuccess = createAction(ACTIONS.LOAD_ROLES_SUCCESS, 'payload');
export const loadRolesError = createAction(ACTIONS.LOAD_ROLES_ERROR, 'errorMessage', 'error');

export const setShowAddRoleModal = createAction(
  ACTIONS.SET_SHOW_ADD_ROLE_MODAL,
  'showAddRoleModal',
);
export const setShowDeleteRoleModal = createAction(
  ACTIONS.SET_SHOW_DELETE_ROLE_MODAL,
  'showDeleteRoleModal',
);
export const setShowEditRoleModal = createAction(
  ACTIONS.SET_SHOW_EDIT_ROLE_MODAL,
  'showEditRoleModal',
);

export const setDeleteSelectedRole = createAction(
  ACTIONS.SET_DELETE_SELECTED_ROLE,
  'deleteSelectedRole',
);
export const setEditSelectedRole = createAction(ACTIONS.SET_EDIT_SELECTED_ROLE, 'editSelectedRole');

export const addRole = createAction(ACTIONS.ADD_ROLE, 'params');
export const addRoleSuccess = createAction(ACTIONS.ADD_ROLE_SUCCESS, 'payload');
export const addRoleError = createAction(ACTIONS.ADD_ROLE_ERROR, 'errorMessage', 'error');

export const editRole = createAction(ACTIONS.EDIT_ROLE, 'params');
export const editRoleSuccess = createAction(ACTIONS.EDIT_ROLE_SUCCESS, 'payload');
export const editRoleError = createAction(ACTIONS.EDIT_ROLE_ERROR, 'errorMessage', 'error');

export const deleteRole = createAction(ACTIONS.DELETE_ROLE, 'params');
export const deleteRoleSuccess = createAction(ACTIONS.DELETE_ROLE_SUCCESS, 'payload');
export const deleteRoleError = createAction(ACTIONS.DELETE_ROLE_ERROR, 'errorMessage', 'error');
