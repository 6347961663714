import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const loadReport = createAction(ACTIONS.LOAD_REPORT);
export const loadReportSuccess = createAction(ACTIONS.LOAD_REPORT_SUCCESS, 'payload');
export const loadReportError = createAction(ACTIONS.LOAD_REPORT_ERROR, 'errorMessage', 'error');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(
  ACTIONS.EXPORT_TO_FILE_ERROR,
  'errorMessage',
  'error',
);

export const resetReportState = createAction(ACTIONS.RESET_STATE);
