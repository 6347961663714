import React from 'react';
import _isString from 'lodash/isString';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import { FIELD_VALUE_TYPES, FIELD_TYPES } from './constants';
import { validateValueType } from './helpers';
import { TextFieldWrap } from './styles';

/**
 * A custom TextField component with additional features like value type validation.
 *
 * @component
 * @example
 * <TextField
 *   onChange={(value) => console.log(value)}
 *   valueType={FIELD_VALUE_TYPES.INTEGER}
 * />
 */
const TextField = ({
  onChange,
  valueType,
  error,
  helperText,
  endAdornment,
  startAdornment,
  isLoading,
  ...rest
}) => {
  const displayHelperText = error && _isString(error) ? error : helperText;
  const handleOnChange = e => {
    const {
      target: { value, name },
    } = e;

    if (!valueType) {
      onChange(value, name);
      return;
    }

    const validTypeValue = validateValueType(valueType, value);

    if (validTypeValue) {
      onChange(value, name);
    }
  };
  return (
    <TextFieldWrap
      {...rest}
      helperText={displayHelperText}
      error={!!error}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: (endAdornment || isLoading) && (
          <InputAdornment position="end">
            {isLoading && (
              <Box mr={1}>
                <CircularProgress size="1rem" />
              </Box>
            )}
            {endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
};

TextField.propTypes = {
  endAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  startAdornment: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  /**
   * Specifies the type of values accepted by the TextField component.
   * - If a string, it should be one of the predefined values from FIELD_VALUE_TYPES.
   * - If a regular expression, it defines a custom validation pattern.
   *
   * @type {string | RegExp}
   */
  valueType: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(FIELD_VALUE_TYPES)),
    PropTypes.instanceOf(RegExp),
  ]),
};

TextField.defaultProps = {
  endAdornment: null,
  startAdornment: null,
  error: null,
  helperText: null,
  isLoading: false,
  onChange() {},
  valueType: FIELD_VALUE_TYPES.ANY,
};

export default TextField;

export { FIELD_VALUE_TYPES, FIELD_TYPES };
