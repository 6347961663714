import { all, takeEvery, put, select, call } from 'redux-saga/effects';
import { formatFilterToObject, formatSortByToObject } from 'app/api/helpers';
import {
  getDownloadProductsFile,
  getDownloadProductsLogs,
  postCancelDownloadRequest,
  postDownloadProducts,
} from 'services/productManagement/actions';
import { errorToasterSaga, fileSaverSaga } from 'services/sagaWorkers';
import {
  cancelDownloadRequestError,
  cancelDownloadRequestSuccess,
  downloadProductsFileError,
  downloadProductsFileSuccess,
  fetchDownloadLogs,
  fetchDownloadLogsError,
  fetchDownloadLogsSuccess,
  requestDownloadProductsError,
  requestDownloadProductsSuccess,
} from '../actions';
import { ACTIONS } from '../constants';
import { getFilters, getLastPage, getSettingsSorting } from '../selectors';

const EXPORT_FILE_NAME = 'products.csv';

function* fetchDownloadLogsWatcher() {
  yield put(getDownloadProductsLogs(fetchDownloadLogsSuccess, fetchDownloadLogsError));
}

function* requestDownloadProductsWatcher() {
  const { columnId, direction } = yield select(getSettingsSorting);
  const page = yield select(getLastPage);
  const sortBy = yield call(formatSortByToObject, columnId, direction);
  const filters = yield select(getFilters);
  const filter = yield call(formatFilterToObject, filters);

  const params = {
    page,
    sortBy,
    filter,
  };
  yield put(
    postDownloadProducts(params, requestDownloadProductsSuccess, requestDownloadProductsError),
  );
}

function* requestDownloadProductsSuccessWatcher() {
  yield put(fetchDownloadLogs());
}

function* downloadProductsFileWatcher({ fileKey }) {
  yield put(
    getDownloadProductsFile(fileKey, downloadProductsFileSuccess, downloadProductsFileError),
  );
}

function* downloadProductsFileSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: downloadProductsFileError,
  });
}

function* cancelDownloadRequestWatcher({ requestId }) {
  yield put(
    postCancelDownloadRequest(requestId, cancelDownloadRequestSuccess, cancelDownloadRequestError),
  );
}

function* cancelDownloadRequestSuccessWatcher() {
  yield put(fetchDownloadLogs());
}

export default function* downloadSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_DOWNLOAD_LOGS, fetchDownloadLogsWatcher),
    takeEvery(ACTIONS.FETCH_DOWNLOAD_LOGS_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.REQUEST_DOWNLOAD_PRODUCTS, requestDownloadProductsWatcher),
    takeEvery(ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_SUCCESS, requestDownloadProductsSuccessWatcher),
    takeEvery(ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.DOWNLOAD_PRODUCTS_FILE, downloadProductsFileWatcher),
    takeEvery(ACTIONS.DOWNLOAD_PRODUCTS_FILE_SUCCESS, downloadProductsFileSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_PRODUCTS_FILE_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.CANCEL_DOWNLOAD_REQUEST, cancelDownloadRequestWatcher),
    takeEvery(ACTIONS.CANCEL_DOWNLOAD_REQUEST_SUCCESS, cancelDownloadRequestSuccessWatcher),
    takeEvery(ACTIONS.CANCEL_DOWNLOAD_REQUEST_ERROR, errorToasterSaga),
  ]);
}
