import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { replaceSpacesWithUnderscore } from 'utils/formatString';
import {
  fetchOrderExporterPastReports,
  setOrderExporterCSVDownload,
} from 'services/orders/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { convertDateToISO } from 'utils/date';
import { ACTIONS } from './constants';
import {
  getReportsSuccess,
  getReportsError,
  downloadFileSuccess,
  downloadFileError,
  getReports,
  exportDownloadSuccess,
  exportDownloadError,
} from './actions';
import {
  getSelectedDate,
  getSelectedOrderType,
  getSelectedTimestamp,
  getCurrentDate,
} from './selectors';

const EXPORT_FILE_NAME = (timestamp = '') => `exported_orders_${timestamp}.csv`;

function* getReportsWatcher() {
  const selectedDate = yield select(getSelectedDate);
  const orderType = yield select(getSelectedOrderType);
  const formattedDate = convertDateToISO(selectedDate);

  yield put(
    fetchOrderExporterPastReports(formattedDate, orderType, getReportsSuccess, getReportsError),
  );
}

function* getReportsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* downloadFileWatcher() {
  const timestamp = yield select(getSelectedTimestamp);
  const orderType = yield select(getSelectedOrderType);

  const params = {
    timestamp,
    orderType,
    isPast: 1,
  };

  yield put(setOrderExporterCSVDownload(params, downloadFileSuccess, downloadFileError));
}

function* downloadFileSuccessWatcher({ payload }) {
  const timestamp = yield select(getSelectedTimestamp);

  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME(`${replaceSpacesWithUnderscore(timestamp)}_past`),
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: downloadFileError,
  });
}

function* downloadFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* exportDownloadWatcher() {
  const currentDate = yield select(getCurrentDate);
  const orderType = yield select(getSelectedOrderType);
  const convertedDateToISO = currentDate.toISOString().replace('T', ' ').substr(0, 19);

  const params = {
    timestamp: convertedDateToISO,
    orderType,
    isPast: 0,
  };

  yield put(setOrderExporterCSVDownload(params, exportDownloadSuccess, exportDownloadError));
}

function* exportDownloadSuccessWatcher({ payload }) {
  const currentDate = yield select(getCurrentDate);
  const timestamp = convertDateToISO(currentDate);

  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME(replaceSpacesWithUnderscore(timestamp)),
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportDownloadError,
  });

  yield put(getReports());
}

function* exportDownloadErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* orderExporterSagas() {
  yield all([
    takeEvery(ACTIONS.GET_REPORTS, getReportsWatcher),
    takeEvery(ACTIONS.GET_REPORTS_ERROR, getReportsErrorWatcher),
    takeEvery(ACTIONS.DOWNLOAD_FILE, downloadFileWatcher),
    takeEvery(ACTIONS.DOWNLOAD_FILE_SUCCESS, downloadFileSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_FILE_ERROR, downloadFileErrorWatcher),
    takeEvery(ACTIONS.EXPORT_DOWNLOAD, exportDownloadWatcher),
    takeEvery(ACTIONS.EXPORT_DOWNLOAD_SUCCESS, exportDownloadSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_DOWNLOAD_ERROR, exportDownloadErrorWatcher),
  ]);
}
