import { VIEW_COLUMNS } from './columns';

export const VIEW_FILTERS = {
  [VIEW_COLUMNS.ORDER_ID.id]: {
    ...VIEW_COLUMNS.ORDER_ID,
    filterable: true,
  },
  [VIEW_COLUMNS.SALES_ORDER_NUMBER.id]: {
    ...VIEW_COLUMNS.SALES_ORDER_NUMBER,
    filterable: true,
  },
  [VIEW_COLUMNS.SKU.id]: {
    ...VIEW_COLUMNS.SKU,
    filterable: true,
  },
  [VIEW_COLUMNS.UPC.id]: {
    ...VIEW_COLUMNS.UPC,
    filterable: true,
  },
  [VIEW_COLUMNS.PURCHASE_ORDER_NUMBER.id]: {
    ...VIEW_COLUMNS.PURCHASE_ORDER_NUMBER,
    filterable: true,
  },
  [VIEW_COLUMNS.QTY_ORDERED.id]: {
    ...VIEW_COLUMNS.QTY_ORDERED,
    filterable: true,
  },
  [VIEW_COLUMNS.PRICE.id]: {
    ...VIEW_COLUMNS.PRICE,
    filterable: true,
  },
  [VIEW_COLUMNS.ORDER_LINE_STATUS.id]: {
    ...VIEW_COLUMNS.ORDER_LINE_STATUS,
    filterable: true,
  },
  [VIEW_COLUMNS.LINE_TYPE.id]: {
    ...VIEW_COLUMNS.LINE_TYPE,
    filterable: true,
  },
  [VIEW_COLUMNS.PURCHASE_DATE.id]: {
    ...VIEW_COLUMNS.PURCHASE_DATE,
    filterable: true,
  },
  [VIEW_COLUMNS.PROMISE_DATE.id]: {
    ...VIEW_COLUMNS.PROMISE_DATE,
    filterable: true,
  },
  [VIEW_COLUMNS.EDI.id]: {
    ...VIEW_COLUMNS.EDI,
    filterable: true,
  },
  [VIEW_COLUMNS.BRAND.id]: {
    ...VIEW_COLUMNS.BRAND,
    filterable: true,
  },
  [VIEW_COLUMNS.SUPPLIER.id]: {
    ...VIEW_COLUMNS.SUPPLIER,
    filterable: true,
  },
  [VIEW_COLUMNS.SALES_CHANNEL.id]: {
    ...VIEW_COLUMNS.SALES_CHANNEL,
    filterable: true,
  },
  [VIEW_COLUMNS.COST.id]: {
    ...VIEW_COLUMNS.COST,
    filterable: true,
  },
  [VIEW_COLUMNS.MARKUP.id]: {
    ...VIEW_COLUMNS.MARKUP,
    filterable: true,
  },
  [VIEW_COLUMNS.NOTES.id]: {
    ...VIEW_COLUMNS.NOTES,
    filterable: false,
  },
  [VIEW_COLUMNS.ADDRESS.id]: {
    ...VIEW_COLUMNS.ADDRESS,
    filterable: false,
  },
  [VIEW_COLUMNS.SHIPPING_PRIORITY.id]: {
    ...VIEW_COLUMNS.SHIPPING_PRIORITY,
    filterable: true,
  },
  [VIEW_COLUMNS.FULFILLMENT_WAREHOUSE_ABBR.id]: {
    ...VIEW_COLUMNS.FULFILLMENT_WAREHOUSE_ABBR,
    filterable: true,
  },
};

export const QUICK_FILTERS = [
  {
    label: 'Exported',
    value: 1,
    operand: { column: 'exported', condition: '=', value: '1' },
  },
  {
    label: 'Not Exported',
    value: 2,
    operand: { column: 'exported', condition: '=', value: '0' },
  },
  {
    label: 'Cancelled',
    value: 3,
    operand: { column: 'order_line_status', condition: '=', value: 'cancelled' },
  },
  {
    label: 'Not Cancelled',
    value: 4,
    operand: { column: 'order_line_status', condition: '<>', value: 'cancelled' },
  },
  {
    label: 'Prime',
    value: 5,
    operand: { column: 'is_prime', condition: '=', value: '1' },
  },
  {
    label: 'Not Prime',
    value: 6,
    operand: { column: 'is_prime', condition: '=', value: '0' },
  },
  {
    label: 'Premium',
    value: 7,
    operand: { column: 'is_premium', condition: '=', value: '1' },
  },
  {
    label: 'Not Premium',
    value: 8,
    operand: { column: 'is_premium', condition: '=', value: '0' },
  },
  {
    label: 'Unfulfilled',
    value: 9,
    operand: { column: 'order_line_status', condition: '=', value: 'unfulfilled' },
  }, {
    label: 'Partially Fulfilled',
    value: 10,
    operand: { column: 'order_line_status', condition: '=', value: 'partially_fulfilled' },
  }, {
    label: 'Fulfilled',
    value: 11,
    operand: { column: 'order_line_status', condition: '=', value: 'fulfilled' },
  },
];