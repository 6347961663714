import { all, fork, takeEvery, put, select } from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { fetchShippingZoneName } from 'services/inventory/actions';
import { getShippingZoneName } from 'services/inventory/selectors';
import { setFeatureName } from 'containers/Dashboard/actions';
import inventoryViewSagas from './InventoryView/sagas';
import inventoryMoveSagas from './InventoryMove/sagas';
import pickingSagas from './Picking/sagas';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.INVENTORY_MANAGEMENT.title));

  const shippingZoneName = yield select(getShippingZoneName);
  if (!shippingZoneName) {
    yield put(fetchShippingZoneName());
  }
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* inventoryManagementSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(inventoryViewSagas, ...args),
    fork(inventoryMoveSagas, ...args),
    fork(pickingSagas, ...args),
  ]);
}
