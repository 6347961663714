import apiConf from './paths';
import packageJson from '../../../package.json';

export const APP_VERSION = packageJson.version;
export const ENV = process.env.NODE_ENV;
export const DEV_MODE = ENV !== 'production';
export const PROD_MODE = ENV === 'production';
export const LOCAL_MODE = ENV === 'local';
// const baseUrl = 'https://blitzsuite.logisticsforhire.com';
const baseUrl = '';

export default {
  accountApi: {
    url: `${baseUrl}${apiConf.paths.account}`,
  },
  authApi: {
    url: `${baseUrl}${apiConf.paths.auth}`,
  },
  brandsApi: {
    url: `${baseUrl}${apiConf.paths.brands}`,
  },
  companyApi: {
    url: `${baseUrl}${apiConf.paths.company}`,
  },
  dataVisualizationApi: {
    url: `${baseUrl}${apiConf.paths.data_visualization}`,
  },
  feedsApi: {
    url: `${baseUrl}${apiConf.paths.feeds}`,
  },
  firebaseApi: {
    url: `${baseUrl}${apiConf.paths.firebase}`,
  },
  integrationsApi: {
    url: `${baseUrl}${apiConf.paths.integrations}`,
  },
  inventoryApi: {
    url: `${baseUrl}${apiConf.paths.inventory}`,
  },
  ordersApi: {
    url: `${baseUrl}${apiConf.paths.orders}`,
  },
  orderLinesApi: {
    url: `${baseUrl}${apiConf.paths.order_lines}`,
  },
  productManagementApi: {
    url: `${baseUrl}${apiConf.paths.product_management}`,
  },
  purchaseOrdersApi: {
    url: `${baseUrl}${apiConf.paths.purchase_orders}`,
  },
  searchOrdersApi: {
    url: `${baseUrl}${apiConf.paths.searchOrders}`,
  },
  shipmentsApi: {
    url: `${baseUrl}${apiConf.paths.shipments}`,
  },
  suppliersApi: {
    url: `${baseUrl}${apiConf.paths.suppliers}`,
  },
  tomApi: {
    url: `${baseUrl}${apiConf.paths.tom}`,
  },
  userManagementApi: {
    url: `${baseUrl}${apiConf.paths.user_management}`,
  },
  utilitiesApi: {
    url: `${baseUrl}${apiConf.paths.utilities}`,
  },
  testingApi: {
    url: `${baseUrl}${apiConf.paths.testing}`,
  },
};
