import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class IntegrationsApi extends HttpRequest {
  getSalesChannels() {
    return this.call(ENDPOINTS.GET_SALES_CHANNELS);
  }

  addSalesChannel({ channelData }) {
    const { channelTypeId, name, abbr, balanceable } = channelData;

    const bodyFormData = new FormData();
    bodyFormData.append('channel_type_id', channelTypeId);
    bodyFormData.append('description', name);
    bodyFormData.append('abbr', abbr);
    bodyFormData.append('balanceable', balanceable);

    return this.call(ENDPOINTS.ADD_SALES_CHANNEL, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  editSalesChannel({ channelId, channelData }) {
    const { description, balanceable } = channelData;

    const bodyFormData = new FormData();
    bodyFormData.append('sales_channel_id', channelId);
    bodyFormData.append('description', description);
    bodyFormData.append('balanceable', balanceable);

    return this.call(ENDPOINTS.EDIT_SALES_CHANNEL, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteSalesChannel({ channelId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('sales_channel_id', channelId);

    return this.call(ENDPOINTS.DELETE_SALES_CHANNEL, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getChannelTypes() {
    return this.call(ENDPOINTS.GET_CHANNEL_TYPES);
  }

  deleteIntegration({ lookupId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('lookup_id', lookupId);

    return this.call(ENDPOINTS.DELETE_INTEGRATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  addIntegration({ channelData }) {
    const { service_name: serviceName, auth_method: authMethod, data, metadata } = channelData;

    const bodyFormData = new FormData();
    bodyFormData.append('service_name', serviceName);
    bodyFormData.append('auth_method', authMethod);
    bodyFormData.append('data', JSON.stringify(data));
    bodyFormData.append('metadata', JSON.stringify(metadata));

    return this.call(ENDPOINTS.ADD_INTEGRATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getIntegrationServices() {
    return this.call(ENDPOINTS.INTEGRATION_SERVICES);
  }
}

export { ENDPOINTS };
