export const ACTIONS = {
  SET_IMPORT_INVENTORY_LABEL_GENERATOR_FILE:
    'SERVICE_UTILITIES__SET_IMPORT_INVENTORY_LABEL_GENERATOR_FILE',
  FETCH_INVENTORY_LABEL_GENERATOR_SAMPLE:
    'SERVICE_UTILITIES__FETCH_INVENTORY_LABEL_GENERATOR_SAMPLE',

  SET_IMPORT_UPC_LABEL_GENERATOR_FILE: 'SERVICE_UTILITIES__SET_IMPORT_UPC_LABEL_GENERATOR_FILE',
  FETCH_UPC_LABEL_GENERATOR_SAMPLE: 'SERVICE_UTILITIES__FETCH_UPC_LABEL_GENERATOR_SAMPLE',
};

export const UPC_LABEL_GENERATOR_OPTIONS = {
  include_product_data: {
    key: 'include_product_data',
    label: 'Include Product Data',
    defaultValue: true,
  },
  skip_missing_products: {
    key: 'skip_missing_products',
    label: 'Skip Missing Products',
    defaultValue: true,
  },
};
