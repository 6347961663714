import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const listBrands = createAction(
  ACTIONS.LIST_BRANDS,
  'successActionCallback',
  'errorActionCallback',
);
export const addBrand = createAction(
  ACTIONS.ADD_BRAND,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const updateBrand = createAction(
  ACTIONS.UPDATE_BRAND,
  'brandId',
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const deleteBrand = createAction(
  ACTIONS.DELETE_BRAND,
  'brandId',
  'successActionCallback',
  'errorActionCallback',
);
