import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { setFeatureName } from 'containers/Dashboard/actions';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';
import pickTicketGeneratorSagas from './PickTicketGenerator/sagas';
import inventoryLabelGeneratorSagas from './InventoryLabelGenerator/sagas';
import UPCLabelGeneratorSagas from './UPCLabelGenerator/sagas';
import bulkMarkShipSagas from './BulkMarkShip/sagas';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.TOOLS.title));
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* toolsSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(pickTicketGeneratorSagas, ...args),
    fork(inventoryLabelGeneratorSagas, ...args),
    fork(UPCLabelGeneratorSagas, ...args),
    fork(bulkMarkShipSagas, ...args),
  ]);
}
