/* eslint-disable no-underscore-dangle */
const _map = require('lodash/map');

const apiPath = '/api';

const paths = {
  account: `${apiPath}/account`,
  auth: `${apiPath}/auth`,
  brands: `${apiPath}/product_management/brands`,
  company: `${apiPath}/company`,
  data_visualization: `${apiPath}/data_visualization`,
  feeds: `${apiPath}/feeds`,
  firebase: `${apiPath}/firebase`,
  images: `${apiPath}/images`,
  integrations: `${apiPath}/integrations`,
  inventory: `${apiPath}/inventory`,
  orders: `${apiPath}/orders`,
  order_lines: `${apiPath}/order_lines`,
  product_management: `${apiPath}/product_management`,
  purchase_orders: `${apiPath}/purchase_orders`,
  searchOrders: `${apiPath}/search_orders`,
  shipments: `${apiPath}/shipments`,
  suppliers: `${apiPath}/product_management/suppliers`,
  tom: `${apiPath}/tom`,
  user_management: `${apiPath}/user_management`,
  utilities: `${apiPath}/utilities`,
  testing: `${apiPath}`,
};

module.exports = {
  paths,
  pathsArray: _map(paths),
};
