import { all, takeEvery, put, call, delay } from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';
import { fetchSearchOrders } from 'services/orders/actions';
import { ACTIONS } from '../constants';
import { searchOrdersSuccess, searchOrdersError, sortSearchOrdersResultsSuccess } from '../actions';

function* searchOrdersWatcher({ searchTerm }) {
  yield put(fetchSearchOrders(searchTerm, searchOrdersSuccess, searchOrdersError));
}

function* searchOrdersErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* sortSearchOrderResultsWatcher({ results, columnId, direction }) {
  const sortedResults = _orderBy(results, [columnId], [direction]);
  yield delay(100);
  yield put(sortSearchOrdersResultsSuccess(sortedResults));
}

export default function* searchOrdersSagas() {
  yield all([
    takeEvery(ACTIONS.SEARCH_ORDERS, searchOrdersWatcher),
    takeEvery(ACTIONS.SEARCH_ORDERS_ERROR, searchOrdersErrorWatcher),
    takeEvery(ACTIONS.SORT_SEARCH_ORDER_RESULTS, sortSearchOrderResultsWatcher),
  ]);
}
