import { call, select } from 'redux-saga/effects';
import { buildInventoryItem } from 'services/inventory/helpers';
import {
  getZoneLevel,
  getZoneRow,
  getZoneCol,
  findZoneItemByUPC,
} from '../selectors';

export function* buildZoneItem(upc, qty) {
  const zoneLevel = yield select(getZoneLevel);
  const zoneRow = yield select(getZoneRow);
  const zoneCol = yield select(getZoneCol);
  const {
    qty: originalQty, lid, upc: itemUpc, ...rest
  } = yield select(findZoneItemByUPC(upc));
  const inventoryItem = yield call(buildInventoryItem, {
    lid,
    qty,
    upc: itemUpc || upc,
    row: zoneRow,
    col: zoneCol,
    lvl: zoneLevel,
    ...rest,
  });

  return inventoryItem;
}