import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';
import { toast } from 'react-toastify';
import { getShippingZoneName } from 'services/inventory/selectors';
import { INVENTORY_MANAGEMENT_ERRORS } from 'features/InventoryManagement/constants';
import { buildInventoryItem } from 'services/inventory/helpers';
import {
  setMoveToShipping,
  setMoveFromShipping,
} from 'services/inventory/actions';
import { getZoneCoords, getZoneName } from '../selectors/pickingZoneSelectors';
import { getZoneItems as getShippingZoneItems } from '../selectors/shippingZoneSelectors';
import { setZoneItems as setShippingZoneItems } from '../actions/shippingZone';
import { findSelectedItems } from './helpers';

export function* toggleSelectItemWorker({
  upc, isSelected, getChangedItems, updateSelectedItems,
}) {
  const changedItems = yield select(getChangedItems);
  const itemIndex = _findIndex(changedItems, { upc });
  const updatedChangedItems = _reject(changedItems, { upc });
  const updatedItem = {
    ..._find(changedItems, { upc }),
  };
  updatedItem.selected = isSelected;
  updatedChangedItems.splice(itemIndex, 0, updatedItem);

  yield put(updateSelectedItems(updatedChangedItems));
}

export function* toggleSelectAllItemsWorker({ selectAll, getChangedItems, updateSelectedItems }) {
  const changedItems = yield select(getChangedItems);
  const updatedChangedItems = changedItems.map(item => {
    const updatedItem = {
      ...item,
    };
    updatedItem.selected = selectAll;
    return updatedItem;
  });
  yield put(updateSelectedItems(updatedChangedItems));
}

export function* cancelChangedItemsWorker({ getZoneItems, setChangedItems }) {
  const zoneItems = yield select(getZoneItems);
  yield put(setChangedItems(zoneItems));
}

export function* scanUPCWorker({
  upc,
  targetZoneName,
  findZoneItemByUPC,
  scanUPCSuccess,
  scanUPCError,
}) {
  const shippingZoneName = yield select(getShippingZoneName);
  const pickingZone = yield select(getZoneCoords);
  const pickingZoneName = yield select(getZoneName);
  const zoneItem = yield select(findZoneItemByUPC(upc));
  const pickingZoneItem = yield call(buildInventoryItem, {
    qty: 1,
    upc,
    sku: zoneItem.sku,
    row: pickingZone.row,
    col: pickingZone.col,
    lvl: pickingZone.level,
  });
  const itemCollection = [pickingZoneItem];
  const moveAction = targetZoneName === shippingZoneName ? setMoveToShipping : setMoveFromShipping;
  yield put(moveAction(
    pickingZoneName, shippingZoneName, itemCollection, scanUPCSuccess, scanUPCError,
  ));
}

export function* moveSelectedItemsWorker({
  targetZoneName,
  getChangedItems,
  moveSelectedItemsSuccess,
  moveSelectedItemsError,
  selectSelectedItems,
}) {
  const shippingZoneName = yield select(getShippingZoneName);
  const pickingZone = yield select(getZoneCoords);
  const pickingZoneName = yield select(getZoneName);
  const items = yield select(getChangedItems);
  const selectedItemIds = yield select(selectSelectedItems);
  const selectedItems = findSelectedItems(selectedItemIds, items);

  if (!selectedItems) {
    yield put(moveSelectedItemsError('Selected items not found in zone.'));
    return;
  }

  const itemCollection = yield all(selectedItems.map(item => {
    const inventoryItem = call(buildInventoryItem, {
      ...item,
      row: pickingZone.row,
      col: pickingZone.col,
      lvl: pickingZone.level,
    });
    return inventoryItem;
  }));

  const moveAction = targetZoneName === shippingZoneName ? setMoveToShipping : setMoveFromShipping;
  yield put(moveAction(
    pickingZoneName,
    shippingZoneName,
    itemCollection,
    moveSelectedItemsSuccess,
    moveSelectedItemsError,
  ));
}

export function* moveSelectedItemsErrorWorker({
  errorMessage, reloadZone, reloadOtherZone,
}) {
  yield put(reloadZone());
  yield put(reloadOtherZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.MOVING_ITEMS);
}

const formatUpdatedItem = item => ({
  upc: item.UPC,
  qty: item.Qty,
  sku: item.SKU,
});

export function* addToShippingZone(list) {
  let shippingZoneItems = yield select(getShippingZoneItems);

  const updatedShippingZoneItems = yield all(_map(list, item => {
    const updatedItem = formatUpdatedItem(item);

    const existingItem = _find(shippingZoneItems, { upc: updatedItem.upc });
    if (!existingItem) {
      return updatedItem;
    }

    const { qty: existingQty } = existingItem;
    const { qty: newQty } = updatedItem;

    shippingZoneItems = _reject(shippingZoneItems, { upc: updatedItem.upc });

    return {
      ...existingItem,
      qty: Number(existingQty) + Number(newQty),
    };
  }));
  const updatedList = _sortBy([
    ...shippingZoneItems,
    ...updatedShippingZoneItems,
  ], ['upc']);
  yield put(setShippingZoneItems(updatedList));
}

export function* removeFromShippingZone(list) {
  let shippingZoneItems = yield select(getShippingZoneItems);

  const updatedShippingZoneItems = yield all(_map(list, item => {
    const updatedItem = formatUpdatedItem(item);
    const existingItem = _find(shippingZoneItems, { upc: updatedItem.upc });
    const { qty: existingQty } = existingItem;
    const { qty: newQty } = updatedItem;

    shippingZoneItems = _reject(shippingZoneItems, { upc: updatedItem.upc });
    const deductedQty = Number(existingQty) - Number(newQty);
    if (deductedQty < 1) {
      return null;
    }

    return {
      ...existingItem,
      qty: deductedQty,
    };
  }));
  const updatedList = _sortBy(_compact([
    ...shippingZoneItems,
    ...updatedShippingZoneItems,
  ]), ['upc']);
  yield put(setShippingZoneItems(updatedList));
}

export function sagas(ACTIONS, watchers) {
  const {
    toggleSelectItemWatcher,
    toggleSelectAllItemsWatcher,
    cancelChangedItemsWatcher,
    scanUPCWatcher,
    moveSelectedItemsWatcher,
  } = watchers;

  return [
    takeEvery(ACTIONS.TOGGLE_SELECT_ITEM, toggleSelectItemWatcher),
    takeEvery(ACTIONS.TOGGLE_SELECT_ALL_ITEMS, toggleSelectAllItemsWatcher),
    takeEvery(ACTIONS.CANCEL_CHANGED_ITEMS, cancelChangedItemsWatcher),
    takeEvery(ACTIONS.SCAN_UPC, scanUPCWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS, moveSelectedItemsWatcher),
  ];
}