import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { convertDateToISO } from 'utils/date';
import { fetchSalesReportCSV } from 'services/feeds/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import { exportToFileSuccess, exportToFileError } from '../actions';
import { getReportRequestParams } from './loadReportSagas';
import { getEndDate, getStartDate } from '../selectors';

const EXPORT_FILE_NAME = (dateRange = '') => `sales_report__${dateRange}.csv`;

function* exportToFileWatcher() {
  const params = yield call(getReportRequestParams);

  yield put(fetchSalesReportCSV(params, exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  const selectedStartDate = yield select(getStartDate);
  const selectedEndDate = yield select(getEndDate);
  const startDate = convertDateToISO(selectedStartDate);
  const endDate = convertDateToISO(selectedEndDate);
  const dateRange = startDate === endDate ? endDate : `${startDate}-to-${endDate}`;

  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME(dateRange),
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* exportToFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, exportToFileErrorWatcher),
  ]);
}
