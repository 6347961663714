import styled, { css } from 'styled-components';
import { IRON, ALABASTER, WHITE, PUNCH, OLSO_GRAY, BOULDER, AZURE } from 'constants/colors';
import { genericBorder } from 'assets/styles/utils';
import { ButtonStyled } from 'components/Button/styles';
import { ROBOTO, ROBOTO_MEDIUM } from 'assets/styles/fonts';

export const FieldIconWrap = styled.i`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.2rem;
  > svg {
    color: ${OLSO_GRAY};
    width: 100%;
    height: 100%;
  }
`;

// sizes
const sizeSmall = css`
  height: 1.6875rem;
  > span {
    font-size: 0.75rem;
  }
  ${FieldIconWrap} {
    width: 0.8rem;
    height: 0.8rem;
  }
`;
const sizeMedium = css`
  height: 2.1875rem;
  > span {
    font-size: 0.875rem;
  }
  ${FieldIconWrap} {
    width: 1rem;
    height: 1rem;
  }
`;
const sizeLarge = css`
  height: 3.125rem;
  > span {
    font-size: 1.2rem;
  }
  ${FieldIconWrap} {
    width: 1.2rem;
    height: 1.2rem;
  }
`;
export const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};
const sizeTypes = {
  [sizes.small]: sizeSmall,
  [sizes.medium]: sizeMedium,
  [sizes.large]: sizeLarge,
};

export const FIELD_BORDER_RADIUS = '0.25rem';

export const FIELD_FOCUS_OUTLINE = {
  ERROR: 'box-shadow: 0px 0px 2px 2px rgba(220,53,69,0.9);',
  DEFAULT: 'box-shadow: 0px 0px 2px 2px rgba(0,123,255,0.9);',
};

export const FieldWrapOuter = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  min-width: ${({ minWidth }) => minWidth || '0'};
  width: ${({ width }) => width || '100%'};
`;

export const FieldWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: ${FIELD_BORDER_RADIUS};
  border: ${genericBorder(IRON)};
  height: 3rem;
  margin: 0;
  background-color: ${WHITE};
  box-sizing: border-box;
  width: 100%;
  &:focus-within {
    ${FIELD_FOCUS_OUTLINE.DEFAULT}
  }
  ${({ hasError }) => hasError && `
    border: ${genericBorder(PUNCH)};
    &:focus-within {
      ${FIELD_FOCUS_OUTLINE.ERROR}
    }
  `}
  ${({ size }) => size && sizeTypes[size]}
  ${({ disabled }) => disabled && `
    opacity: 0.6;
    background-color: ${ALABASTER};
  `}

  > input {
    width: 100%;
    height: 100%;
    background: none;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    text-align: ${({ align }) => align || 'left'};
    ${({ disabled }) => disabled && `
      cursor: not-allowed;
  `}
  }
`;

export const FieldLabel = styled.label`
  font-size: 0.9rem;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  vertical-align: text-top;
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'initial')};
  ${({ disabled }) => disabled && `
    opacity: 0.6;
  `}
`;

export const RequiredLabel = styled.span`
  color: ${PUNCH};
  font-size: 1rem;
`;

export const FieldLabelWrap = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.5rem;
  align-items: center;
  ${FieldLabel} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0.2rem 0 0;
    text-align: left;
    font-size: 0.9rem;
  }
`;

export const FieldError = styled.span`
  color: ${PUNCH};
  font-family: ${ROBOTO_MEDIUM};
  margin: ${({ margin }) => margin || '0'};
`;

export const FieldHint = styled.span`
  color: ${BOULDER};
  font-family: ${ROBOTO};
  line-height: 1rem;
`;

export const FieldSpinnerWrap = styled.i`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.2rem;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex-grow: ${({ flexGrow }) => flexGrow || '1'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  min-width: ${({ minWidth }) => minWidth || '0'};
  padding: ${({ padding }) => padding || '0'};
  grid-column-gap: ${({ gapX }) => gapX || '0'};
  margin: ${({ margin }) => margin || '0.5rem 1rem 0.5rem 0'};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  > label {
    text-align: right;
    margin: 0 0.5rem;
    flex: auto;
    flex-grow: 1;
  }
  > form {
    display: flex;
    flex-wrap: inherit;
    margin: 0;
  }
  > *, > form > * {
    flex: 1;
    width: auto;
    margin: 0 0.5rem;
    > button {
      margin-bottom: 0;
      margin-top: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  ${ButtonStyled} {
    max-width: fit-content;
  }
  ${FieldError} {
    flex-basis: 100%;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  display: none;
  margin: 0.3rem 0;
  z-index: 2;

  .MuiPaper-root {
    max-height: 20rem;
    overflow: auto;

    .MuiMenuItem-root {
      white-space: break-spaces;
      word-break: break-word;
    }
  }
  ${({ open }) => open && `
    display: block
  `}
`;

export const OptionWrap = styled.div`
  width: 100%;
  padding: 0;
  ${({ active }) => active && `
    .MuiMenuItem-root {
      background-color: ${AZURE};
      color: ${WHITE};

      &:hover {
        background-color: ${AZURE};
      }
    }
  `}
`;
