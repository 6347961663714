import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchProducts = createAction(
  ACTIONS.FETCH_PRODUCTS,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchProductsCSV = createAction(
  ACTIONS.FETCH_PRODUCTS_CSV,
  'successActionCallback',
  'errorActionCallback',
);

export const setImportProductsFile = createAction(
  ACTIONS.SET_IMPORT_PRODUCTS_FILE,
  'file',
  'uploadType',
  'successActionCallback',
  'errorActionCallback',
);
export const setValidateProductsFile = createAction(
  ACTIONS.SET_VALIDATE_PRODUCTS_FILE,
  'file',
  'uploadType',
  'successActionCallback',
  'errorActionCallback',
);

export const fetchEditProductsTemplate = createAction(
  ACTIONS.FETCH_EDIT_PRODUCTS_TEMPLATE,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchAddProductsTemplate = createAction(
  ACTIONS.FETCH_ADD_PRODUCTS_TEMPLATE,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchDeleteProductsTemplate = createAction(
  ACTIONS.FETCH_DELETE_PRODUCTS_TEMPLATE,
  'successActionCallback',
  'errorActionCallback',
);

export const fetchProductsUploadLogs = createAction(
  ACTIONS.FETCH_PRODUCTS_UPLOAD_LOGS,
  'successActionCallback',
  'errorActionCallback',
);
export const getDownloadUploadedFile = createAction(
  ACTIONS.GET_DOWNLOAD_UPLOADED_FILE,
  'fileKey',
  'successActionCallback',
  'errorActionCallback',
);
export const postCancelUploadRequest = createAction(
  ACTIONS.POST_CANCEL_UPLOAD_REQUEST,
  'fileKey',
  'successActionCallback',
  'errorActionCallback',
);
export const getDownloadErrorFile = createAction(
  ACTIONS.GET_DOWNLOAD_ERROR_FILE,
  'fileKey',
  'successActionCallback',
  'errorActionCallback',
);

export const getDownloadProductsLogs = createAction(
  ACTIONS.GET_DOWNLOAD_PRODUCTS_LOGS,
  'successActionCallback',
  'errorActionCallback',
);
export const postDownloadProducts = createAction(
  ACTIONS.POST_DOWNLOAD_PRODUCTS,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const getDownloadProductsFile = createAction(
  ACTIONS.GET_DOWNLOAD_PRODUCTS_FILE,
  'fileKey',
  'successActionCallback',
  'errorActionCallback',
);
export const postCancelDownloadRequest = createAction(
  ACTIONS.POST_CANCEL_DOWNLOAD_REQUEST,
  'requestId',
  'successActionCallback',
  'errorActionCallback',
);

export const getBrandsSuppliers = createAction(
  ACTIONS.GET_BRANDS_SUPPLIERS,
  'successActionCallback',
  'errorActionCallback',
);
export const getFilteredBrandsSuppliers = createAction(
  ACTIONS.GET_FILTERED_BRANDS_SUPPLIERS,
  'params',
  'successActionCallback',
  'errorActionCallback',
);

export const addBrandSupplier = createAction(
  ACTIONS.ADD_BRAND_SUPPLIER,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const updateBrandSupplier = createAction(
  ACTIONS.UPDATE_BRAND_SUPPLIER,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const deleteBrandSupplier = createAction(
  ACTIONS.DELETE_BRAND_SUPPLIER,
  'brandSupplierId',
  'successActionCallback',
  'errorActionCallback',
);
