import { all, takeEvery } from 'redux-saga/effects';
import { integrationsApiSelector } from 'app/api/selectors';
import { authRequired } from 'services/auth/sagas';
import { serviceSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';

const fetchSalesChannelsWatcher = action =>
  serviceSaga(integrationsApiSelector, 'getSalesChannels', action);

const addSalesChannelWatcher = action =>
  serviceSaga(integrationsApiSelector, 'addSalesChannel', action);

const editSalesChannelWatcher = action =>
  serviceSaga(integrationsApiSelector, 'editSalesChannel', action);

const deleteSalesChannelWatcher = action =>
  serviceSaga(integrationsApiSelector, 'deleteSalesChannel', action);

const deleteIntegrationWatcher = action =>
  serviceSaga(integrationsApiSelector, 'deleteIntegration', action);

const fetchChannelTypesWatcher = action =>
  serviceSaga(integrationsApiSelector, 'getChannelTypes', action);

const addIntegrationWatcher = action =>
  serviceSaga(integrationsApiSelector, 'addIntegration', action);

const fetchIntegrationServicesWatcher = action =>
  serviceSaga(integrationsApiSelector, 'getIntegrationServices', action);

export default function* inventoryServiceSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_SALES_CHANNELS, authRequired, fetchSalesChannelsWatcher),
    takeEvery(ACTIONS.FETCH_CHANNEL_TYPES, authRequired, fetchChannelTypesWatcher),
    takeEvery(ACTIONS.ADD_SALES_CHANNEL, authRequired, addSalesChannelWatcher),
    takeEvery(ACTIONS.EDIT_SALES_CHANNEL, authRequired, editSalesChannelWatcher),
    takeEvery(ACTIONS.DELETE_SALES_CHANNEL, authRequired, deleteSalesChannelWatcher),
    takeEvery(ACTIONS.DELETE_INTEGRATION, authRequired, deleteIntegrationWatcher),
    takeEvery(ACTIONS.ADD_INTEGRATION, authRequired, addIntegrationWatcher),
    takeEvery(ACTIONS.FETCH_INTEGRATION_SERVICES, authRequired, fetchIntegrationServicesWatcher),
  ]);
}
