/*
formatPOScannedItem OBJECT SCHEMA
"7984726:610934824674": {
  "order_id": "OW-ST-1599091608495-6",
  "order_line_id": "38bf5f08-d852-496e-9e17-9154eedaaefd",
  "scanned_in": 1,
  "consolidated_po": "7984726BB",
  "sold_out": 0,
  "purchase_number": null,
  "upc": null,
}
*/

export const PO_SCANNED_ITEM_SCHEMA = {
  UPC: 'upc',
  ORDER_ID: 'order_id',
  ORDER_LINE_ID: 'order_line_id',
  SCANNED_IN: 'scanned_in',
  CONSOLIDATED_PO: 'consolidated_po',
  ITEM_SHIPMENT_COST: 'item_shipment_cost',
  ITEM_SHIPMENT_VOLUME: 'item_shipment_volume',
};

export const SHIPMENT_SCHEMA = {
  PO: 'po',
  TOTAL_COST: 'total_cost',
  VOLUME_UNIT: 'volume_unit',
};

export const buildPOScannedItem = item => ({
  [PO_SCANNED_ITEM_SCHEMA.UPC]: item.upc || null,
  [PO_SCANNED_ITEM_SCHEMA.ORDER_ID]: item.order_id || null,
  [PO_SCANNED_ITEM_SCHEMA.ORDER_LINE_ID]: item.order_line_id || null,
  [PO_SCANNED_ITEM_SCHEMA.SCANNED_IN]: item.scanned_in || 0,
  [PO_SCANNED_ITEM_SCHEMA.CONSOLIDATED_PO]: item.purchase_order_number || null,
  [PO_SCANNED_ITEM_SCHEMA.ITEM_SHIPMENT_COST]: item.item_shipment_cost || 0,
  [PO_SCANNED_ITEM_SCHEMA.ITEM_SHIPMENT_VOLUME]: item.item_shipment_volume || null,
});

export const formatPOScannedItem = item => ({
  ...buildPOScannedItem(item),
});

export const formatShipment = shipment => ({
  [SHIPMENT_SCHEMA.PO]: shipment.poNumber,
  [SHIPMENT_SCHEMA.TOTAL_COST]: shipment.shipmentTotalCost,
  [SHIPMENT_SCHEMA.VOLUME_UNIT]: shipment.shipmentVolumeUnit,
});
