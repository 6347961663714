export const ACTIONS = {
  FETCH_ZONE: 'SERVICE_INVENTORY__FETCH_ZONE',
  FETCH_HAS_LOCK: 'SERVICE_INVENTORY__FETCH_HAS_LOCK',
  FETCH_SEARCH_ITEM: 'SERVICE_INVENTORY__FETCH_SEARCH_ITEM',
  FETCH_SHIPPING_ZONE_NAME: 'SERVICE_INVENTORY__FETCH_SHIPPING_ZONE_NAME',
  FETCH_SHIPPING_ZONE_NAME_SUCCESS: 'SERVICE_INVENTORY__FETCH_SHIPPING_ZONE_NAME_SUCCESS',
  FETCH_SHIPPING_ZONE_NAME_ERROR: 'SERVICE_INVENTORY__FETCH_SHIPPING_ZONE_NAME_ERROR',
  SET_ZONE_LOCK: 'SERVICE_INVENTORY__SET_ZONE_LOCK',
  SET_ZONE_UNLOCK: 'SERVICE_INVENTORY__SET_ZONE_UNLOCK',
  SET_REPLACE_QTY: 'SERVICE_INVENTORY__SET_REPLACE_QTY',
  SET_MOVE_ITEMS: 'SERVICE_INVENTORY__SET_MOVE_ITEMS',
  SET_MOVE_REPLACE_DELTA_QTY: 'SERVICE_INVENTORY__SET_MOVE_REPLACE_DELTA_QTY',
  SET_MOVE_TO_SHIPPING: 'SERVICE_INVENTORY__SET_MOVE_TO_SHIPPING',
  SET_MOVE_FROM_SHIPPING: 'SERVICE_INVENTORY__SET_MOVE_FROM_SHIPPING',
  RELEASE_ZONE_LOCK: 'SERVICE_INVENTORY__RELEASE_ZONE_LOCK',
  FETCH_DECREMENT_QTY: 'SERVICE_INVENTORY__FETCH_DECREMENT_QTY',
  FETCH_GET_ZONE_AVAILABILITY: 'SERVICE_INVENTORY__FETCH_GET_ZONE_AVAILABILITY',
};

export const INVENTORY_ERRORS = {
  ZONE_LOCKED_BY_ANOTHER_USER: (zoneName, lockOwner) =>
    `Sorry! Zone ${zoneName} is locked by ${lockOwner}.`,
  INVALID_API_RESPONSE_RECEIVED: (ref = 'no ref') => `Invalid API response received at ${ref}`,
  FETCHING_LOCK: 'Sorry! Something went wrong fetching lock status',
  ACQUIRING_LOCK: 'Sorry! Something went wrong acquiring the lock',
  RELEASING_LOCK: 'Sorry! Something went wrong releasing the lock',
};

export const MOVE_ITEMS_REF = {
  RENAMING_ZONE: 'renaming_zone',
};
