import { all, takeEvery } from 'redux-saga/effects';
import { productManagementApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const fetchProductsWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getProducts', action);

const fetchProductsCSVWatcher = action =>
  serviceSaga(productManagementApiSelector, 'productsToCSV', action);

const setImportProductsFileWatcher = action =>
  serviceSaga(productManagementApiSelector, 'importProductsFile', action);

const setValidateProductsFileWatcher = action =>
  serviceSaga(productManagementApiSelector, 'validateProductsFile', action);

const fetchEditProductsTemplateWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getEditProductsTemplate', action);

const fetchAddProductsTemplateWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getAddProductsTemplate', action);

const fetchDeleteProductsTemplateWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getDeleteProductsTemplate', action);

const fetchProductsUploadLogsWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getUploadProductsLogs', action);

const getDownloadUploadedFileWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getDownloadUploadedFile', action);

const postCancelUploadRequestWatcher = action =>
  serviceSaga(productManagementApiSelector, 'postCancelUploadRequest', action);

const getDownloadErrorFileWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getDownloadErrorFile', action);

const getDownloadProductsLogsWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getDownloadProductsLogs', action);

const postDownloadProductsWatcher = action =>
  serviceSaga(productManagementApiSelector, 'postDownloadProducts', action);

const getDownloadProductsFileWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getDownloadProductsFile', action);

const postCancelDownloadRequestWatcher = action =>
  serviceSaga(productManagementApiSelector, 'postCancelDownloadRequest', action);

const getBrandsSuppliersWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getBrandsSuppliers', action);

const getFilteredBrandsSuppliersWatcher = action =>
  serviceSaga(productManagementApiSelector, 'getFilteredBrandsSuppliers', action);

const addBrandSupplierWatcher = action =>
  serviceSaga(productManagementApiSelector, 'addBrandSupplier', action);

const updateBrandSupplierWatcher = action =>
  serviceSaga(productManagementApiSelector, 'updateBrandSupplier', action);

const deleteBrandSupplierWatcher = action =>
  serviceSaga(productManagementApiSelector, 'deleteBrandSupplier', action);

export default function* productManagementSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_PRODUCTS, authRequired, fetchProductsWatcher),
    takeEvery(ACTIONS.FETCH_PRODUCTS_CSV, authRequired, fetchProductsCSVWatcher),
    takeEvery(ACTIONS.SET_IMPORT_PRODUCTS_FILE, authRequired, setImportProductsFileWatcher),
    takeEvery(ACTIONS.SET_VALIDATE_PRODUCTS_FILE, authRequired, setValidateProductsFileWatcher),
    takeEvery(ACTIONS.FETCH_EDIT_PRODUCTS_TEMPLATE, authRequired, fetchEditProductsTemplateWatcher),
    takeEvery(ACTIONS.FETCH_ADD_PRODUCTS_TEMPLATE, authRequired, fetchAddProductsTemplateWatcher),
    takeEvery(
      ACTIONS.FETCH_DELETE_PRODUCTS_TEMPLATE,
      authRequired,
      fetchDeleteProductsTemplateWatcher,
    ),
    takeEvery(ACTIONS.FETCH_PRODUCTS_UPLOAD_LOGS, authRequired, fetchProductsUploadLogsWatcher),
    takeEvery(ACTIONS.GET_DOWNLOAD_UPLOADED_FILE, authRequired, getDownloadUploadedFileWatcher),
    takeEvery(ACTIONS.POST_CANCEL_UPLOAD_REQUEST, authRequired, postCancelUploadRequestWatcher),
    takeEvery(ACTIONS.GET_DOWNLOAD_ERROR_FILE, authRequired, getDownloadErrorFileWatcher),
    takeEvery(ACTIONS.GET_DOWNLOAD_PRODUCTS_LOGS, authRequired, getDownloadProductsLogsWatcher),
    takeEvery(ACTIONS.POST_DOWNLOAD_PRODUCTS, authRequired, postDownloadProductsWatcher),
    takeEvery(ACTIONS.GET_DOWNLOAD_PRODUCTS_FILE, authRequired, getDownloadProductsFileWatcher),
    takeEvery(ACTIONS.POST_CANCEL_DOWNLOAD_REQUEST, authRequired, postCancelDownloadRequestWatcher),
    takeEvery(ACTIONS.GET_BRANDS_SUPPLIERS, authRequired, getBrandsSuppliersWatcher),
    takeEvery(
      ACTIONS.GET_FILTERED_BRANDS_SUPPLIERS,
      authRequired,
      getFilteredBrandsSuppliersWatcher,
    ),
    takeEvery(ACTIONS.ADD_BRAND_SUPPLIER, authRequired, addBrandSupplierWatcher),
    takeEvery(ACTIONS.UPDATE_BRAND_SUPPLIER, authRequired, updateBrandSupplierWatcher),
    takeEvery(ACTIONS.DELETE_BRAND_SUPPLIER, authRequired, deleteBrandSupplierWatcher),
  ]);
}
