import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const downloadTemplate = createAction(ACTIONS.DOWNLOAD_TEMPLATE);
export const downloadTemplateSuccess = createAction(ACTIONS.DOWNLOAD_TEMPLATE_SUCCESS, 'payload');
export const downloadTemplateError = createAction(
  ACTIONS.DOWNLOAD_TEMPLATE_ERROR,
  'errorMessage',
  'error',
);

export const validateUploadFile = createAction(ACTIONS.VALIDATE_UPLOAD_FILE, 'file');
export const validateUploadFileSuccess = createAction(
  ACTIONS.VALIDATE_UPLOAD_FILE_SUCCESS,
  'payload',
);
export const validateUploadFileError = createAction(
  ACTIONS.VALIDATE_UPLOAD_FILE_ERROR,
  'errorMessage',
  'error',
);
export const setUploadFileIsValid = createAction(
  ACTIONS.SET_UPLOAD_FILE_IS_VALID,
  'isUploadFileValid',
);
export const setUploadFileValidationErrors = createAction(
  ACTIONS.SET_UPLOAD_FILE_VALIDATION_ERRORS,
  'uploadFileErrors',
);

export const uploadFile = createAction(ACTIONS.UPLOAD_FILE, 'file');
export const uploadFileSuccess = createAction(ACTIONS.UPLOAD_FILE_SUCCESS, 'payload');
export const uploadFileError = createAction(ACTIONS.UPLOAD_FILE_ERROR, 'errorMessage', 'error');

export const resetPurchaseOrderCreatorState = createAction(ACTIONS.RESET_STATE);
