import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadBrandSuppliers = createAction(ACTIONS.LOAD_BRANDS_SUPPLIERS);
export const loadBrandsSuppliersSuccess = createAction(
  ACTIONS.LOAD_BRANDS_SUPPLIERS_SUCCESS,
  'payload',
);
export const loadBrandsSuppliersError = createAction(
  ACTIONS.LOAD_BRANDS_SUPPLIERS_ERROR,
  'errorMessage',
  'error',
);
export const loadFeed = createAction(ACTIONS.LOAD_FEED);

export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const refreshFeed = createAction(ACTIONS.REFRESH_FEED);
export const setFilterOperand = createAction(ACTIONS.SET_FILTER_OPERAND, 'operand');
export const unsetFilterOperand = createAction(
  ACTIONS.UNSET_FILTER_OPERAND,
  'groupIndex',
  'filterIndex',
);
export const clearFilters = createAction(ACTIONS.CLEAR_FILTERS);
export const setFilters = createAction(ACTIONS.SET_FILTERS, 'filters');
export const arrangeFilters = createAction(ACTIONS.ARRANGE_FILTERS, 'destination', 'source');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(
  ACTIONS.EXPORT_TO_FILE_ERROR,
  'errorMessage',
  'error',
);

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnsOrder = createAction(ACTIONS.SET_COLUMNS_ORDER, 'columnsOrder');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');
export const setItemsPerPage = createAction(ACTIONS.SET_ITEMS_PER_PAGE, 'itemsPerPage');
export const sortFeed = createAction(ACTIONS.SORT_FEED, 'columnId', 'direction');
export const goToPage = createAction(ACTIONS.GO_TO_PAGE, 'lastPage');

export const showManagementModal = createAction(ACTIONS.SHOW_BRAND_SUPPLIER_MODAL, 'showModal');
export const showDeleteModal = createAction(
  ACTIONS.SHOW_DELETE_BRAND_SUPPLIER_MODAL,
  'showDeleteModal',
);

export const setSelectedItem = createAction(ACTIONS.SET_SELECTED_ITEM, 'selectedItem');

export const removeBrandSupplier = createAction(ACTIONS.REMOVE_BRAND_SUPPLIER, 'brandSupplierId');
export const removeBrandSupplierSuccess = createAction(
  ACTIONS.REMOVE_BRAND_SUPPLIER_SUCCESS,
  'payload',
);
export const removeBrandSupplierError = createAction(
  ACTIONS.REMOVE_BRAND_SUPPLIER_ERROR,
  'errorMessage',
  'error',
);

export const createBrandSupplier = createAction(ACTIONS.CREATE_BRAND_SUPPLIER, 'params');
export const createBrandSupplierSuccess = createAction(
  ACTIONS.CREATE_BRAND_SUPPLIER_SUCCESS,
  'payload',
);
export const createBrandSupplierError = createAction(
  ACTIONS.CREATE_BRAND_SUPPLIER_ERROR,
  'errorMessage',
  'error',
);

export const editBrandSupplier = createAction(ACTIONS.EDIT_BRAND_SUPPLIER, 'params');
export const editBrandSupplierSuccess = createAction(
  ACTIONS.EDIT_BRAND_SUPPLIER_SUCCESS,
  'payload',
);
export const editBrandSupplierError = createAction(
  ACTIONS.EDIT_BRAND_SUPPLIER_ERROR,
  'errorMessage',
  'error',
);

export const resetBrandSupplierManagementState = createAction(
  ACTIONS.RESET_BRAND_SUPPLIER_MANAGEMENT_STATE,
);

// Brands
export const fetchBrands = createAction(ACTIONS.FETCH_BRANDS);
export const fetchBrandsSuccess = createAction(ACTIONS.FETCH_BRANDS_SUCCESS, 'payload');
export const fetchBrandsError = createAction(ACTIONS.FETCH_BRANDS_ERROR, 'errorMessage', 'error');

// Suppliers
export const fetchSuppliers = createAction(ACTIONS.FETCH_SUPPLIERS);
export const fetchSuppliersSuccess = createAction(ACTIONS.FETCH_SUPPLIERS_SUCCESS, 'payload');
export const fetchSuppliersError = createAction(
  ACTIONS.FETCH_SUPPLIERS_ERROR,
  'errorMessage',
  'error',
);

// Brand Management
export const createBrand = createAction(ACTIONS.CREATE_BRAND, 'formValues');
export const createBrandSuccess = createAction(ACTIONS.CREATE_BRAND_SUCCESS, 'payload');
export const createBrandError = createAction(ACTIONS.CREATE_BRAND_ERROR, 'errorMessage', 'error');
export const removeBrand = createAction(ACTIONS.REMOVE_BRAND, 'brandId');
export const removeBrandSuccess = createAction(ACTIONS.REMOVE_BRAND_SUCCESS, 'payload');
export const removeBrandError = createAction(ACTIONS.REMOVE_BRAND_ERROR, 'errorMessage', 'error');
export const editBrand = createAction(ACTIONS.EDIT_BRAND, 'formValues');
export const editBrandSuccess = createAction(ACTIONS.EDIT_BRAND_SUCCESS, 'payload');
export const editBrandError = createAction(ACTIONS.EDIT_BRAND_ERROR, 'errorMessage', 'error');
export const setSelectedBrand = createAction(ACTIONS.SET_SELECTED_BRAND, 'selectedItem');
export const showDeleteBrandModal = createAction(
  ACTIONS.SHOW_DELETE_BRAND_MODAL,
  'showDeleteModal',
);
export const showBrandManagementModal = createAction(
  ACTIONS.SHOW_BRAND_MANAGEMENT_MODAL,
  'showManagementModal',
);
export const resetBrandManagementState = createAction(ACTIONS.RESET_BRAND_MANAGEMENT_STATE);

// Supplier Management
export const createSupplier = createAction(ACTIONS.CREATE_SUPPLIER, 'formValues');
export const createSupplierSuccess = createAction(ACTIONS.CREATE_SUPPLIER_SUCCESS, 'payload');
export const createSupplierError = createAction(
  ACTIONS.CREATE_SUPPLIER_ERROR,
  'errorMessage',
  'error',
);
export const removeSupplier = createAction(ACTIONS.REMOVE_SUPPLIER, 'supplierId');
export const removeSupplierSuccess = createAction(ACTIONS.REMOVE_SUPPLIER_SUCCESS, 'payload');
export const removeSupplierError = createAction(
  ACTIONS.REMOVE_SUPPLIER_ERROR,
  'errorMessage',
  'error',
);
export const editSupplier = createAction(ACTIONS.EDIT_SUPPLIER, 'formValues');
export const editSupplierSuccess = createAction(ACTIONS.EDIT_SUPPLIER_SUCCESS, 'payload');
export const editSupplierError = createAction(ACTIONS.EDIT_SUPPLIER_ERROR, 'errorMessage', 'error');
export const setSelectedSupplier = createAction(ACTIONS.SET_SELECTED_SUPPLIER, 'selectedItem');
export const showDeleteSupplierModal = createAction(
  ACTIONS.SHOW_DELETE_SUPPLIER_MODAL,
  'showDeleteModal',
);
export const showSupplierManagementModal = createAction(
  ACTIONS.SHOW_SUPPLIER_MANAGEMENT_MODAL,
  'showManagementModal',
);
export const resetSupplierManagementState = createAction(ACTIONS.RESET_SUPPLIER_MANAGEMENT_STATE);
