import { createAction } from 'redux-create-action';
import { ZONE_A_ACTIONS as ACTIONS } from '../constants';

export const setFieldZoneName = createAction(ACTIONS.SET_FIELD_ZONE_NAME, 'fieldZoneName');
export const setFieldUPCNumber = createAction(ACTIONS.SET_FIELD_UPC_NUMBER, 'fieldUPCNumber');

export const scanZoneName = createAction(ACTIONS.SCAN_ZONE_NAME, 'zoneName');
export const scanZoneNameSuccess = createAction(ACTIONS.SCAN_ZONE_NAME_SUCCESS, 'payload');
export const scanZoneNameError = createAction(
  ACTIONS.SCAN_ZONE_NAME_ERROR,
  'errorMessage',
  'error',
);
export const setChangedItems = createAction(ACTIONS.SET_ZONE_CHANGED_ITEMS, 'list');

export const scanUPC = createAction(ACTIONS.SCAN_UPC, 'upc');
export const scanUPCSuccess = createAction(ACTIONS.SCAN_UPC_SUCCESS, 'payload');
export const scanUPCError = createAction(ACTIONS.SCAN_UPC_ERROR, 'errorMessage', 'error');

export const toggleZoneLock = createAction(ACTIONS.TOGGLE_ZONE_LOCK);
export const lockZone = createAction(ACTIONS.LOCK_ZONE);
export const lockZoneSuccess = createAction(ACTIONS.LOCK_ZONE_SUCCESS, 'payload');
export const lockZoneError = createAction(ACTIONS.LOCK_ZONE_ERROR, 'errorMessage', 'error');
export const unlockZone = createAction(ACTIONS.UNLOCK_ZONE);
export const unlockZoneSuccess = createAction(ACTIONS.UNLOCK_ZONE_SUCCESS, 'payload');
export const unlockZoneError = createAction(ACTIONS.UNLOCK_ZONE_ERROR, 'errorMessage', 'error');
export const reloadZoneLock = createAction(ACTIONS.RELOAD_ZONE_LOCK);
export const reloadZoneLockSuccess = createAction(ACTIONS.RELOAD_ZONE_LOCK_SUCCESS, 'payload');
export const reloadZoneLockError = createAction(
  ACTIONS.RELOAD_ZONE_LOCK_ERROR,
  'errorMessage',
  'error',
);

export const toggleSelectItem = createAction(ACTIONS.TOGGLE_SELECT_ITEM, 'upc', 'isSelected');
export const toggleSelectAllItems = createAction(ACTIONS.TOGGLE_SELECT_ALL_ITEMS, 'selectAll');
export const updateSelectedItems = createAction(ACTIONS.UPDATE_SELECTED_ITEMS, 'list');
export const setSelectedItems = createAction(ACTIONS.SET_SELECTED_ITEMS, 'selectedItems');

export const moveSelectedItems = createAction(ACTIONS.MOVE_SELECTED_ITEMS);
export const moveSelectedItemsSuccess = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS,
  'payload',
);
export const moveSelectedItemsError = createAction(
  ACTIONS.MOVE_SELECTED_ITEMS_ERROR,
  'errorMessage',
  'error',
);

export const reloadZone = createAction(ACTIONS.RELOAD_ZONE);
