export const VIEW_COLUMNS = {
  ADDRESS: {
    id: 'address',
    label: 'Address',
  },
  BRAND: {
    id: 'brand',
    label: 'Brand',
  },
  COST: {
    id: 'cost',
    label: 'Cost',
  },
  EDI: {
    id: 'edi',
    label: 'EDI',
  },
  FULFILLMENT_WAREHOUSE_ABBR: {
    id: 'fulfillment_warehouse_abbr',
    label: 'Fulfillment Warehouse',
  },
  LINE_TYPE: {
    id: 'line_type',
    label: 'Type',
  },
  MARKUP: {
    id: 'markup',
    label: 'Markup',
  },
  NOTES: {
    id: 'notes',
    label: 'Notes',
  },
  ORDER_ID: {
    id: 'order_id',
    label: 'Order ID',
  },
  ORDER_LINE_STATUS: {
    id: 'order_line_status',
    label: 'Status',
  },
  PRICE: {
    id: 'price',
    label: 'Line Total',
    tooltip: 'Lorem ipsum',
  },
  PROMISE_DATE: {
    id: 'promise_date',
    label: 'Ship By',
  },
  PURCHASE_DATE: {
    id: 'purchase_date',
    label: 'Purchase Date',
  },
  PURCHASE_ORDER_NUMBER: {
    id: 'purchase_order_number',
    label: 'PO #',
  },
  QTY_ORDERED: {
    id: 'qty_ordered',
    label: 'Qty',
  },
  SALES_CHANNEL: {
    id: 'sales_channel',
    label: 'Sales Channel',
  },
  SALES_ORDER_NUMBER: {
    id: 'sales_order_number',
    label: 'SO #',
  },
  SHIPPING_PRIORITY: {
    id: 'shipping_priority',
    label: 'Shipping Priority',
  },
  SKU: {
    id: 'sku',
    label: 'SKU',
  },
  SUPPLIER: {
    id: 'supplier',
    label: 'Supplier',
  },
  SHIPMENT_LINES: {
    id: 'shipment_lines',
    label: 'Shipments',
  },
  UPC: {
    id: 'upc',
    label: 'UPC',
  },
};