import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getOrderDetails, postOrderNote } from 'services/orders/actions';
import { ACTIONS } from '../constants';
import {
  loadOrderDetailsSuccess,
  loadOrderDetailsError,
  addOrderNoteSuccess,
  addOrderNoteError,
  loadOrderDetails,
} from '../actions';
import { selectOrderDetailsData } from '../selectors';

function* loadOrderDetailsWatcher({ salesOrderNumber }) {
  yield put(getOrderDetails(salesOrderNumber, loadOrderDetailsSuccess, loadOrderDetailsError));
}

function* loadOrderDetailsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* addOrderNoteWatcher({ note }) {
  const data = yield select(selectOrderDetailsData);
  const salesOrderNumber = data.sales_order_number;
  yield put(postOrderNote(salesOrderNumber, note, addOrderNoteSuccess, addOrderNoteError));
}

function* addOrderNoteSuccessWatcher() {
  const data = yield select(selectOrderDetailsData);
  const salesOrderNumber = data.sales_order_number;

  if (salesOrderNumber) {
    yield put(loadOrderDetails(salesOrderNumber));
  }

  yield call([toast, toast.success], 'New order note added successfully.');
}

export default function* orderDetailsSagas() {
  yield all([
    takeEvery(ACTIONS.LOAD_ORDER_DETAILS, loadOrderDetailsWatcher),
    takeEvery(ACTIONS.LOAD_ORDER_DETAILS_ERROR, loadOrderDetailsErrorWatcher),
    takeEvery(ACTIONS.ADD_ORDER_NOTE, addOrderNoteWatcher),
    takeEvery(ACTIONS.ADD_ORDER_NOTE_SUCCESS, addOrderNoteSuccessWatcher),
  ]);
}
