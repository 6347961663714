import { all, takeEvery, put } from 'redux-saga/effects';
import { ACTIONS } from '../constants';
import { generateOrderId } from '../actions';

function* setSalesChannelWatcher() {
  yield put(generateOrderId());
}

export default function* orderTypeSagas() {
  yield all([takeEvery(ACTIONS.SET_SALES_CHANNEL, setSalesChannelWatcher)]);
}
