import { formatSortByToObject, formatFilterToObject } from '../helpers';

const ENDPOINTS = {
  IMPORT: '/import',
  VALIDATE: '/validate',
  IMPORT_TEMPLATE: '/import_template.csv',
  GET_PURCHASE_ORDERS: '/list',
  REMOVE_PURCHASE_ORDER: '/remove_purchase_order',
  UPDATE_PURCHASE_ORDER: '/update_purchase_order',
  SEARCH: '/search',
  CHECKIN: '/checkin',
};

export default ENDPOINTS;

export const PURCHASE_ORDERS_DEFAULT_PARAMS = {
  itemsPerPage: 25,
  days: '180',
  sortBy: formatSortByToObject('brand', 'asc'),
  filter: formatFilterToObject(),
  page: 1,
};
