import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchGeneratePickTicket } from 'services/orders/actions';
import { ACTIONS } from './constants';
import { getSalesOrderNumbers } from './selectors';
import { generateTicketsSuccess, generateTicketsError, setShowPickTicketsPopup } from './actions';

function* generateTicketsWatcher() {
  const salesOrderNumbers = yield select(getSalesOrderNumbers);
  const soArray = salesOrderNumbers.split('\n');

  yield put(fetchGeneratePickTicket(soArray, generateTicketsSuccess, generateTicketsError));
}

function* generateTicketsSuccessWatcher() {
  yield put(setShowPickTicketsPopup(true));
}

function* generateTicketsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* pickTicketGeneratorSagas() {
  yield all([
    takeEvery(ACTIONS.GENERATE_TICKETS, generateTicketsWatcher),
    takeEvery(ACTIONS.GENERATE_TICKETS_SUCCESS, generateTicketsSuccessWatcher),
    takeEvery(ACTIONS.GENERATE_TICKETS_ERROR, generateTicketsErrorWatcher),
  ]);
}
