import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import ROUTES from 'constants/routes';
import { getArrangedFilters } from 'features/helpers';
import { formatSortByToObject, formatFilterToObject } from 'app/api/helpers';
import { hashFiltersToURL } from 'features/sagaHelpers';
import { fetchProducts } from 'services/productManagement/actions';
import { errorToasterSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import {
  loadFeed,
  loadProducts,
  loadProductsError,
  loadProductsSuccess,
  setFilters,
} from '../actions';
import { getSettingsItemsPerPage, getSettingsSorting, getLastPage, getFilters } from '../selectors';

function* requestFeed(otherParams = {}) {
  const itemsPerPage = yield select(getSettingsItemsPerPage);
  const { columnId, direction } = yield select(getSettingsSorting);
  const page = yield select(getLastPage);
  const sortBy = yield call(formatSortByToObject, columnId, direction);
  const filters = yield select(getFilters);
  const filter = yield call(formatFilterToObject, filters);

  yield call(hashFiltersToURL, filters, ROUTES.PRODUCT_MANAGEMENT.PRODUCTS);

  const params = {
    itemsPerPage,
    page,
    sortBy,
    filter,
    ...otherParams,
  };

  yield put(loadFeed());
  yield put(fetchProducts(params, loadProductsSuccess, loadProductsError));
}

export function* loadProductsWatcher() {
  yield call(requestFeed);
}

function* arrangeFiltersWatcher({ destination, source }) {
  const filters = yield select(getFilters);
  const updatedFilters = yield call(getArrangedFilters, destination, source, filters);
  yield put(setFilters(updatedFilters));
  if (JSON.stringify(filters) !== JSON.stringify(updatedFilters)) {
    yield put(loadProducts());
  }
}

export default function* loadProductsSagas() {
  yield all([
    takeEvery(
      [
        ACTIONS.LOAD_PRODUCTS,
        ACTIONS.REFRESH_FEED,
        ACTIONS.SORT_FEED,
        ACTIONS.SET_FILTER_OPERAND,
        ACTIONS.UNSET_FILTER_OPERAND,
        ACTIONS.CLEAR_FILTERS,
        ACTIONS.GO_TO_PAGE,
        ACTIONS.SET_ITEMS_PER_PAGE,
      ],
      loadProductsWatcher,
    ),
    takeEvery(ACTIONS.LOAD_PRODUCTS_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.ARRANGE_FILTERS, arrangeFiltersWatcher),
  ]);
}
