import { all, fork } from 'redux-saga/effects';
import containerSagas from './containerSagas';
import searchOrdersSagas from './searchOrdersSagas';
import loadOrderLinesSagas from './loadOrderLinesSagas';
import exportToFileSagas from './exportToFileSagas';
import orderLineManagementSagas from './orderLineManagementSagas';
import createOrderFromPreviousSagas from './createOrderFromPreviousSagas';
import orderDetailsSagas from './orderDetailsSagas';
import orderLinesSagas from './orderLinesSagas';
import orderLineNoteSagas from './orderLineNoteSagas';

export default function* orderLineViewSagas(...args) {
  yield all([
    fork(containerSagas, ...args),
    fork(loadOrderLinesSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(orderLineManagementSagas, ...args),
    fork(searchOrdersSagas, ...args),
    fork(createOrderFromPreviousSagas, ...args),
    fork(orderDetailsSagas, ...args),
    fork(orderLinesSagas, ...args),
    fork(orderLineNoteSagas, ...args),
  ]);
}
