import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { utilitiesApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

export function* setImportInventoryLabelGeneratorFileWatcher({
  file,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(utilitiesApiSelector);
  try {
    const response = yield call([api, api.importInventoryLabelGeneratorFile], file);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchInventoryLabelGeneratorSampleWatcher({
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(utilitiesApiSelector);

  try {
    const response = yield call([api, api.getInventoryLabelGeneratorSample]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export function* setImportUPCLabelGeneratorFileWatcher({
  file,
  options,
  successActionCallback,
  errorActionCallback,
}) {
  const api = yield select(utilitiesApiSelector);
  try {
    const response = yield call([api, api.importUPCLabelGeneratorFile], file, options);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchUPCLabelGeneratorSampleWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(utilitiesApiSelector);

  try {
    const response = yield call([api, api.getUPCLabelGeneratorSample]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export default function* utilitiesSagas() {
  yield all([
    takeEvery(
      ACTIONS.SET_IMPORT_INVENTORY_LABEL_GENERATOR_FILE,
      authRequired,
      setImportInventoryLabelGeneratorFileWatcher,
    ),
    takeEvery(
      ACTIONS.FETCH_INVENTORY_LABEL_GENERATOR_SAMPLE,
      authRequired,
      fetchInventoryLabelGeneratorSampleWatcher,
    ),
    takeEvery(
      ACTIONS.SET_IMPORT_UPC_LABEL_GENERATOR_FILE,
      authRequired,
      setImportUPCLabelGeneratorFileWatcher,
    ),
    takeEvery(
      ACTIONS.FETCH_UPC_LABEL_GENERATOR_SAMPLE,
      authRequired,
      fetchUPCLabelGeneratorSampleWatcher,
    ),
  ]);
}
