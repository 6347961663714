import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { formatSortByToObject, formatFilterToObject } from 'app/api/helpers';
import { addFilter } from 'features/helpers';
import { fetchPurchaseOrdersCSV } from 'services/purchaseOrders/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import {
  exportToFileSuccess,
  exportToFileError,
  downloadLineSuccess,
  downloadLineError,
} from '../actions';

import { getSettingsDays, getSettingsSorting, getFilters } from '../selectors';

const EXPORT_FILE_NAME = 'purchase_orders.csv';
const DOWNLOAD_LINE_FILE_NAME = 'purchase_order.csv';

function* exportToFileWatcher() {
  const days = yield select(getSettingsDays);
  const { columnId, direction } = yield select(getSettingsSorting);
  const sortBy = yield call(formatSortByToObject, columnId, direction);
  const filters = yield select(getFilters);
  const filter = yield call(formatFilterToObject, filters);
  const params = {
    days,
    sortBy,
    filter,
  };
  yield put(fetchPurchaseOrdersCSV(params, exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* exportToFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* downloadLineWatcher({ item }) {
  const filters = addFilter(
    {
      column: 'po',
      condition: '=',
      value: item.po,
    },
    [],
  );
  const filter = yield call(formatFilterToObject, filters);
  const params = {
    filter,
  };
  yield put(fetchPurchaseOrdersCSV(params, downloadLineSuccess, downloadLineError));
}

function* downloadLineSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: DOWNLOAD_LINE_FILE_NAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* downloadLineErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, exportToFileErrorWatcher),

    takeEvery(ACTIONS.DOWNLOAD_LINE, downloadLineWatcher),
    takeEvery(ACTIONS.DOWNLOAD_LINE_SUCCESS, downloadLineSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_LINE_ERROR, downloadLineErrorWatcher),
  ]);
}
