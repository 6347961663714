import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchSalesChannels = createAction(
  ACTIONS.FETCH_SALES_CHANNELS,
  'successActionCallback',
  'errorActionCallback',
);
export const fetchChannelTypes = createAction(
  ACTIONS.FETCH_CHANNEL_TYPES,
  'successActionCallback',
  'errorActionCallback',
);
export const addSalesChannel = createAction(
  ACTIONS.ADD_SALES_CHANNEL,
  'channelData',
  'successActionCallback',
  'errorActionCallback',
);
export const editSalesChannel = createAction(
  ACTIONS.EDIT_SALES_CHANNEL,
  'channelId',
  'channelData',
  'successActionCallback',
  'errorActionCallback',
);
export const deleteSalesChannel = createAction(
  ACTIONS.DELETE_SALES_CHANNEL,
  'channelId',
  'successActionCallback',
  'errorActionCallback',
);
export const deleteIntegration = createAction(
  ACTIONS.DELETE_INTEGRATION,
  'lookupId',
  'successActionCallback',
  'errorActionCallback',
);
export const addIntegration = createAction(
  ACTIONS.ADD_INTEGRATION,
  'channelData',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchIntegrationServices = createAction(
  ACTIONS.FETCH_INTEGRATION_SERVICES,
  'successActionCallback',
  'errorActionCallback',
);
