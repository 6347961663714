export const ACTIONS = {
  FETCH_SALES_CHANNELS: 'SERVICE_INTEGRATIONS__FETCH_SALES_CHANNELS',
  FETCH_CHANNEL_TYPES: 'SERVICE_INTEGRATIONS__FETCH_CHANNEL_TYPES',

  ADD_SALES_CHANNEL: 'SERVICE_INTEGRATIONS__ADD_SALES_CHANNEL',
  EDIT_SALES_CHANNEL: 'SERVICE_INTEGRATIONS__EDIT_SALES_CHANNEL',
  DELETE_SALES_CHANNEL: 'SERVICE_INTEGRATIONS__DELETE_SALES_CHANNEL',

  DELETE_INTEGRATION: 'SERVICE_INTEGRATIONS__DELETE_INTEGRATION',
  ADD_INTEGRATION: 'SERVICE_INTEGRATIONS__ADD_INTEGRATION',

  FETCH_INTEGRATION_SERVICES: 'SERVICE_INTEGRATIONS__FETCH_INTEGRATION_SERVICES',
};
