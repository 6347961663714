import styled, { css } from 'styled-components';
import { TUNDORA_DARK_20, WHITE } from 'constants/colors';
import {
  colorTypes,
  disabledStyle,
  defaultColor,
  buttonColors as circleButtonColors,
  buttonSizes as circleButtonSizes,
} from 'components/Button/styles';

export const spinnerSizes = {
  small: '0.8rem',
  medium: '1.1rem',
  large: '1.4rem',
};

export const SpinnerWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  > svg {
    ${({ size }) =>
      size &&
      `
      width: ${spinnerSizes[size]};
      height: ${spinnerSizes[size]};
    `}
    > circle {
      stroke: ${({ color }) =>
        color === circleButtonColors.light || color === circleButtonColors.ghost
          ? TUNDORA_DARK_20
          : WHITE};
    }
  }
`;

const sizeMixin = size => css`
  max-height: ${size};
  max-width: ${size};
  min-height: ${size};
  min-width: ${size};
`;

// sizes
const sizeSmall = css`
  ${sizeMixin('1.6875rem')}
  > span {
    font-size: 1rem;
  }
`;
const sizeMedium = css`
  ${sizeMixin('2.1875rem')}
  > span {
    font-size: 1.2rem;
  }
`;
const sizeLarge = css`
  ${sizeMixin('3.125rem')}
  > span {
    font-size: 1.8rem;
  }
`;

export const sizeTypes = {
  [circleButtonSizes.small]: sizeSmall,
  [circleButtonSizes.medium]: sizeMedium,
  [circleButtonSizes.large]: sizeLarge,
};

export const CircleButtonWrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${({ block }) => (block ? '8px' : '50%')};
  box-sizing: border-box;
  font-weight: bold;
  margin: 0.5rem;
  padding: 0;
  transition: background-color 0.1s ease-in-out;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${defaultColor}
  ${sizeMedium}
  ${({ color }) => color && colorTypes[color]}
  ${({ size }) => size && sizeTypes[size]}
  ${disabledStyle}
`;

export { circleButtonColors, circleButtonSizes };
