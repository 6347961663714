import { put, all, takeLeading, takeEvery, call, select, take, race } from 'redux-saga/effects';
import _get from 'lodash/get';
import { authApiSelector } from 'app/api/selectors';
import { clearSession } from 'app/session/actions';
import { getSessionUserName } from 'app/session/selectors';
import { firebaseSignOff } from 'services/firebase/actions';
import { getErrorMessage } from 'utils/error';
import { ACTIONS, AUTH_ERRORS, AUTH_FLAGS } from './constants';
import {
  fetchAuthTest,
  fetchAuthTestSuccess,
  fetchAuthTestError,
  setLoginSuccess,
  setLoginError,
  setLogoutSuccess,
  setLogoutError,
} from './actions';

function* fetchAuthTestWatcher({ username, ref, successActionCallback, errorActionCallback }) {
  const sessionUserName = yield select(getSessionUserName);
  const authUserName = username || sessionUserName;
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.authTest], authUserName, ref);
    const responseData = _get(response, 'auth[0]', {});

    if (!responseData || !responseData.authorized) {
      yield put(clearSession());
      yield put(firebaseSignOff());
      throw new Error(AUTH_ERRORS.NOT_AUTHORIZED);
    }

    yield put(fetchAuthTestSuccess(responseData));

    if (successActionCallback) {
      yield put(successActionCallback(responseData));
    }

    return responseData;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put(fetchAuthTestError(errorMessage, error));

    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export function* authRequired(callback, action) {
  yield put(fetchAuthTest());

  const { success } = yield race({
    success: take(ACTIONS.FETCH_AUTH_TEST_SUCCESS),
    fail: take(ACTIONS.FETCH_AUTH_TEST_ERROR),
  });

  if (success) {
    yield call(callback, action);
  }
}

function* setLoginWatcher({ username, password, successActionCallback, errorActionCallback }) {
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.login], username, password);
    const result = response.data ? response.data : response;
    const formattedResult = {
      username,
      ...result,
    };

    if (result.error && result.type === AUTH_FLAGS.PASSWORD_CHANGE_REQUIRED) {
      formattedResult.tempPass = password;
    }

    if (successActionCallback) {
      yield put(successActionCallback(formattedResult));
    }

    yield put(setLoginSuccess(formattedResult));

    return formattedResult;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    yield put(setLoginError(error));

    return error;
  }
}

function* setLogoutWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.logout]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    yield put(setLogoutSuccess(result));

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    yield put(setLogoutError(error));

    return error;
  }
}

function* setForgotPasswordWatcher({ username, successActionCallback, errorActionCallback }) {
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.forgotPassword], username);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    yield put(setLogoutSuccess(result));

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    yield put(setLogoutError(error));

    return error;
  }
}

function* setVerifyPasswordResetWatcher({ data, successActionCallback, errorActionCallback }) {
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.verifyPasswordReset], data);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    yield put(setLogoutSuccess(result));

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    yield put(setLogoutError(error));

    return error;
  }
}

function* setChangeTemporaryPasswordWatcher({ data, successActionCallback, errorActionCallback }) {
  const api = yield select(authApiSelector);
  try {
    const response = yield call([api, api.changeTemporaryPassword], data);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    yield put(setLogoutSuccess(result));

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    yield put(setLogoutError(error));

    return error;
  }
}

export default function* authServiceSagas() {
  yield all([
    takeLeading(ACTIONS.FETCH_AUTH_TEST, fetchAuthTestWatcher),
    takeEvery(ACTIONS.SET_LOGIN, setLoginWatcher),
    takeEvery(ACTIONS.SET_LOGOUT, setLogoutWatcher),
    takeEvery(ACTIONS.SET_FORGOT_PASSWORD, setForgotPasswordWatcher),
    takeEvery(ACTIONS.SET_VERIFY_PASSWORD_RESET, setVerifyPasswordResetWatcher),
    takeEvery(ACTIONS.SET_CHANGE_TEMPORARY_PASSWORD, setChangeTemporaryPasswordWatcher),
  ]);
}
